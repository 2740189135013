import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/api.service';
import { Constants } from 'src/app/class/constants';
import { MixpanelService } from 'src/app/mixpanel.service';
import { AuthService } from 'src/app/services/auth.service';
import { ToastMessageService } from 'src/app/services/toast-message.service';
import { environment } from '../../../environments/environment';

declare var Razorpay: any;

@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.css']
})
export class ProgramsComponent implements OnInit {

  public modalRef: any;
  public modalConfigMd = Constants.lightGreyBackdropModalMd;

  public programData: any;
  public currentProgram: any;
  public currentUser: any;
  public isLoading: boolean = false;

  public razorPayOptions = {
    key: environment.razorpay_key,
    amount: '',
    currency: 'INR',
    name: 'ProBano',
    image: 'https://career-data.s3.ap-south-1.amazonaws.com/probano_logo.png',
    description: '',
    order_id: '',
    modal: {
      escape: false,
    },
    theme: {
      color: '#25BEC2',
    },
    prefill: {
      name: '',
      contact: '',
      email: '',
    },
    handler: (res) => {},
  };


  constructor(
    private modalService: NgbModal,
    private authService: AuthService,
    private apiService: ApiService,
    private toastMessageService: ToastMessageService,
    private mixpanelService: MixpanelService
  ) {
     this.programData = {
      explore: {
        name: 'Explore Program',
        image: "/assets/images/course.png",
        price: '999',
        features: [
          "Personality report based on psychometric test",
          "4 - 6 personalised career recommendations",
          "Engaging online course on career counselling with animated videos",
          "One 60 minute webinar for addressing career related queries or concerns",
          "One year online support for all career related questions"
        ]
      },
      discover: {
        name: 'Discover Program',
        image: "/assets/images/course.png",
        price: '1499',
        features: [
          "Personality report based on psychometric test",
          "4 - 6 personalised career recommendations",
          "Engaging online course on career counselling with animated videos",
          "Two 60 minute webinar for addressing career related queries or concerns",
          "One year online support for all career related questions"
        ]
      }
    }
  }

  ngOnInit(): void {
  }

  openModal(modalRef: any, programName) {
    this.isLoading = true;
    this.currentProgram = this.programData[programName];
    this.modalRef = this.modalService.open(modalRef, this.modalConfigMd);
    this.isLoading = false;
  }

  public purchaseNow() {
    
    this.currentUser = this.authService.userValue;
    let data = {
      amount: this.currentProgram?.price,
      name: this.currentUser?.name
    }
    if (this.currentUser.is_verified)
    {
    this.isLoading = true;
    this.apiService.postUser('pay', data).subscribe(
      data => {
        this.razorPayOptions.description = this.currentProgram?.name;
        this.razorPayOptions.prefill = {
          name: this.currentUser?.name,
          contact: this.currentUser?.mobile,
          email: this.currentUser?.email,
        };
        this.razorPayOptions.order_id = data.order.order_payment_id;
        this.razorPayOptions.amount = (parseInt(this.currentProgram?.price) * 100).toString();

        this.razorPayOptions.handler = (response) => {
          if (response.razorpay_payment_id) {
            let postData = {
              package_name: this.currentProgram?.name,
              full_name: this.currentUser?.name,
              user_email: this.currentUser?.email,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
            };
            this.toastMessageService.showSuccessWithTitle("Mission Successful", "Thanks for purchasing our Career Guidance Program. You can start pursuing it now on this app.", 10000)
            this.checkPaymentSuccess(postData);
          } else {
            this.toastMessageService.showError("Mentor booking not completed")
            this.mixpanelService.event("Mentor booking not completed", {
              "mentor_name": this.currentProgram?.name
            })
          }
        }
        const rzp1 = new Razorpay(this.razorPayOptions);
        rzp1.open();
      }, 
      err => {
        this.mixpanelService.error('payment-error', {
          message: err.error,
        });
      },
      () => this.isLoading = false
    )
    }
    else{
      this.toastMessageService.showError("Unable to purchase course, please verify your email.")
    }
  }

  checkPaymentSuccess(data) {
    this.apiService.post('payment/success',data).subscribe(
      data => {
        this.mixpanelService.event('course-purchased-completed', {
          "course_name": this.currentProgram?.name
        });
      },
      err => {
        this.mixpanelService.error('payment-success-error', {
          message: err.error,
        });
      }
    );
  }

  closeModal() {
    if (this.modalRef) {
      this.modalRef.dismiss();
    }
  }


}
