export const Constants: any = {
  lightGreyBackdropModalLg: {
    backdropClass: 'light-grey-backdrop',
    size: 'lg',
    windowClass: 'theme-modal rules-sec-modal',
    scrollable: true,
    backdrop: 'static',
  },
  lightGreyBackdropModalMd: {
    backdropClass: 'light-grey-backdrop',
    size: 'md',
    windowClass: 'theme-modal rules-sec-modal',
    scrollable: true,
    backdrop: 'static',
  },
  modalConfigMd: {
    backdropClass: 'light-grey-backdrop',
    size: 'md',
    windowClass: 'theme-modal rules-sec-modal',
    scrollable: true,
    backdrop: 'static',
  },
  modalLgConfig: {
    backdropClass: 'dark-grey-backdrop',
    windowClass: 'theme-modal center-modal',
    size: 'lg',
  },
  modalMdConfig: {
    centered: true,
    windowClass: 'theme-modal header-top onboarding-checker-modal',
    size: 'md',
    backdrop: 'static',
  },
  singleSelectDropdownSettings: {
    singleSelection: true,
    showCheckbox: true,
    text: "Select",
    enableSearchFilter: true,
    classes: "myclass custom-class",
    primaryKey: 'id',
    labelKey: 'name',
  }
};
