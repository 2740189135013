<app-admin-header></app-admin-header>
<div>
    <h2>Clients</h2>
</div>

<button type="button" class="btn btn-primary float-end m-3" (click)="openModal(clientModal)">Add User</button>



<ng-template #clientModal let-modal>

    <div class="modal-header">
        <h5 class="modal-title" *ngIf="!isView">{{ isEdit ? 'Edit' : 'Add' }} User</h5>
        <h5 class="modal-title" *ngIf="isView">View User</h5>
        <span class="btn btn-close btn-outline-danger" data-dismiss="modal" aria-label="Close" (click)="closeModal()"></span>
  
    </div>
    <div class="modal-body">

        <form [formGroup]="userForm">
            <div class="row clearfix px-3">
                <div class="col-md-12 col-lg-6">
                    <div class="form-group mt-3">
                        <label class="secondary-text-font-size primary-theme-color mb-1">Name</label>
                        <input type="text" name="name" formControlName="name"
                            [readonly]="isView"
                            class="form-input form-control theme-form-control pt-2 placeholder-fix" autocomplete="off" />
                        <div class="text-danger mt-1" *ngIf="f.name.touched && f.name?.errors?.required">
                            Name is Required
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-lg-6">
                    <div class="form-group mt-3">
                        <label class="secondary-text-font-size primary-theme-color mb-1">Email</label>
                        <input type="text" name="email" formControlName="email"
                            [readonly]="isView"
                            class="form-input form-control theme-form-control pt-2 placeholder-fix" autocomplete="off" />
                        <div class="text-danger mt-1" *ngIf="f.email.touched && f.email?.errors?.required">
                            Email is Required
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-lg-6">
                    <div class="form-group mt-3">
                        <label class="secondary-text-font-size primary-theme-color mb-1">Client</label>
                        <angular2-multiselect [data]="dropdownList"
                            [settings]="dropdownSettings" name="client" formControlName="client"
                        >
                        </angular2-multiselect>
                        <div class="text-danger mt-1" *ngIf="f.client.touched && f.client?.errors?.required">
                           Client is Required
                        </div>
                    </div>
                </div>

                


                <div class="col-md-12 col-lg-6">
                    <div class="form-group mt-3">
                        <label class="secondary-text-font-size primary-theme-color mb-1">Class</label>
                        <input type="text" name="class" formControlName="class"
                            [readonly]="isView"
                            class="form-input form-control theme-form-control pt-2 placeholder-fix" autocomplete="off" />
                    </div>
                </div>

                <div class="col-md-12 col-lg-6">
                    <div class="form-group mt-3">
                        <label class="secondary-text-font-size primary-theme-color mb-1">School</label>
                        <input type="text" name="school" formControlName="school"
                            [readonly]="isView"
                            class="form-input form-control theme-form-control pt-2 placeholder-fix" autocomplete="off" />
                    </div>
                </div>

                <div class="col-md-12 col-lg-6">
                    <div class="form-group mt-3">
                        <label class="secondary-text-font-size primary-theme-color mb-1">State</label>
                        <input type="text" name="state" formControlName="state"
                            [readonly]="isView"
                            class="form-input form-control theme-form-control pt-2 placeholder-fix" autocomplete="off" />
                    </div>
                </div>


            </div>
        </form>



    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="closeModal()">Close</button>
        <button type="button" class="btn btn-primary" (click)="onSubmitForm()" *ngIf="!isView">Save changes</button>
    </div>

</ng-template>