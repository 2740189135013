import { Router } from '@angular/router';
import { MixpanelService } from './../../mixpanel.service';
import { ApiService } from './../../api.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-mobile-sidebar',
  templateUrl: './mobile-sidebar.component.html',
  styleUrls: ['./mobile-sidebar.component.css'],
})
export class MobileSidebarComponent implements OnInit {
  public mobile_no: string;
  public student_class: string;
  public scholarship_url: string;

  constructor(
    private apiService: ApiService,
    private mixpanelService: MixpanelService,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
  }

  public closeSidebar() {
    document.getElementById('sidenav').style.width = '0%';
  }

  public startScholarship() {
    this.mixpanelService.event('Scholarship started');
    this.mobile_no = localStorage.getItem('user_mobile');
    this.student_class = localStorage.getItem('class_name');
    this.apiService
      .postScholarship(this.mobile_no, this.student_class)
      .subscribe((data) => {
        this.scholarship_url = data.data.url;
        window.open(this.scholarship_url, '_blank');
      });
  }

  public logout() {
    this.apiService.get('logout').subscribe(
      (data) => {
        this.mixpanelService.event('user-logout');
        this.authService.clearData();
        this.router.navigate(['/login']);
      },
      (err) => {
        this.mixpanelService.error('logout-error', {
          message: err.error,
        });
      }
    );
  }
}
