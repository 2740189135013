import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { CustomValidators } from 'src/app/class/custom-validators';
import { MixpanelService } from 'src/app/mixpanel.service';
import { AuthService } from 'src/app/services/auth.service';
import { ToastMessageService } from 'src/app/services/toast-message.service';


@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css'],
})
export class UserProfileComponent implements OnInit {
  public personality_test_status: string;

  public currentUser: any;
  public displayEditForm: boolean = false;
  public profileForm: FormGroup;
  public isLoading: boolean = false;

  get f() { return this.profileForm.controls }


  constructor(
    private apiService: ApiService,
    private mixpanelService: MixpanelService,
    private authService: AuthService,
    private fb: FormBuilder,
    private toastMessageService: ToastMessageService
  ) {
  }

  public openSidebar() {
    document.getElementById('sidenav').style.width = '50%';
  }

  ngOnInit() {
    this.mixpanelService.pageTrack('Loaded profile page');
    this.currentUser = this.authService.userValue;
    this.createProfileForm();
    this.patchProfileForm();
  }

  patchProfileForm() {
    this.profileForm.patchValue({
      class: this.currentUser?.class_name,
      name: this.currentUser?.name,
      school: this.currentUser?.school_name
    })
  }

  public showUpdateProfile() {
    this.displayEditForm = true;
  }

  updateProfile() {
    if(this.profileForm.valid) {
      const values = this.profileForm.getRawValue();
      let data = {
        class_name: values?.class,
        full_name: values?.name,
        mobile: this.currentUser?.mobile,
        school_name: values?.school
      }
      this.postUpdateProfileData(data);
    } else {
      this.toastMessageService.showError("Form is not valid.")
    }
  }

  postUpdateProfileData(data) {
    this.isLoading = true;
    this.apiService.put('edit-profile', data).subscribe(
      (data) => {
        if (data.status) {
          this.mixpanelService.event('update-profile-completed');
          this.toastMessageService.showSuccess(data.message);
          this.authService.getCurrentUserDetails();
          setTimeout(() => {
            this.isLoading = false;
            window.location.reload();
          }, 800);
        } else {
          this.isLoading = false;
          this.toastMessageService.showError(data.message);
        }
      },
      (err) => {
        this.isLoading = false;
        this.toastMessageService.showError(err.error.detail);
        this.mixpanelService.error('edit-profile-error', {
          'message': err
        })
      }
    );
  }

  createProfileForm() {
    this.profileForm = this.fb.group({
      name: ['', [Validators.required, Validators.pattern(CustomValidators.onlyChars)]],
      class: ['', Validators.required],
      school: ['', [Validators.required, Validators.pattern(CustomValidators.onlyChars)]],
    });
  }


}
