<header class="bg-white font-small blue-grey lighten-5 page-header fixed-header-top shadow-sm">
    <div>
      <div class="container">
        <div class="row py-3 d-flex align-items-center">
          <div class="col-md-6 col-6 text-md-left mb-4 mb-md-0">
            <a [href]="data?.secondary_client_info?.website_url === '-' ? '#' : data?.secondary_client_info?.website_url"  target="_blank">
              <img class="mb-0 img-fluid max-height-50-px" [src]="data?.secondary_client_info?.logo" alt="Buddy For Study"> 
            </a>
          </div>
  
          <div class="col-md-6 col-6 text-end text-md-right">
            <a [href]="data?.primary_client_info?.website_url" target="_blank">
              <img class="mb-0 img-fluid max-height-50-px" [src]="data?.primary_client_info?.logo" alt="HCL Foundation"> 
            </a>
          </div>
        </div>
      </div>
    </div>
</header>
  