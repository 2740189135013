<app-verify-email></app-verify-email>
<div class="main">
  <div class="sidebar-container">
    <app-sidebar active="mentors"></app-sidebar>
  </div>

  <app-mobile-sidebar></app-mobile-sidebar>

  <div class="main-content">
    <!-- Profile section -->
    <app-header class="header" title="Mentors"></app-header>

    <div class="event-container-mobile">
      <h2 class="current-page-name">Mentors</h2>
      <i class="fas fa-bars" (click)="openSidebar()"></i>
    </div>


    <div class="row">
      <div class="col-md-8 height-80-vh modal-body-custom-scroll overflow-auto">
        <div class="mentor-list-card" *ngFor="let mentor of mentors">
          <img class="mentor-image" [src]="mentor.img_url" alt="Mentor Image" />
          <div class="mentor-info-container">
            <div class="mentor-information">
              <div class="mentor-name-container">
                <span class="mentor-list-name">{{ mentor.name }}</span>
                <span class="mentor-profession">{{ mentor.title }}</span>
              </div>
              <button class="book-button" (click)="openModal(mentorModal, mentor)">
                Book Now
              </button>
            </div>
            <p class="mentor-description" (click)="openModal(mentorModal, mentor)">
              {{ mentor.short_description }}
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-4 height-80-vh">
        <app-personality-test-prompt></app-personality-test-prompt>

        <app-programs></app-programs>
      </div>
    </div>

  </div>
</div>



<ng-template #mentorModal let-modal>

  <div class="modal-header">
      <h5 class="modal-title rubik-bold pb-primary-color">
        <span class="rubik-bold">{{ currentMentor?.name }}</span>
        (
        <span class="rubik-bold">{{ currentMentor?.title }}</span>
        )
      </h5>
      <span class="btn btn-close btn-outline-danger" data-dismiss="modal" aria-label="Close" (click)="closeModal()"></span>
  </div>
  <div class="modal-body modal-body-custom-scroll">
      

      <img class="img-fluid float-start w-50 border-radius-large me-3 mb-1" [src]="currentMentor?.img_url" [alt]="currentMentor?.name">

      <div>
        <p class="roboto pb-grey-light-color">
          {{ currentMentor?.description }}
        </p>
      </div>
      
  </div>
  <div class="modal-footer">
      <button type="button" class="btn pb-primary-color-bg text-white w-100 border-radius-large roboto-bold" (click)="bookMentor()">Book a session for ₹999</button>
      <button type="button" class="btn btn-white w-100 my-2 roboto" data-bs-dismiss="modal" (click)="closeModal()">Cancel</button>
  </div>

</ng-template>

<div class="loading-div" *ngIf="isLoading">
  <img class="loading-mode-width loading-spinner" src="/assets/images/loader.gif" alt="">
</div>
