<div>
    <div class="background-header-hcl py-5">
        <div class="container">
            <h3 class="rubik-bold">Suggested Career Fields :</h3>
            <p class="roboto pb-grey-light-color">
                These are the suggested career fields according to your interests.
            </p>
        </div>
    </div>

     <!-- Careers -->
    <div class="container py-5">
        <div *ngFor="let item of data?.career_recommendation; let i = index">
            <div class="row my-5 d-flex align-items-center" *ngIf="i % 2 == 0">
                <div class="col-md-8 col-12">
                    <h4 class="rubik-medium">{{ item?.category }}</h4>
                    <div class="my-3 show">
                    
                        <div class="d-flex align-items-center w-50 progress-bar-width">
                            <div class="progress-bar-test-container w-44">
                                <div class="progress-bar-test" 
                                    [ngStyle]="{'width': (item?.points / (item?.careers.length * 4)) * 100 + '%'}"> 
                                </div>
                                <div class="d-flex justify-content-between">
                                    <small class="roboto">Profession</small>
                                    <small class="roboto">Passion</small>
                                </div>   
                            </div>
                            <small class="small-caption mx-3 pb-primary-color roboto-bold">Career Inclination Scale</small>
                        </div> 
                    </div>
                    <p class="roboto pb-grey-light-color">
                        {{ item?.category_description }}
                    </p>
                </div>
                <div class="col-md-4 col-12">
                    <div class="fixed-image-container" [ngStyle]="{'background-image': 'url(' + item?.image_url + ')'}">    
                    </div>
                </div>
                <div class="col-md-12 my-3 hide">
                    
                    <div class="d-flex align-items-center w-50 progress-bar-width">
                        <div class="progress-bar-test-container w-44">
                            <div class="progress-bar-test" 
                                [ngStyle]="{'width': (item?.points / (item?.careers.length * 4)) * 100 + '%'}"> 
                            </div>
                            <div class="d-flex justify-content-between">
                                <small class="roboto">Profession</small>
                                <small class="roboto">Passion</small>
                            </div>   
                        </div>
                        <small class="small-caption mx-3 pb-primary-color roboto-bold">Career Inclination Scale</small>
                    </div> 
                </div>
            </div>

            <div class="row my-5 d-flex align-items-center" *ngIf="i % 2 != 0">
                <div class="col-md-4 col-12">
                    <div class="fixed-image-container" [ngStyle]="{'background-image': 'url(' + item?.image_url + ')'}">    
                    </div>
                </div>
                <div class="col-md-8 col-12 text-end">
                    <h4 class="rubik-medium">{{ item?.category }}</h4>     
                    <div class="clearfix show my-3">
                        <div class="d-flex align-items-center w-40 progress-bar-width float-end justify-content-between">
                            <small class="small-caption pb-primary-color roboto-bold">Career Inclination Scale</small>
                            <div class="progress-bar-test-container w-44">
                                <div class="progress-bar-test" 
                                    [ngStyle]="{'width': (item?.points / (item?.careers.length * 4)) * 100 + '%'}">
                                </div>
                                <div class="d-flex justify-content-between">
                                    <small class="roboto">Profession</small>
                                    <small class="roboto">Passion</small>
                                </div>   
                            </div>
                        </div> 
                    </div>   
                    <p class="roboto pb-grey-light-color">
                        {{ item?.category_description }}
                    </p>
                </div>   
                <div class="col-md-12 hide my-0">
                    <div class="clearfix">
                        <div class="d-flex align-items-center w-40 progress-bar-width float-end justify-content-between">
                            <small class="small-caption pb-primary-color roboto-bold">Career Inclination Scale</small>
                            <div class="progress-bar-test-container w-44">
                                <div class="progress-bar-test" 
                                    [ngStyle]="{'width': (item?.points / (item?.careers.length * 4)) * 100 + '%'}">
                                </div>
                                <div class="d-flex justify-content-between">
                                    <small class="roboto">Profession</small>
                                    <small class="roboto">Passion</small>
                                </div>   
                            </div>
                        </div> 
                    </div>   
                </div> 
            </div>
        </div>
    </div> 


    <!-- Traits  -->
    <div class="background-header-hcl py-5">
        <div class="container">
            <h3 class="rubik-bold">Your Personality Traits :</h3>
            <p class="roboto pb-grey-light-color">
                These are the key strengths aligned with your career interests.
            </p>
        </div>
    </div>

    <div class="container py-5">
        <div *ngFor="let item of data?.personality_traits; let i = index" >
            <div class="row my-5 d-flex align-items-center" *ngIf="i % 2 == 0">
                <div class="col-md-8 col-12">
                    <h4 class="rubik-medium">{{ item?.trait_name }}</h4>

                   <div class="show">
                        <div class="d-flex align-items-center w-50 progress-bar-width">
                            <div class="progress-bar-test-container w-44">
                                <div class="progress-bar-test" 
                                    [ngStyle]="{'width': (item?.points / data?.career_recommendation?.length) * 100 + '%'}"> 
                                </div>
                                <div class="d-flex justify-content-between">
                                    <small class="roboto">Good</small>
                                    <small class="roboto">Strong</small>
                                </div>   
                            </div>
                            <small class="small-caption mx-3 pb-primary-color roboto-bold">Personality Match</small>
                        </div> 
                   </div>

                    <p class="roboto my-4 pb-grey-light-color">
                        {{ item?.trait_description }}
                    </p>
                </div>
                <div class="col-md-4 col-12">
                    <div class="fixed-image-container" [ngStyle]="{'background-image': 'url(' + item?.trait_image + ')'}">    
                    </div>
                </div>
                <div class="col-md-12 hide my-3">
                    
                    <div class="d-flex align-items-center w-50 progress-bar-width">
                        <div class="progress-bar-test-container w-44">
                            <div class="progress-bar-test" 
                                [ngStyle]="{'width': (item?.points / data?.career_recommendation?.length) * 100 + '%'}"> 
                            </div>
                            <div class="d-flex justify-content-between">
                                <small class="roboto">Good</small>
                                <small class="roboto">Strong</small>
                            </div>   
                        </div>
                        <small class="small-caption mx-3 pb-primary-color roboto-bold">Personality Match</small>
                    </div> 
                </div>
            </div>

            <div class="row my-5 d-flex align-items-center" *ngIf="i % 2 != 0">
                <div class="col-md-4 col-12">
                    <div class="fixed-image-container" [ngStyle]="{'background-image': 'url(' + item?.trait_image + ')'}">    
                    </div>
                </div>
                <div class="col-md-8 col-12 text-end d-flex flex-column my-2">
                    <h4 class="rubik-medium">{{ item?.trait_name }}</h4>

                    <div class="clearfix show">
                        <div class="d-flex align-items-center w-40 progress-bar-width float-end justify-content-between">
                            <small class="small-caption pb-primary-color roboto-bold">Personality Match</small>
                            <div class="progress-bar-test-container w-44">
                                <div class="progress-bar-test" 
                                    [ngStyle]="{'width': (item?.points / data?.career_recommendation?.length) * 100 + '%'}">
                                </div>
                                <div class="d-flex justify-content-between">
                                    <small class="roboto">Good</small>
                                    <small class="roboto">Strong</small>
                                </div>   
                            </div>
                        </div> 
                    </div>       
                    <p class="roboto my-3 pb-grey-light-color">
                        {{ item?.trait_description }}
                    </p>
                </div> 
                <div class="col-md-12 hide my-0">
                    <div class="clearfix">
                        <div class="d-flex align-items-center w-40 progress-bar-width float-end justify-content-between">
                            <small class="small-caption pb-primary-color roboto-bold">Personality Match</small>
                            <div class="progress-bar-test-container w-44">
                                <div class="progress-bar-test" 
                                    [ngStyle]="{'width': (item?.points / data?.career_recommendation?.length) * 100 + '%'}">
                                </div>
                                <div class="d-flex justify-content-between">
                                    <small class="roboto">Good</small>
                                    <small class="roboto">Strong</small>
                                </div>   
                            </div>
                        </div> 
                    </div>      
                </div>   
            </div>
        </div>
    </div>




    <!-- Single Career options -->
    <div *ngFor="let item of data?.career_recommendation; let i = index;">
        <div class="background-header-hcl py-5">
            <div class="container pb-4">
                <h3 class="rubik-bold text-center">{{ item?.category }}</h3>
            </div>
        </div>
        <div class="container py-5">
            <div class="row d-flex align-items-center" *ngIf="i % 2 == 0">
                <div class="col-md-8 col-12">
                    <p class="roboto pb-grey-light-color ">
                        {{ item?.category_description }}
                    </p>
                </div>
                <div class="col-md-4 col-12">
                    <div class="fixed-image-container" [ngStyle]="{'background-image': 'url(' + item?.image_url + ')'}">    
                    </div>
                </div>   
            </div>
    
            <div class="row d-flex align-items-center" *ngIf="i % 2 != 0">
                <div class="col-md-4 col-12">
                    <div class="fixed-image-container" [ngStyle]="{'background-image': 'url(' + item?.image_url + ')'}">    
                    </div>
                </div>    
                <div class="col-md-8 col-12 text-end">
                    <p class="roboto pb-grey-light-color ">
                        {{ item?.category_description }}
                    </p>
                </div> 
            </div>
    
            <div>
                <div class="my-5 hcl-career-container mx-2">
                    <span class="rubik-bold hcl-report-bg-dark-color text-white ls-2 px-3 py-2 border-radius-medium hcl-career-text">CAREERS</span>
                    <div class="row hcl-report-bg-color border-radius-medium px-2 pt-5 pb-3">
                        <div class="col-md-4 my-1 col-6" *ngFor="let career of item?.careers"> 
                            <h6 class="pb-grey-light-color"> 
                                <i class="fas fa-star me-1 pb-green-color"></i>
                                {{ career?.career_name  }}
                            </h6>
                        </div>
                    </div>
                </div>
        
                <p class="roboto-medium pb-grey-dark-color">
                    <i class="fas fa-star-of-life pb-secondary-color me-1"></i>
                    <span class="roboto-medium pb-grey-dark-color">
                        Workplaces: 
                        <span *ngFor="let workplace of item?.workplaces">
                            {{ workplace + ',' }}
                        </span>
                        etc
                    </span>
                </p>
            </div>      
        </div>
        
    </div>

    <!-- Single Trait options -->
    <div *ngFor="let item of data?.personality_traits; let i = index;">

        <div class="background-header-hcl py-5">
            <div class="container pb-4">
                <h3 class="rubik-bold text-center">{{ item?.trait_name }}</h3>
            </div>
        </div>

        <div class="my-5 hcl-career-container">
            <div class="row justify-content-around mx-3">
                <div class="col-md-5 col-12 pb-fade-grey-light-color-bg p-4 border-radius-medium hcl-career-container my-4">
                    <span class="rubik-bold hcl-report-bg-dark-color text-white ls-2 px-3 py-2 border-radius-medium hcl-pros-cons-text">PROS</span>
                    <p *ngFor="let pros of item?.pros">
                        <i class="fas fa-star me-1 pb-green-color"></i>
                        <span class="roboto pb-grey-light-color">{{ pros }}</span>
                    </p>
                </div>

                <div class="col-md-5 col-12 pb-fade-grey-light-color-bg p-4 border-radius-medium hcl-career-container my-4">
                    <span class="rubik-bold hcl-report-bg-dark-color text-white ls-2 px-3 py-2 border-radius-medium hcl-pros-cons-text">CONS</span>
                    <p *ngFor="let cons of item?.cons">
                        <i class="fas fa-star me-1 pb-green-color"></i>
                        <span class="roboto pb-grey-light-color">{{ cons }}</span>
                    </p>
                </div>
            </div>

        </div>

    </div>
</div>
