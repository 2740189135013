<div class="container-fluid margin-top-50-px">

    <div class="row main-content-header bg-success text-center">

        <div class="col-md-4 text-center company__info">
            <span class="company__logo">
                <h2><span class="fa fa-android"></span></h2>
            </span>
            <h2 class="company_title">Welcome</h2>
        </div>

        <div class="col-md-8 col-xs-12 col-sm-12 login_form py-4">
            <div class="container-fluid">
                <div class="row">
                    <h2>Admin Login</h2>
                </div>

                <div class="row">
                    <form [formGroup]="loginForm" class="form-group">
                        <div class="row">
                            <input type="text" name="email" formControlName="email" class="form__input"
                                placeholder="Email">
                            <div class="danger-text text-left" *ngIf="fm.email.touched && fm.email?.errors?.required">
                                Email is Required
                            </div>
                        </div>
                        <div class="row">
                            <input type="password" name="password" formControlName="password" class="form__input"
                                placeholder="Password">
                            <div class="danger-text text-left"
                                *ngIf="fm.password.touched && fm.password?.errors?.required">
                                Password is Required
                            </div>
                        </div>
                        <div class="row">
                            <input type="submit" value="Submit" [disabled]="loginForm.invalid" class="btn cursor-pointer" (click)="onSubmitForm()">
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>

</div>


<div class="loading-div" *ngIf="isLoading">
    <img class="loading-mode-width loading-spinner" src="/assets/images/loader.gif" alt="">
</div>