import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'src/app/class/constants';
import { AuthService } from 'src/app/services/auth.service';
import { ToastMessageService } from 'src/app/services/toast-message.service';
import { environment } from 'src/environments/environment';
import { ApiService } from '../../api.service';
import { DataService } from '../../data.service';
import { MixpanelService } from '../../mixpanel.service';

declare var Razorpay: any;

@Component({
  selector: 'app-explore-courses',
  templateUrl: './explore-courses.component.html',
  styleUrls: ['./explore-courses.component.css'],
})
export class ExploreCoursesComponent implements OnInit {
  public courses = new Array<courseData>();
  public personality_test_status: string = '';
  
  public modalRef: any;
  public modalConfigMd = Constants.lightGreyBackdropModalMd;

  public currentCourseData: any;
  public currentUser: any;
  public isLoading: boolean = false;

  public razorPayOptions = {
    key: environment.razorpay_key,
    amount: '',
    currency: 'INR',
    name: 'ProBano',
    image: 'https://career-data.s3.ap-south-1.amazonaws.com/probano_logo.png',
    description: '',
    order_id: '',
    modal: {
      escape: false,
    },
    theme: {
      color: '#25BEC2',
    },
    prefill: {
      name: '',
      contact: '',
      email: '',
    },
    handler: (res) => {},
  };

  constructor(
    private apiService: ApiService,
    private router: Router,
    private mixpanelService: MixpanelService,
    private modalService: NgbModal,
    private authService: AuthService,
    private toastMessageService: ToastMessageService
  ) {
  }

  public viewCourse() {
    this.isLoading = true;
    this.router.navigate(['course-videos'], {
      queryParams: {
        courseId: this.currentCourseData?.course_id
      }
    })
    this.closeModal();
    this.isLoading = false;
  }

  public purchaseCourse(course) {
    this.currentCourseData = course
    this.currentUser = this.authService.userValue;
    let data = {
      amount: this.currentCourseData?.amount,
      name: this.currentUser?.name
    }
    if (this.currentUser.is_verified)
   { this.apiService.postUser('pay', data).subscribe(
      data => {
        this.razorPayOptions.description = this.currentCourseData?.course_name;
        this.razorPayOptions.prefill = {
          name: this.currentUser?.name,
          contact: this.currentUser?.mobile,
          email: this.currentUser?.email,
        };
        this.razorPayOptions.order_id = data.order.order_payment_id;
        this.razorPayOptions.amount = (parseInt(this.currentCourseData?.amount) * 100).toString();

        this.razorPayOptions.handler = (response) => {
          if (response.razorpay_payment_id) {
            let postData = {
              course_id: this.currentCourseData?.course_id,
              course_name: this.currentCourseData?.course_name,
              full_name: this.currentUser?.name,
              user_email: this.currentUser?.email,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
            };
            this.toastMessageService.showSuccessWithTitle("Mission Successful", "Thanks for purchasing this course. You can start pursuing it now on this app.", 10000)
            this.checkPaymentSuccess(postData);
          } else {
            this.toastMessageService.showError("Course Purchasing failed")
            this.mixpanelService.event("Course Purchasing failed", {
              "course_name": this.currentCourseData?.name
            })
          }
        }
        const rzp1 = new Razorpay(this.razorPayOptions);
        rzp1.open();
      }, 
      err => {
        this.mixpanelService.error('payment-error', {
          message: err.error,
        });
      }
    )}
    else{
      this.toastMessageService.showError("Unable to buy course, please verify your email.")
    }
  }

  checkPaymentSuccess(data) {
    this.isLoading = true;
    this.apiService.post('payment/success',data).subscribe(
      data => {
        this.mixpanelService.event('course-purchasing-completed', {
          "course_name": this.currentCourseData?.name
        });
      },
      err => {
        this.mixpanelService.error('payment-success-error', {
          message: err.error,
        });
      },
      () => this.isLoading = false
    );
  }

  public openSidebar() {
    document.getElementById('sidenav').style.width = '50%';
  }

  openModal(modalRef: any, data: any) {
    this.resetData();
    this.currentCourseData = data;
    this.modalRef = this.modalService.open(modalRef, this.modalConfigMd);
  }

  resetData() {
    this.currentCourseData = [];
  }

  closeModal() {
    if (this.modalRef) {
      this.modalRef.dismiss();
    }
  }


  ngOnInit() {
    this.mixpanelService.pageTrack('Loaded explore courses page');
    this.getAllCourses();
  }

  getAllCourses() {
    this.isLoading = true;
    this.apiService.get('get-all-courses').subscribe(
      data => {
        this.courses = data['course_data'];
      },
      err => {
        this.mixpanelService.error('get-all-courses-error', {
          message: err.error,
        });
        localStorage.clear();
        this.router.navigate(['login'])
      },
      () => this.isLoading = false
    );
  }

}

interface courseData {
  amount: string;
  course_description: string;
  course_id: number;
  course_image: string;
  course_name: string;
  num_of_lessons: string;
  total_hours: string;
}
