import { MixpanelService } from './../../mixpanel.service';
import { Component, Input, OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../api.service';
import { AuthService } from 'src/app/services/auth.service';
import { Constants } from 'src/app/class/constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastMessageService } from 'src/app/services/toast-message.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  public currentPath;

  public modalRef: any;
  public modalConfigMd = Constants.lightGreyBackdropModalMd;

  public scholarship_url: string;
  public isLoading: boolean = false;

  @Input() active;
  constructor(
    private router: Router,
    private apiService: ApiService,
    private mixpanelService: MixpanelService,
    private el: ElementRef,
    private authService: AuthService,
    private modalService: NgbModal,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit() {
  }

  openScholarshipModal(modalRef: any) {
    this.isLoading = true;
    this.modalRef = this.modalService.open(modalRef, this.modalConfigMd);
    this.isLoading = false;
  }
  
  closeModal() {
    if (this.modalRef) {
      this.modalRef.dismiss();
    }
  }


  public startScholarship() {
    this.isLoading = true;
    this.mixpanelService.event('Scholarship started');
    let currentUser = this.authService.userValue;
    let data ={
      mobile:currentUser?.mobile,
      class: currentUser?.class_name
    }
    this.apiService.post("scholarship-url",data).subscribe(
       data => {
          this.scholarship_url = data.data.url;
          window.open(this.scholarship_url, "_blank");
        },
        err => {
          this.toastMessageService.showError(err.error.detail);
          this.isLoading = false
        },
        () => this.isLoading = false
      )
  }




  goTo(path) {
    this.router.navigate([path]);
  }
}
