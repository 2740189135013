<app-admin-header></app-admin-header>

<div class="container py-4">
    <div>
        <h2>Events</h2>
    </div>
    
    <button type="button" class="btn btn-primary float-end m-3" (click)="openModal(eventModal)">Add Event</button>

    <!-- <button type="button" class="btn btn-secondary" (click)="exportExcel()">Export Excel</button> -->
    
    <div>
        <table class="table table-striped table-secondary">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Event Name</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of events; let i = index">
                <th scope="row">{{ i + 1 }}</th>
                <td>{{ item?.event_name }}</td>
                <td>
                    <i class="fa fa-eye" data-bs-toggle="tooltip" data-bs-placement="top" title="View" (click)="openModal(eventModal, item, true)"></i>
                    <i class="fa fa-edit" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" (click)="openModal(eventModal, item, false, true)"></i>
                    <i class="fa fa-trash-o" aria-hidden="true" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete" (click)="openDeleteModal(deleteEventModal, item)"></i>
                </td>
              </tr>
              
            </tbody>
          </table>
    </div>
    
</div>



<ng-template #eventModal let-modal>

    <div class="modal-header">
        <h5 class="modal-title" *ngIf="!isView">{{ isEdit ? 'Edit' : 'Add' }} Event</h5>
        <h5 class="modal-title" *ngIf="isView">View Event</h5>
        <span class="btn btn-close btn-outline-danger" data-dismiss="modal" aria-label="Close" (click)="closeModal()"></span>
  
    </div>
    <div class="modal-body">

        <img *ngIf="isView" [src]="eventImage" class="rounded mx-auto d-block" [alt]="eventName">

        <form [formGroup]="eventForm">
            <div class="row clearfix px-3">
                <div class="col-md-12 col-lg-6">
                    <div class="form-group mt-3">
                        <label class="secondary-text-font-size primary-theme-color mb-1">Event Name</label>
                        <input type="text" name="eventName" formControlName="eventName"
                            [readonly]="isView"
                            class="form-input form-control theme-form-control pt-2 placeholder-fix" autocomplete="off" />
                        <div class="text-danger mt-1" *ngIf="f.eventName.touched && f.eventName?.errors?.required">
                            Event Name is Required
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-lg-6">
                    <div class="form-group mt-3">
                        <label class="secondary-text-font-size primary-theme-color mb-1">Event Url</label>
                        <input type="text" name="eventUrl" formControlName="eventUrl"
                            [readonly]="isView"
                            class="form-input form-control theme-form-control pt-2 placeholder-fix" autocomplete="off" />
                        <div class="text-danger mt-1" *ngIf="f.eventUrl.touched && f.eventUrl?.errors?.required">
                            Event Url is Required
                        </div>
                    </div>
                </div>


                <div class="col-md-12 col-lg-6">
                    <div class="form-group mt-3">
                        <label class="secondary-text-font-size primary-theme-color mb-1">Event Image Url</label>
                        <input type="text" name="eventImageUrl" formControlName="eventImageUrl"
                            [readonly]="isView"
                            class="form-input form-control theme-form-control pt-2 placeholder-fix" autocomplete="off" />
                        <div class="text-danger mt-1" *ngIf="f.eventImageUrl.touched && f.eventImageUrl?.errors?.required">
                            Event Image Url is Required
                        </div>
                    </div>
                </div>


                <div class="col-md-12 col-lg-12">
                    <div class="form-group mt-3">
                        <label class="secondary-text-font-size primary-theme-color mb-1">Event Description</label>
                        <textarea type="text" name="eventDescription" formControlName="eventDescription"
                            [readonly]="isView"
                            class="form-input form-control theme-form-control pt-2 placeholder-fix" autocomplete="off" rows="5"></textarea>
                        <div class="text-danger mt-1" *ngIf="f.eventDescription.touched && f.eventDescription?.errors?.required">
                            Event Description is Required
                        </div>
                    </div>
                </div>

            </div>
        </form>



    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="closeModal()">Close</button>
        <button type="button" class="btn btn-primary" (click)="onSubmitForm()" *ngIf="!isView">Save changes</button>
    </div>

</ng-template>

<ng-template #deleteEventModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Delete Event</h5>
        <span class="btn btn-close btn-outline-danger" data-dismiss="modal" aria-label="Close" (click)="closeModal()"></span>
    </div>

    <div class="modal-body">
        <h4>Are you sure you want to delete this event?</h4>
        <h5 class="mt-3">Event Name: <span class="text-primary">{{ eventName }}</span></h5>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="closeModal()">Close</button>
        <button type="button" class="btn btn-danger" (click)="onDelete()" *ngIf="!isView">Delete</button>
    </div>
</ng-template>



<div class="loading-div" *ngIf="isLoading">
    <img class="loading-mode-width loading-spinner" src="/assets/images/loader.gif" alt="">
</div>