<div class="personality-test-prompt" *ngIf="!personalityTestStatus">
  <img
    class="personality-image"
    src="../../../assets/images/personality_test.svg"
    alt="Personality Test"
  />
  <div class="personality-info">
    <h2 class="personality-test-heading">Take our Personality Test</h2>
    <p class="personality-test-description">
      Get to know your personality type and our career recommendations
      for you.
    </p>
    <button class="test-button" (click)="openModal(testModal)">Take Test</button>
  </div>
</div>

<div
  class="personality-report-prompt"
  *ngIf="personalityTestStatus"
>
  <h1 class="personality-test-heading">Thanks :)</h1>
  <p class="personality-test-description">
    For taking our PathFinder Test. Your report with your personality type and
    our career recommendations can be viewed below.
  </p>
  <button class="test-button" (click)="viewReport()">View Report</button>
</div>




<ng-template #testModal let-modal>

  <div class="modal-header">
      <h3 class="modal-title rubik-bold">Rules</h3>
      <span class="btn btn-close btn-outline-danger" data-dismiss="modal" aria-label="Close" (click)="closeModal()"></span>
  </div>
  <div class="modal-body modal-body-custom-scroll">
    <div>
      <h5 class="pb-primary-color roboto">There will be four options for each 35 question.</h5>
      <div class="english-rules">
        <div>
          <i class="fas fa-star"></i>
          <span class="roboto pb-grey-light-color">Choose your most liked option first.</span>
        </div>
        <div>
          <i class="fas fa-star"></i>
          <span class="roboto pb-grey-light-color">Then choose your second most liked option.</span>
        </div>
        <div>
          <i class="fas fa-star"></i>
          <span class="roboto pb-grey-light-color">Then choose your third most liked option.</span>
        </div>
        <div>
          <i class="fas fa-star"></i>
          <span class="roboto pb-grey-light-color">Then choose your least liked option.</span>
        </div>
        <div>
          <i class="fas fa-star"></i>
          <span class="roboto pb-grey-light-color">Submit each question.</span>
        </div>
      </div>

      <h5 class="pb-primary-color roboto my-3">प्रत्येक प्रश्न के लिए चार विकल्प होंगे</h5>
      <div class="hindi-rules">
        <div class="my-1">
          <i class="fas fa-star"></i>
          <span class="roboto pb-grey-light-color">सबसे पहले अपना सबसे पसंदीदा विकल्प चुनें।</span>
        </div>

        <div class="my-1">
          <i class="fas fa-star"></i>
          <span class="roboto pb-grey-light-color">इसके बाद अपना दूसरा सबसे ज्यादा पसंद किया जाने वाला विकल्प चुनें।</span>
        </div>
        <div class="my-1">
          <i class="fas fa-star"></i>
          <span class="roboto pb-grey-light-color">इसके बाद अपना तीसरा सबसे ज्यादा पसंद किया जाने वाला विकल्प चुनें।</span>
        </div>
        <div class="my-1">
          <i class="fas fa-star"></i>
          <span class="roboto pb-grey-light-color">फिर अपना सबसे कम पसंद किया गया विकल्प चुनें।</span>
        </div>
        
      </div>

    </div>
     

  </div>
  <div class="modal-footer">
      <button type="button" class="btn pb-primary-color-bg text-white w-100 border-radius-large roboto-bold" (click)="startTest()">Start Test</button>
      <button type="button" class="btn btn-white w-100 my-2 roboto" data-bs-dismiss="modal" (click)="closeModal()">Cancel</button>
  </div>

</ng-template>



<div class="loading-div" *ngIf="isLoading">
  <img class="loading-mode-width loading-spinner" src="/assets/images/loader.gif" alt="">
</div>


