<app-verify-email></app-verify-email>
<div class="main">
  <div class="sidebar-container">
    <app-sidebar active="events"></app-sidebar>
  </div>
  <app-mobile-sidebar></app-mobile-sidebar>

  <div class="main-content">
    <!-- Profile section -->
    <app-header class="header" title="Events"></app-header>

    <div class="event-container-mobile">
      <h2 class="current-page-name">Events</h2>
      <i class="fas fa-bars" (click)="openSidebar()"></i>
    </div>

    <div class="row">
      <div class="col-12 col-md-4 mb-4 cursor-pointer" *ngFor="let event of events" (click)="openModal(eventModal, event)">
        <img class="img-fluid event-image" [src]="event.event_image" [alt]="event.short_event_name" />

        <div class="bg-white p-3 event-info">
          <h5 class="roboto-bold">{{ event.short_event_name }}</h5>
          <p class="roboto pb-grey-light-color">
            {{ event.short_description }}
          </p>

          <div class="d-flex justify-content-between">
            <div>
              <i class="far fa-clock"></i>
              <span class="event-time">{{ getEventTime(event.time) }}</span>
            </div>
            <div>
              <i class="fas fa-calendar-week"></i>
              <span class="event-date">{{ event.date }} </span>
            </div>
          </div>

        </div>

      </div>
    </div>

  </div>
</div>


<ng-template #eventModal let-modal>

  <div class="modal-header">
      <h4 class="modal-title">{{ currentEventData?.event_name  }}</h4>
      <span class="btn btn-close btn-outline-danger" data-dismiss="modal" aria-label="Close" (click)="closeModal()"></span>

  </div>
  <div class="modal-body modal-body-custom-scroll">

      <img [src]="currentEventData?.event_image" class="rounded mx-auto d-block img-fluid" [alt]="currentEventData?.event_name">

      <div class="pt-3">
        <h6 class="pb-grey-light-color">
          {{ currentEventData?.description }}
        </h6>

        <div class="d-flex justify-content-around my-3">
          <div>
            <i class="far fa-clock"></i>
            <span class="event-time">{{ getEventTime(currentEventData?.time) }}</span>
          </div>
          <div>
            <i class="fas fa-calendar-week"></i>
            <span class="event-date">{{ currentEventData?.date }} </span>
          </div>
        </div>
      </div>

  </div>
  <div class="modal-footer">
      <button type="button" class="btn pb-primary-color-bg text-white w-100 border-radius-large roboto" (click)="openEvent()">Watch Event</button>
      <button type="button" class="btn btn-white w-100 my-2 roboto" data-bs-dismiss="modal" (click)="closeModal()">Cancel</button>
  </div>

</ng-template>

<div class="loading-div" *ngIf="isLoading">
  <img class="loading-mode-width loading-spinner" src="/assets/images/loader.gif" alt="">
</div>