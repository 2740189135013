import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { MixpanelService } from '../mixpanel.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    public currentUser: any;
    private userSubject: BehaviorSubject<any>;
    public user: Observable<any>;

    constructor(
        private apiService: ApiService,
        private mixpanelService: MixpanelService
    ) {
        this.userSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('user_info')));
        this.user = this.userSubject.asObservable();
    }

    public get userValue() {
        return this.userSubject.value;
    }

    setCurrentUser(user, userType?) {
        return new Promise((resolve, reject) => {
            this.currentUser = user;
            localStorage.setItem('auth_token', user.token);
            if(userType === 'admin') {
                localStorage.setItem('role', 'admin');
                localStorage.setItem('admin_info', JSON.stringify(user))
                resolve(user);
            } else if(userType === 'user') {
                this.apiService.get('my-profile').subscribe(
                    res => {
                        localStorage.setItem('user_info', JSON.stringify(res));
                        this.userSubject.next(res);
                        this.currentUser = res; 
                        resolve(this.currentUser);
                    },
                    err => {
                        this.mixpanelService.error('my-profile-error', {
                        'message': err
                        });
                        reject(null)
                    }
                );
            }
            
        })
        
    }

    getCurrentUser(userType?) {
        if(userType === 'admin') {
            if(localStorage.getItem('admin_info')) {
                this.currentUser = JSON.parse(localStorage.getItem('admin_info')) || false;
                return this.currentUser;
            }
        } else if(userType === 'user') {
            if(localStorage.getItem('user_info')) {
                this.currentUser = JSON.parse(localStorage.getItem('user_info')) || false;
                return this.currentUser;
            }
            return this.currentUser || false;
        }
        return false;   
    }

    getCurrentUserDetails() {
        this.apiService.get('my-profile').subscribe(
            res => {
                localStorage.setItem('user_info', JSON.stringify(res));
                this.userSubject.next(res);
                this.currentUser = res; 
            },
            err => {
                this.mixpanelService.error('my-profile-error', {
                'message': err
                })
            }
        );
    }

    clearData() {
        localStorage.clear();
        this.userSubject.next(null);
    }
}
