<app-hcl-header [data]="questionsData?.client_info"></app-hcl-header>

<div class="py-5 mt-5">

    <div class="container px-5 mt-5 mt-md-3 pb-5">

        <div class="pb-grey-light-bg pt-4 pb-2 border-radius-large text-center parent mb-3 px-2">
            <span class="pb-primary-color-bg text-white py-2 px-3 border-radius-medium hcl-pros-cons-text roboto">Ques
                {{ questionNumber + 1 }}</span>
            <h6 class="rubik pb-grey-light-color">Rank these career options from 1 to 4 based on your preference.</h6>
        </div>

        <div class="progress-bar-test-container mx-auto w-100">
            <div class="progress-bar-test" [ngStyle]="{'width': progressValue + '%'}"></div>
        </div>

        <div class="row my-4">
            <div class="col-12 my-2 col-md-6" *ngFor="let item of currentQuestion" (click)="toggleClick(item)">
                <div class="row d-flex align-items-center cursor-pointer">
                    <div class="col-4">
                        <img class="img-fluid" [src]="item?.image_url" [alt]="item?.career_name">
                    </div>
                    <div class="col-8 border-radius-medium text-justify p-2 position-relative"
                        [ngClass]="item?.selected ? 'selected' : ''">
                        <h6 class="rubik-bold mb-0">{{ item?.career_name }}</h6>
                        <p class="roboto pb-grey-light-color mb-0"><small>{{ item?.career_description }}</small></p>
                        <span *ngIf="item?.selected"
                            class="question-order-badge badge rounded-pill pb-primary-color bg-white shadow-lg">
                            {{ item?.number }}
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex align-items-center justify-content-center buttons">
            <i class="fas fa-arrow-left pb-secondary-color-bg text-white p-2 rounded mx-3 cursor-pointer"
                *ngIf="questionNumber != 0" (click)="fetchPreviousQuestion()"></i>
            <i class="fas fa-arrow-right pb-secondary-color-bg text-white p-2 rounded mx-3 cursor-pointer"
                *ngIf="questionNumber != 34" (click)="fetchNextQuestion()"></i>
            <button *ngIf="questionNumber == 34" class="btn pb-secondary-color-bg text-white roboto"
                (click)="submitQuestions()">Submit</button>

        </div>

    </div>
</div>


<app-hcl-footer></app-hcl-footer>

<div class="loading-div" *ngIf="isLoading">
    <img class="loading-mode-width loading-spinner" src="/assets/images/loader.gif" alt="">
</div>