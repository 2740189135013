import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/api.service';
import { Constants } from 'src/app/class/constants';
import { CustomValidators } from 'src/app/class/custom-validators';
import { ToastMessageService } from 'src/app/services/toast-message.service';
import mixpanel from 'mixpanel-browser';

@Component({
  selector: 'app-hcl-landing-page',
  templateUrl: './hcl-landing-page.component.html',
  styleUrls: ['./hcl-landing-page.component.css']
})
export class HclLandingPageComponent implements OnInit {

  public modalRef: any;
  public modalConfigMd = Constants.lightGreyBackdropModalMd;
  public hclForm: FormGroup;
  public isLoading: boolean = false;
  public userData: any;
  public currentClient: any = [];

  get f() { return this.hclForm.controls }

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private toastMessageService: ToastMessageService,
    private apiService: ApiService,
    private route: ActivatedRoute
  ) { }

  openModal(modalRef: any) {
    if(this.hclForm.valid) {
      const values = this.hclForm.getRawValue();
      let data = {
        email: values.email || '',
        redeem_code: values.redeemCode || '',
        client_id: this.currentClient.id
      };
      this.personalityTestClient(modalRef, data);
    } else {
      this.toastMessageService.showError("Form is not Valid")
    }
  }

  personalityTestClient(modalRef, data) {
    this.isLoading = true
    this.apiService.postUser('personality-test-client', data).subscribe(
      data => {
        if(data.status && !data.personality_test_status) {
          this.userData = data.data
          mixpanel.track('login', {
            data: this.userData
          });
          this.modalRef = this.modalService.open(modalRef, this.modalConfigMd);
        } else if(data.status && data.personality_test_status) {
          localStorage.setItem('personality_test_client', JSON.stringify(data.data));
          this.router.navigate(['client/report'], {
            queryParams: {
              clientCode: data.data.client_code,
              studentId: data.data.student_id,
              redeemCode: data.data.redeem_code
            }
          })
        } else {
          this.toastMessageService.showError(data.message);
        }
      },
      err => {
        mixpanel.track('login-error', {
          error: err
        });
        this.toastMessageService.showError(err.error.detail)
      },
      () => this.isLoading = false
    )
  }

  closeModal() {
    if (this.modalRef) {
      this.modalRef.dismiss();
    }
  }

  createClientForm() {
    this.hclForm = this.fb.group({
     email: ['', [Validators.required, Validators.pattern(CustomValidators.email)]],
     redeemCode: ['', Validators.required]
    });
  }

  navigateToTest() {
    this.closeModal();
    localStorage.setItem('personality_test_client', JSON.stringify(this.userData));
    mixpanel.track('test-started', {
      data: this.userData
    });
    this.router.navigate(['client/test']);
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(
      (data:any) => {
        if(data.name && data.code) {
          this.fetchClientInfo(data);
        } else {
          this.toastMessageService.showError("Please provide correct client name and client code")
          this.router.navigate(['/']);
        }
    })
    this.createClientForm();
  }

  fetchClientInfo(client) {
    this.isLoading = true;
    this.apiService.get(`client-info/${client.name}/${client.code}`).subscribe(
      data => {
        if(data.status) {
          this.currentClient = data.data[0];
        } else {
          this.router.navigate(['/'])
          this.toastMessageService.showError(data.message);
        }
      },
      err => {
        this.router.navigate(['/']);
        this.toastMessageService.showError(err.error.detail);
      },
      () => this.isLoading = false
    )
  }

}
