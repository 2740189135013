
<div class="admin-sidebar col-md-3" *ngIf="toggleSidebar" id="admin-sidebar">
    <div class="d-flex flex-column px-3 pt-2 text-white min-vh-100">

        <h3 class="mb-4 pb-primary-color rubik">Hello Admin</h3>

        <ul class="nav flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start menu" id="menu">
            <li class="roboto my-1">
                <a href="/" class="nav-link align-middle px-0">
                    <i class="fas fa-home mx-2"></i>
                    <span class="ms-1 d-none d-sm-inline">Home</span>
                </a>
            </li>
            <li class="roboto my-1">
                <a routerLink="/admin/dashboard" data-bs-toggle="collapse" class="nav-link px-0 align-middle">
                    <i class="fas fa-tachometer-alt mx-2"></i>
                    <span class="ms-1 d-none d-sm-inline">Dashboard</span>
                </a>
            </li>
            <li class="roboto my-1">
                <a routerLink="/admin/events" class="nav-link px-0 align-middle">
                    <i class="far fa-calendar-alt mx-2"></i>
                    <span class="ms-1 d-none d-sm-inline">Events</span></a>
            </li>
            <li class="roboto my-1">
                <a routerLink="/admin/mentors" data-bs-toggle="collapse" class="nav-link px-0 align-middle">
                    <i class="fas fa-chalkboard-teacher mx-2"></i>
                    <span class="ms-1 d-none d-sm-inline">Mentors</span></a>
            </li>
            <li class="roboto my-1">
                <a routerLink="/admin/courses" class="nav-link px-0 align-middle">
                    <i class="fas fa-users mx-2"></i>
                    <span class="ms-1 d-none d-sm-inline">Courses</span>
                </a>
            </li>
            <li class="roboto my-1">
                <a routerLink="/admin/clients" class="nav-link px-0 align-middle">
                    <i class="fas fa-users mx-2"></i>
                    <span class="ms-1 d-none d-sm-inline">Clients</span>
                </a>
            </li>

            <li class="roboto">
                <a routerLink="/admin/user-management" class="nav-link px-0 align-middle">  
                    <i class="fas fa-users-cog mx-2"></i>
                    <span class="ms-1 d-none d-sm-inline">User Management</span>
                </a>
            </li>
        </ul>
        <hr />
    </div>
</div>