import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { MixpanelService } from 'src/app/mixpanel.service';
import { AuthService } from 'src/app/services/auth.service';
import { ToastMessageService } from 'src/app/services/toast-message.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css'],
})
export class VerifyEmailComponent implements OnInit {
  public currentUser: any;
  public isLoading: boolean = false;
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private toastMessageService: ToastMessageService,
    private mixpanelService: MixpanelService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.authService.getCurrentUserDetails();
    // console.log("user")
    this.currentUser = this.authService.userValue;
  }
  verifyEmail() {
    let data = {
      email: this.currentUser.email,
    };
    this.apiService.postUser('verify-email', data).subscribe(
      (data) => {
        if (data.status) {
          this.toastMessageService.showSuccess(data.message);
          // this.apiService.put('edit-profile', data).subscribe(
          //   (data) => {
          //     if (data.status) {
          //       this.toastMessageService.showSuccess(data.message);
          //       this.authService.getCurrentUserDetails();
          //       setTimeout(() => {
          //         this.isLoading = false;
          //         window.location.reload();
          //       }, 800);
          //     } else {
          //       this.isLoading = false;
          //       this.toastMessageService.showError(data.message);
          //     }
          //   },
          //   (err) => {
          //     this.isLoading = false;
          //     this.toastMessageService.showError(err.error.detail);
          //     this.mixpanelService.error('edit-profile-error', {
          //       'message': err
          //     })
          //   }
          // );
           
        } else {
          this.toastMessageService.showError(data.message);
        }
      },
      (err) => {
        this.mixpanelService.error('verify-otp-error', {
          message: err,
        });
      },
      () => (this.isLoading = false)
    );
  }
}
