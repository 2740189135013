<div class="contact-us">
    <div class="d-flex flex-column align-items-center justify-content-center">
        <h3 class="hcl-report-text-color">Got any questions?</h3>
        <img class="img-fluid my-2" src="../../../../assets/images/contact_us_hcl.png" alt="">
    </div>
    <div class="contact-us-text d-flex flex-column align-items-center justify-content-center mt-5">
        <h4>Contact us at:</h4>
        <h6><b>{{ data }}</b></h6>
    </div>
</div>
  