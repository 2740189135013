<div class="sidebar-main">
  <div class="sidebar-logo-container">
    <a href="/">
      <img
      class="sidebar-probano-logo"
      src="../../../assets/images/probano-logo white transparent h.png"
      alt="ProBano logo"
      />
    </a>
  </div>

  <div class="sidebar-content">
    <ul class="sidebar-list" id="sidebar-list">
      <li [ngClass]="active == 'courses' ? 'svg active' : ''">
        <a (click)="goTo('/courses')">
          <i class="fas fa-layer-group"></i>
          <span>Explore Courses</span>
        </a>
      </li>
      <li [ngClass]="active == 'events' ? 'svg active' : ''">
        <a (click)="goTo('/events')">
          <i class="fas fa-calendar-week"></i>
          <span>Events</span>
        </a>
      </li>
      <li [ngClass]="active == 'mentors' ? 'svg active' : ''">
        <a (click)="goTo('/mentors')">
          <i class="fas fa-user-graduate"></i>
          <span>Mentors</span>
        </a>
      </li>

      <li [ngClass]="active == 'graduation' ? 'svg active' : ''">
        <a (click)="openScholarshipModal(scholarshipModal)">
          <i class="fas fa-graduation-cap"></i>
          <span>Scholarship</span>
        </a>
      </li>

    </ul>
  </div>
</div>

<ng-template #scholarshipModal let-modal>

  <div class="modal-header">
      <h3 class="modal-title rubik-bold">Scholarship</h3>
      <span class="btn btn-close btn-outline-danger" data-dismiss="modal" aria-label="Close" (click)="closeModal()"></span>

  </div>
  <div class="modal-body">
    <div>
      <h5 class="pb-primary-color roboto">Details & Rules</h5>
      <div class="english-rules">
        <div>
          <i class="fas fa-star"></i>
          <span class="roboto pb-grey-light-color">This scholarship is sponsored by Auro Scholar (auroscholar.org).</span>
        </div>
        <div>
          <i class="fas fa-star"></i>
          <span class="roboto pb-grey-light-color">By agreeing to take this test, you are authorizing us to share your data with Auro Scholar.</span>
        </div>
        <div>
          <i class="fas fa-star"></i>
          <span class="roboto pb-grey-light-color">There are multiple quizzes for English, Hindi, Maths, Science, and Social Science on the platform.</span>
        </div>
        <div>
          <i class="fas fa-star"></i>
          <span class="roboto pb-grey-light-color">You can attempt each quiz as per convenience, and get ₹50 per quiz if you score 8/10 in it.</span>
        </div>
      </div>

      <h5 class="pb-primary-color roboto my-3">विवरण और नियम</h5>
      <div class="hindi-rules">
        <div class="my-1">
          <i class="fas fa-star"></i>
          <span class="roboto pb-grey-light-color">यह छात्रवृत्ति ऑरो स्कॉलर (auroscholar.org) द्वारा प्रायोजित है।</span>
        </div>

        <div class="my-1">
          <i class="fas fa-star"></i>
          <span class="roboto pb-grey-light-color">यह परीक्षा देने की सहमति देकर, आप हमें अपना डेटा ऑरो स्कॉलर के साथ साझा करने के लिए अधिकृत कर रहे हैं।</span>
        </div>
        <div class="my-1">
          <i class="fas fa-star"></i>
          <span class="roboto pb-grey-light-color">वेबसाइट पर अंग्रेजी, हिंदी, गणित, विज्ञान, और सामाजिक विज्ञान के लिए कई प्रश्नोत्तरी हैं।</span>
        </div>
        <div class="my-1">
          <i class="fas fa-star"></i>
          <span class="roboto pb-grey-light-color">आप सुविधा के अनुसार प्रत्येक प्रश्नोत्तरी में भाग ले सकते हैं, और यदि आप इसमें 8/10 अंक प्राप्त करते हैं तो प्रति क्विज़ ₹50 जीत सकते हैं।</span>
        </div>
        
      </div>

    </div>
     

  </div>
  <div class="modal-footer">
      <button type="button" class="btn pb-primary-color-bg text-white w-100 border-radius-large roboto" (click)="startScholarship()">Start Scholarship</button>
      <button type="button" class="btn btn-white w-100 my-2 roboto" data-bs-dismiss="modal" (click)="closeModal()">Cancel</button>
  </div>

</ng-template>

<div class="loading-div" *ngIf="isLoading">
  <img class="loading-mode-width loading-spinner" src="/assets/images/loader.gif" alt="">
</div>






