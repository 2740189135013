<app-verify-email></app-verify-email>
<div class="main">
  <div class="sidebar-container">
    <app-sidebar active="courses"></app-sidebar>
  </div>

  <app-mobile-sidebar></app-mobile-sidebar>

  <div class="main-content">
    <app-header class="header" title="My Courses"></app-header>

    <div class="event-container-mobile">
      <h2 class="current-page-name">My Courses</h2>
      <i class="fas fa-bars" (click)="openSidebar()"></i>
    </div>


    <div class="row">
      <div class="col-md-8  modal-body-custom-scroll overflow-auto">
        <div class="course-card-content" *ngFor="let course of courses">
          <img class="course-image" [src]="course?.course_image" [alt]="course?.course_name" />
          <div class="course-info-container">
            <div class="course-information">
              <span class="course-name">{{ course?.course_name }}</span>

              <span class="course-amount">Owned</span>
            </div>
            <p class="course-description">
              {{ course?.course_description }}
            </p>

            <div class="row d-flex justify-content-between align-items-center">
              <div class="col-sm-6 d-flex col-lg-8 col-md-12 justify-content-between">
                <p class="summary d-flex align-items-center justify-content-between">
                  <i class="fas fa-hourglass-half me-2"></i>
                  <span class="course-time">
                    {{ course?.total_hours }} Hrs
                  </span>
                </p>
                <p class="summary d-flex align-items-center">
                  <i class="fas fa-layer-group me-2"></i>
                  <span class="course-lessons">{{ course?.num_of_lessons }} Lessons</span>
                </p>
              </div>

              <div class="col-sm-6 col-lg-4 col-md-12  d-flex justify-content-end">
                <button class="course-detail-button btn-outline" (click)="openModal(courseModal, course)">
                  View Details
                </button>
              </div>
            </div>
          </div>
        </div>
        
      </div>

      <div class="col-md-4 height-80-vh">
        <app-personality-test-prompt></app-personality-test-prompt>

        <app-programs></app-programs>
      </div>
    </div>
  </div>
</div>



<ng-template #courseModal let-modal>

  <div class="modal-header">
      <h4 class="modal-title">My Courses</h4>
      <span class="btn btn-close btn-outline-danger" data-dismiss="modal" aria-label="Close" (click)="closeModal()"></span>

  </div>
  <div class="modal-body modal-body-custom-scroll">

      <img [src]="currentCourseData?.course_image" class="rounded mx-auto d-block img-fluid" [alt]="currentCourseData?.course_name">


      <div class="pt-3 px-2">


        <div class="d-flex align-items-center justify-content-between">
          <h4 class="roboto-bold">{{ currentCourseData?.course_name }}</h4>
          <h4 class="rubik-bold pb-secondary-color">Owned</h4>   
        </div>

        <h6 class="pb-grey-light-color">
          {{ currentCourseData?.course_description }}
        </h6>

        <div class="d-flex justify-content-between my-3">
          <div>
            <i class="fas fa-hourglass-half me-2"></i>
            <span class="event-time">{{ currentCourseData?.total_hours }} Hrs</span>
          </div>
          <div>
            <i class="fas fa-layer-group me-2"></i>
            <span class="event-date">{{ currentCourseData?.num_of_lessons }} Lessons</span>
          </div>
        </div>
      </div>

  </div>
  <div class="modal-footer">
      <button type="button" class="btn pb-primary-color-bg text-white w-100 border-radius-large roboto" (click)="startCourse()">Start Course</button>
      <button type="button" class="btn btn-white w-100 my-2 roboto" data-bs-dismiss="modal" (click)="closeModal()">Cancel</button>
  </div>

</ng-template>

<div class="loading-div" *ngIf="isLoading">
  <img class="loading-mode-width loading-spinner" src="/assets/images/loader.gif" alt="">
</div>



