import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastMessageService } from '../../services/toast-message.service';
import { CustomValidators } from '../../class/custom-validators';
import { ApiService } from 'src/app/api.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css'],
})
export class AdminLoginComponent implements OnInit {
  public loginForm: FormGroup;
  public isLoading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private toastMessageService: ToastMessageService,
    private apiService: ApiService,
    private router: Router,
    private authService: AuthService
  ) {}

  get fm() {
    return this.loginForm.controls;
  }

  createLoginForm() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(CustomValidators.email)]],
      password: ['', [Validators.required]],
    });
  }

  onSubmitForm() {
    this.isLoading = true;
    if (this.loginForm.valid) {
      const values = this.loginForm.getRawValue();
      let loginData: any = {
        email: values.email || '',
        password: values.password || '',
      };
      this.authService.clearData();
      this.adminLogin(loginData);
    } else {
      this.isLoading = false;
      this.toastMessageService.showError('Form is not valid.');
    }
  }

  adminLogin(loginData) {
    this.apiService.postUser('admin/login', loginData).subscribe(
      res => {
        if(res.status) {
          this.authService.setCurrentUser(res, 'admin').then(
            res => {
              this.router.navigate(['admin/dashboard']);
            });;
        } else {
          this.toastMessageService.showError(res.message)
        }
      },
      err => {
        this.toastMessageService.showError(err.error.detail);
      },
      () => this.isLoading = false
    );
  }

  ngOnInit() {
    this.createLoginForm();
  }

}
