<app-admin-header></app-admin-header>
<div class="container py-5">
    <div>
        <h2>Clients</h2>
    </div>
    
    <button type="button" class="btn btn-primary float-end m-3" (click)="openModal(clientModal)">
        Add Client
    </button>
    

    <div class="table-responsive col-md-12">
       
        <table class="table table-striped table-secondary">
            <thead>
              <tr>
                <th>#</th>
                <th class="width-150-px">Client Name</th>
                <th class="width-150-px">Client Code</th>
                <th>Primary Client Logo</th>
                <th>Primary Client Website</th>
                <th class="width-200-px">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of clients; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ item?.client_name }}</td>
                <td>{{ item?.client_code }}</td>
                <td>{{ item?.primary_client_logo }}</td>
                <td>{{ item?.primary_web_url }}</td>
                <td class="width-200-px">
                    <i class="fa fa-eye" data-bs-toggle="tooltip" data-bs-placement="top" title="View" (click)="openModal(clientModal, item, true)"></i>
                    <i class="fa fa-edit" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" (click)="openModal(clientModal, item, false, true)"></i>
                    <i class="fa fa-trash-o" aria-hidden="true" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete" (click)="openDeleteModal(deleteClientModal, item)"></i>
                </td>
              </tr>
              
            </tbody>
        </table>
    </div>
    
</div>


<ng-template #clientModal let-modal>

    <div class="modal-header">
        <h5 class="modal-title" *ngIf="!isView">{{ isEdit ? 'Edit' : 'Add' }} Client</h5>
        <h5 class="modal-title" *ngIf="isView">View Client</h5>
        <span class="btn btn-close btn-outline-danger" data-dismiss="modal" aria-label="Close" (click)="closeModal()"></span>
  
    </div>
    <div class="modal-body">

        <form [formGroup]="clientForm">
            <div class="row clearfix px-3">
                <div class="col-md-12 col-lg-6">
                    <div class="form-group mt-3">
                        <label class="secondary-text-font-size primary-theme-color mb-1">Client Name</label>
                        <input type="text" name="name" formControlName="name"
                            [readonly]="isView"
                            class="form-input form-control theme-form-control pt-2 placeholder-fix" autocomplete="off" />
                        <div class="text-danger mt-1" *ngIf="f.name.touched && f.name?.errors?.required">
                            Client Name is Required
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-lg-6">
                    <div class="form-group mt-3">
                        <label class="secondary-text-font-size primary-theme-color mb-1">Client Web(route) Url</label>
                        <input type="text" name="name" formControlName="clientWebUrl"
                            [readonly]="isView"
                            class="form-input form-control theme-form-control pt-2 placeholder-fix" autocomplete="off" />
                        <div class="text-danger mt-1" *ngIf="f.clientWebUrl.touched && f.clientWebUrl?.errors?.required">
                            Client Web Url is Required
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-lg-6">
                    <div class="form-group mt-3">
                        <label class="secondary-text-font-size primary-theme-color mb-1">Client Contact Email</label>
                        <input type="text" name="name" formControlName="clientContactEmail"
                            [readonly]="isView"
                            class="form-input form-control theme-form-control pt-2 placeholder-fix" autocomplete="off" />
                        <div class="text-danger mt-1" *ngIf="f?.clientContactEmail?.touched && f?.clientContactEmail?.errors?.required">
                            Client Contact Email is Required
                        </div>
                    </div>
                </div>


                <div class="col-md-12 col-lg-6" *ngIf="isView">
                    <div class="form-group mt-3">
                        <label class="secondary-text-font-size primary-theme-color mb-1">Client Code</label>
                        <input type="text" name="clientCode" formControlName="clientCode"
                            [readonly]="true"
                            class="form-input form-control theme-form-control pt-2 placeholder-fix" autocomplete="off" />
                    </div>
                </div>


                <div class="col-md-12 col-lg-6">
                    <div class="form-group mt-3">
                        <label class="secondary-text-font-size primary-theme-color mb-1">Primary Client Image Url</label>
                        <input type="text" name="primaryImageUrl" formControlName="primaryImageUrl"
                            [readonly]="isView"
                            class="form-input form-control theme-form-control pt-2 placeholder-fix" autocomplete="off" />
                        <div class="text-danger mt-1" *ngIf="f.primaryImageUrl.touched && f.primaryImageUrl?.errors?.required">
                            Primary Client Image Url is Required
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-lg-6">
                    <div class="form-group mt-3">
                        <label class="secondary-text-font-size primary-theme-color mb-1">Primary Client Website Url</label>
                        <input type="text" name="primaryWebUrl" formControlName="primaryWebUrl"
                            [readonly]="isView"
                            class="form-input form-control theme-form-control pt-2 placeholder-fix" autocomplete="off" />
                        <div class="text-danger mt-1" *ngIf="f.primaryWebUrl.touched && f.primaryWebUrl?.errors?.required">
                            Primary Client Website Url is Required
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-lg-6">
                    <div class="form-group mt-3">
                        <label class="secondary-text-font-size primary-theme-color mb-1">Secondary Client Image Url</label>
                        <input type="text" name="secondaryImageUrl" formControlName="secondaryImageUrl"
                            [readonly]="isView"
                            class="form-input form-control theme-form-control pt-2 placeholder-fix" autocomplete="off" />
                        <div class="text-danger mt-1" *ngIf="f.secondaryImageUrl.touched && f.secondaryImageUrl?.errors?.required">
                            Secondary Client Image Url is Required
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-lg-6">
                    <div class="form-group mt-3">
                        <label class="secondary-text-font-size primary-theme-color mb-1">Secondary Client Website Url</label>
                        <input type="text" name="secondaryWebUrl" formControlName="secondaryWebUrl"
                            [readonly]="isView"
                            class="form-input form-control theme-form-control pt-2 placeholder-fix" autocomplete="off" />
                        <div class="text-danger mt-1" *ngIf="f.secondaryWebUrl.touched && f.secondaryWebUrl?.errors?.required">
                            Secondary Client Website Url is Required
                        </div>
                    </div>
                </div>

            </div>
        </form>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="closeModal()">Close</button>
        <button type="button" class="btn btn-primary" (click)="onSubmitForm()" [disabled]="clientForm.invalid"  *ngIf="!isView && !isEdit">Create</button>
        <button type="button" class="btn btn-primary" (click)="onUpdateClient()" [disabled]="clientForm.invalid"  *ngIf="isEdit">Update</button>
    </div>

</ng-template>

<ng-template #deleteClientModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Delete Client</h5>
        <span class="btn btn-close btn-outline-danger" data-dismiss="modal" aria-label="Close" (click)="closeModal()"></span>
    </div>

    <div class="modal-body">
        <h4>Are you sure you want to delete this client?</h4>
        <h5 class="mt-3">Client Name: <span class="text-primary">{{ clientName }}</span></h5>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="closeModal()">Close</button>
        <button type="button" class="btn btn-danger" (click)="onDelete()">Delete</button>
    </div>
</ng-template>

<div class="loading-div" *ngIf="isLoading">
    <img class="loading-mode-width loading-spinner" src="/assets/images/loader.gif" alt="">
</div>