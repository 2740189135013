import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from '../../class/constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../../class/custom-validators'; 
import { ToastMessageService } from 'src/app/services/toast-message.service';
import { ApiService } from 'src/app/services/api.service';
import { LoaderService } from 'src/app/services/loader/loader.service';


@Component({
  selector: 'app-admin-mentors',
  templateUrl: './admin-mentors.component.html',
  styleUrls: ['./admin-mentors.component.css']
})
export class AdminMentorsComponent implements OnInit {

  public isEdit: boolean = false;
  public isView: boolean = false;

  public modalRef: any;
  public modalConfigLg = Constants.lightGreyBackdropModalLg;

  public mentorForm: FormGroup;
  public isLoading: boolean = false;
  public mentors: any;
  public mentorName: string = '';
  public mentorDescription: string = '';
  public mentorShortDescription: string = '';
  public mentorImage: string = '';
  public currentMentorId: string = '';

  get f() { return this.mentorForm.controls }



  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private toastMessageService: ToastMessageService,
    private apiService: ApiService,
  ) { }

  ngOnInit() {
    this.createMentorForm()
    this.getAllMentors();
  }

  getAllMentors() {
    this.isLoading = true
    this.apiService.get('get-all-guides').subscribe(
      data => {
        this.mentors = data;
      },
      err => {
        this.toastMessageService.showError(err.error.detail);
      },
      () => this.isLoading = false
    )
  }

  openModal(modalRef: any, data?: any, isView?: any, isEdit?: any) {
    this.resetFormData();
    if (isView) {
      this.isView = true;
      this.patchValue(data);
      this.mentorImage = data.img_url
    }
    if (isEdit) {
      this.isEdit = true;
      this.patchValue(data);
    }
    this.modalRef = this.modalService.open(modalRef, this.modalConfigLg);
  }

  patchValue(data) {
    if(data.career_guide_id) {
      this.currentMentorId = data.career_guide_id
    }
    this.mentorForm.patchValue({
      mentorName: data.name,
      mentorEmail: data.email,
      mentorImageUrl: data.img_url,
      mentorDescription: data.description,
      linkedinUrl: data.linkedin_url || '-',
      mentorTitle: data.title,
      isAvailable: data.isAvailable || true 
    })
  }

  openDeleteModal(modalRef: any, data?:any) {
    this.mentorName = data.name
    this.mentorImage = data.img_url
    this.mentorShortDescription = data.description.length > 600 ? data.description.substr(0, 600) + "..." : data.description
    this.modalRef = this.modalService.open(modalRef, this.modalConfigLg);
  }


  resetFormData() {
    this.mentorForm.reset();
    this.isEdit = false;
    this.isView = false;
  }

  closeModal() {
    if (this.modalRef) {
      this.modalRef.dismiss();
      this.isEdit = false;
      this.isView = false;
    }
  }

  createMentorForm() {
    this.mentorForm = this.fb.group({
      mentorName: ['', [Validators.required, Validators.pattern(CustomValidators.onlyChars)]],
      mentorEmail: ['', [Validators.required, Validators.pattern(CustomValidators.email)]],
      mentorImageUrl: ['', [Validators.required, Validators.pattern(CustomValidators.websiteUrl)]],
      mentorDescription: ['', Validators.required],
      linkedinUrl: ['', [Validators.required, Validators.pattern(CustomValidators.websiteUrl)]],
      mentorTitle: ['', [Validators.required, Validators.pattern(CustomValidators.onlyChars)]],
      isAvailable: true
    });
  }

  onSubmitForm() {
    if (this.mentorForm.valid) {
      const values = this.mentorForm.getRawValue();
      let mentorData: any = {
        email: values.email || '',
        password: values.password || '',
      };
      this.toastMessageService.showSuccess('Form is valid')
    } else {
      this.toastMessageService.showError('Form is not valid.');
    }
  }






}