import { MixpanelService } from './../mixpanel.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {

  public isLoading: boolean = false;

  constructor(
    private router: Router, 
    private mixpanelService: MixpanelService
  ) { }

  ngOnInit() {
    this.mixpanelService.pageTrack('Loaded 404 not found page')
  }

  public goHome() {
    this.router.navigate(['/'])
  }
}
