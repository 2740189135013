import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HclLandingPageComponent } from './hcl/hcl-landing-page/hcl-landing-page.component';
import { HclPersonalityTestComponent } from './hcl/hcl-personality-test/hcl-personality-test.component';
import { HclReportComponent } from './hcl/hcl-report/hcl-report.component';

const routes: Routes = [
    {
      path: '',
      component: HclLandingPageComponent
    },
    {
      path: 'test',
      component: HclPersonalityTestComponent
    },
    {
      path: 'report',
      component: HclReportComponent
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClientsRoutingModule { }
