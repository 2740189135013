<div class="full-page pt-10-vh bg-primary-theme pb-5 px-3">
  <div class="container auth-main-content">
    <div class="row">
      <div class="col-md-7 pt-4 d-flex flex-column align-items-center logo-container pb-5">

        <img class="probano-logo img-fluid my-2 mb-5" src="../../assets/images/probano_dark_logo.svg" alt="ProBano logo" />
        <img class="img-fluid mt-5" src="../../assets/images/auth_illustration.svg" alt="Probano image" />

      </div>
      <div class="col-md-5 pt-4 pb-5 px-5">

        <h4 class="mt-4 rubik">
          Welcome To <span class="probano-text">ProBano</span>
        </h4>


        <div class="px-3">
          <form [formGroup]="userLoginForm">
            <div class="row">
              <div class="col-12 px-0">
                <div class="form-group mt-3">
                  <label
                    class="secondary-text-font-size primary-theme-color mb-1 roboto pb-grey-light-color">Email</label>
                  <input type="text" name="email" formControlName="email"
                    class="form-input form-control theme-form-control py-2 placeholder-fix pb-fade-grey-light-color-bg"
                    autocomplete="off" />
                  <div class="text-danger mt-1" *ngIf="f?.email?.touched && f?.email?.errors?.required">
                    Email is Required
                  </div>
                  <div class="text-danger mt-1" *ngIf="f?.email?.touched && f?.email?.errors?.pattern">
                    Please enter a valid email
                  </div>
                </div>
              </div>

              <div class="col-12 px-0">
                <div class="form-group mt-3">

                  <label class="secondary-text-font-size primary-theme-color mb-1 roboto pb-grey-light-color">Password
                  </label>
                  <input type="password" name="password" formControlName="password"
                    class="form-input form-control theme-form-control py-2 placeholder-fix pb-fade-grey-light-color-bg"
                    autocomplete="off" />
                  <div class="text-danger mt-1" *ngIf="f?.password.touched && f?.password?.errors?.required">
                    Password is Required
                  </div>
                </div>
              </div>

            </div>
          </form>


          <div class="text-end my-4">
            <span class="cursor-pointer text-underline pb-grey-light-color" [routerLink]="['/forget-password']">
              Forgot Password?
            </span>
          </div>

          <button type="button" class="btn pb-primary-color-bg text-white roboto w-100" (click)="submitLoginForm()"
            [disabled]="userLoginForm.invalid">
            Login
          </button>


          <div class="or-text-container my-4">
            <div></div>
            <p class="mt-2 mx-1 pb-grey-light-color">Or</p>
            <div></div>
          </div>

          <!-- <div class="social-buttons">
            <div class="facebook-button" (click)="signInWithFB()">
              <i class="fab fa-facebook-square facebook-icon"></i>Facebook
            </div>
            <div class="google-button" (click)="signInWithGoogle()">
              <img class="google-icon" src="../../assets/google-icon.svg" alt="Google icon" />
              Google
            </div>
          </div> -->

          <p class="have-text">
            Have an account?
            <span class="signup-text text-underline" [routerLink]="['/signup']">Sign up</span>
          </p>

        </div>

      </div>
    </div>
  </div>
</div>


<div class="loading-div" *ngIf="isLoading">
  <img class="loading-mode-width loading-spinner" src="/assets/images/loader.gif" alt="">
</div>