import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../api.service';
import { MixpanelService } from '../../mixpanel.service';
import { Constants } from 'src/app/class/constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastMessageService } from 'src/app/services/toast-message.service';

@Component({
  selector: 'app-user-courses',
  templateUrl: './user-courses.component.html',
  styleUrls: ['./user-courses.component.css']
})
export class UserCoursesComponent implements OnInit {

  public courses = new Array<courseData>();
  
  public currentCourseData: any;
  public modalRef: any;
  public modalConfigMd = Constants.lightGreyBackdropModalMd;
  public isLoading: boolean = false;
  public personality_test_status;

  constructor(
    private apiService: ApiService, 
    private router: Router, 
    private mixpanelService: MixpanelService,
    private modalService: NgbModal,
    private toastMessageService: ToastMessageService
  ) {
   
  }

  openModal(modalRef: any, data: any) {
    this.resetData();
    this.currentCourseData = data;
    this.modalRef = this.modalService.open(modalRef, this.modalConfigMd);
  }

  resetData() {
    this.currentCourseData = [];
  }

  closeModal() {
    if (this.modalRef) {
      this.modalRef.dismiss();
    }
  }

  public openSidebar () {
    document.getElementById("sidenav").style.width = "50%";
  }

  public closeSidebar() {
    document.getElementById("sidenav").style.width = "0%";
  }

  ngOnInit() {
    this.mixpanelService.pageTrack('Loaded my course page')
    this.getMyCourses();
  }

  startCourse() {
    this.isLoading = true;
    this.router.navigate(['/course-videos'], {
      queryParams: {
        courseId: this.currentCourseData?.course_id
      }
    })
    this.closeModal();
    this.isLoading = false;
  }

  getMyCourses() {
    this.isLoading = true;
    this.apiService.get('my-course').subscribe(
      data => {
        this.courses = data['course enroll'];
      }, 
      err => {
        this.toastMessageService.showError(err.error.detail);
        this.mixpanelService.error('my-course-error', {
          'message': err
        });
      },
      () => this.isLoading = false
    )
  }
}

interface courseData {
  amount: string,
  course_description: string,
  course_id: number,
  course_image: string,
  course_name: string,
  num_of_lessons: string,
  total_hours: string
}

