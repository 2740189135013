import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { MixpanelService } from 'src/app/mixpanel.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public currentUser: any;
  public is_logged_in;
  @Input() title: string;

  constructor(
    private apiService: ApiService, 
    private router: Router,
    private mixpanelService: MixpanelService,
    private authService: AuthService
  ) {
  }

  ngOnInit() {
    this.currentUser = this.authService.userValue;
  }

  public goToProfile () {
    this.router.navigate(['/my-profile'])
  }

  public goToCourses () {
    this.router.navigate(['/my-courses'])
  }

  public logout () {
    this.apiService.get("logout").subscribe(
      (data) => {
        this.mixpanelService.event('user-logout')
        this.authService.clearData();
        this.router.navigate(["/login"]);
      },
      (err) => {
        this.mixpanelService.error('logout-error', {
          'message': err
        })
      }
    );

  }


}
