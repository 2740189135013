import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClientsRoutingModule } from './clients-routing.module';
import { HclReportComponent } from './hcl/hcl-report/hcl-report.component';
import { HclFooterComponent } from './hcl/hcl-footer/hcl-footer.component';
import { HclHeaderComponent } from './hcl/hcl-header/hcl-header.component';
import { ContactUsComponent } from './hcl/contact-us/contact-us.component';
import { HclMainPageComponent } from './hcl/hcl-main-page/hcl-main-page.component';
import { StudentInfoComponent } from './hcl/student-info/student-info.component';
import { CareerAndTraitsComponent } from './hcl/career-and-traits/career-and-traits.component';
import { HclPersonalityTestComponent } from './hcl/hcl-personality-test/hcl-personality-test.component';
import { HclLandingPageComponent } from './hcl/hcl-landing-page/hcl-landing-page.component';
import { ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    HclReportComponent,
    HclFooterComponent,
    HclHeaderComponent,
    ContactUsComponent,
    HclMainPageComponent,
    StudentInfoComponent,
    CareerAndTraitsComponent,
    HclPersonalityTestComponent,
    HclLandingPageComponent
  ],
  imports: [
    CommonModule,
    ClientsRoutingModule,
    ReactiveFormsModule
  ]
})
export class ClientsModule { }
