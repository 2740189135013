import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-career-and-traits',
  templateUrl: './career-and-traits.component.html',
  styleUrls: ['./career-and-traits.component.css']
})
export class CareerAndTraitsComponent implements OnInit {

  @Input() data: any;

  constructor() { }

  ngOnInit(): void {
  }

}
