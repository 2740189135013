import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from '../../class/constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../../class/custom-validators';
import { ToastMessageService } from 'src/app/services/toast-message.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ApiService } from "../../services/api.service";

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.css']
})

export class AdminUsersComponent implements OnInit {

  public isEdit: boolean = false;
  public isView: boolean = false;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};

  public modalRef: any;
  public modalConfigLg = Constants.lightGreyBackdropModalLg;

  public userForm: FormGroup;

  public events: any = [];

  public isLoading: boolean = false;


  get f() { return this.userForm.controls }

  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private toastMessageService: ToastMessageService,
    private loaderService: LoaderService,
    private apiService: ApiService
  ) { }

  openModal(modalRef: any, data?: any, isView?: any, isEdit?: any) {
    this.resetFormData();
    if (isView) {
      this.isView = true;
      this.userForm.patchValue({
        eventName: data.event_name,
        eventUrl: data.event_url,
        eventImageUrl: data.event_image,
        eventDescription: data.description
      })
    }
    if (isEdit) {
      this.isEdit = true;
      this.userForm.patchValue({
        eventName: data.event_name,
        eventUrl: data.event_url,
        eventImageUrl: data.event_image,
        eventDescription: data.description
      })
    }
    this.modalRef = this.modalService.open(modalRef, this.modalConfigLg);
  }

  createUser() {
    this.userForm = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern(CustomValidators.email)]],
      class: ['', [Validators.pattern(CustomValidators.onlyNumber)]],
      school: '',
      state: '',
      client: ['', [Validators.required]]
    });
  }

  resetFormData() {
    this.userForm.reset()
    this.isEdit = false;
    this.isView = false;
  }

  closeModal() {
    if (this.modalRef) {
      this.modalRef.dismiss();
    }
  }

  ngOnInit(): void {
    this.createUser()

    this.dropdownList = [
      { "id": 1, "itemName": "India" },
      { "id": 2, "itemName": "Singapore" },
      { "id": 3, "itemName": "Australia" },
      { "id": 4, "itemName": "Canada" },
      { "id": 5, "itemName": "South Korea" },
      { "id": 6, "itemName": "Germany" },
      { "id": 7, "itemName": "France" },
      { "id": 8, "itemName": "Russia" },
      { "id": 9, "itemName": "Italy" },
      { "id": 10, "itemName": "Sweden" }
    ];
    this.selectedItems = [
      { "id": 2, "itemName": "Singapore" },
      { "id": 3, "itemName": "Australia" },
      { "id": 4, "itemName": "Canada" },
      { "id": 5, "itemName": "South Korea" }
    ];
    this.dropdownSettings = {
      singleSelection: false,
      text: "Select Countries",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "myclass custom-class"
    };
  }

}
