<div class="full-page pt-10-vh bg-primary-theme pb-5 px-3">
  <div class="container auth-main-content">
    <div class="row">
      <div class="col-md-7 pt-4 d-flex flex-column align-items-center logo-container pb-5">
        <img class="probano-logo img-fluid my-2 mb-5" src="../../assets/images/probano_dark_logo.svg" alt="ProBano logo" />
        <img class="img-fluid mt-5" src="../../assets/images/auth_illustration.svg" alt="Probano image" />
      </div>
      <div class="col-md-5 pt-4 pb-5 px-5">
        <img class="img-fluid pb-grey-light-color cursor-pointer" src="../../assets/images/left-arrow.svg"
          alt="back arrow" (click)="goBack()" />

        <div class="px-3">
          <img class="img-fluid d-block mx-auto" src="../../assets/images/reset_side_shape.svg" alt="Forgot image" />

          <h4 class="text-center rubik-bold">Forgot Password?</h4>
          <p class="text-center">
            Enter The Email Address associated with your account and we will
            send you a link to reset your password
          </p>

          <form [formGroup]="forgotPasswordForm">
            <div class="row">
              <div class="col-12 px-0" *ngIf="!showPasswordSection">
                <div class="form-group">
                  <label
                    class="secondary-text-font-size primary-theme-color mb-1 roboto pb-grey-light-color">Email</label>
                  <input type="text" name="email" formControlName="email"
                    class="form-input form-control theme-form-control py-2 placeholder-fix pb-fade-grey-light-color-bg"
                    autocomplete="off" />
                  <div class="text-danger mt-1" *ngIf="f?.email?.touched && f?.email?.errors?.required">
                    Email is Required
                  </div>
                  <div class="text-danger mt-1" *ngIf="f?.email?.touched && f?.email?.errors?.pattern">
                    Please enter a valid email
                  </div>
                </div>
              </div>

              <div class="col-12 px-0" *ngIf="showOtpSection && !showPasswordSection">
                <div class="form-group mt-3">
                  <label class="secondary-text-font-size primary-theme-color mb-1 roboto pb-grey-light-color">OTP
                  </label>
                  <input type="text" name="otp" formControlName="otp"
                    class="form-input form-control theme-form-control py-2 placeholder-fix pb-fade-grey-light-color-bg"
                    autocomplete="off" />
                  <div class="text-danger mt-1" *ngIf="f?.otp.touched && f?.otp?.errors?.required">
                    OTP is Required
                  </div>
                </div>
              </div>

              <div class="col-12 px-0" *ngIf="showPasswordSection">
                <div class="form-group mt-3">

                  <label class="secondary-text-font-size primary-theme-color mb-1 roboto pb-grey-light-color">New Password
                  </label>
                  <input type="password" name="password" formControlName="password"
                    class="form-input form-control theme-form-control py-2 placeholder-fix pb-fade-grey-light-color-bg"
                    autocomplete="off" />
                  <div class="text-danger mt-1" *ngIf="f?.password.touched && f?.password?.errors?.required">
                    Password is Required
                  </div>
                  <div class="text-danger mt-1" *ngIf="f?.password.touched && f?.password?.errors?.minLength">
                    Password length should be greater than 6 characters
                  </div>
                </div>
              </div>

              <div class="col-12 px-0" *ngIf="showPasswordSection">
                <div class="form-group mt-3">
                  <label class="secondary-text-font-size primary-theme-color mb-1 roboto pb-grey-light-color">Confirm New Password
                  </label>
                  <input type="password" name="confirmPassword" formControlName="confirmPassword"
                    class="form-input form-control theme-form-control py-2 placeholder-fix pb-fade-grey-light-color-bg"
                    autocomplete="off" />
                  <div class="text-danger mt-1" *ngIf="f?.confirmPassword.touched && f?.confirmPassword?.errors?.required">
                    Confirm Password is Required
                  </div>
                  <div class="text-danger mt-1" *ngIf="f?.confirmPassword.touched && f?.confirmPassword?.errors?.minLength">
                    Password length should be greater than 6 characters
                  </div>

                  <div class="text-danger mt-1"  *ngIf="forgotPasswordForm?.get('password')?.value != forgotPasswordForm.get('confirmPassword')?.value && forgotPasswordForm?.get('confirmPassword')?.value != null">
                    Password does not match</div>
                </div>
              </div>


              <button type="button" class="btn pb-primary-color-bg text-white roboto w-100 mt-4" (click)="sendEmailForOtp()">
                Send
              </button>

            </div>
          </form>

        

        </div>
      </div>
    </div>
  </div>
</div>

<div class="loading-div" *ngIf="isLoading">
  <img class="loading-mode-width loading-spinner" src="/assets/images/loader.gif" alt="" />
</div>