import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomValidators } from 'src/app/class/custom-validators';
import { ToastMessageService } from 'src/app/services/toast-message.service';
import { ApiService } from '../../api.service';
import { MixpanelService } from '../../mixpanel.service';


@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css'],
})
export class ForgetPasswordComponent implements OnInit {

  public forgotPasswordForm: FormGroup;
  public isLoading: boolean = false;

  public showOtpSection: boolean = false;
  public showPasswordSection: boolean = false;

  get f() { return this.forgotPasswordForm.controls }

  constructor(
    private apiService: ApiService,
    private router: Router,
    private location: Location,
    private mixpanelService: MixpanelService,
    private fb: FormBuilder,
    private toastMessageService: ToastMessageService
  ) { }

  public goBack() {
    this.location.back()
  }



  ngOnInit(): void {
    // this.mixpanelService.pageTrack('Loaded forget password page');
    this.createForgotPasswordForm();
  }

  sendEmailForOtp() {
    this.isLoading = true;
    const values = this.forgotPasswordForm.getRawValue();

    if(this.showOtpSection && !this.showPasswordSection) {
      this.verifyOTP(values);
    } else if(!this.showOtpSection && this.showPasswordSection) {
      this.changePassword(values)
    } else {
      this.sendOTP(values);
    }

  }

  changePassword(values) {
    let data = {
      email: values.email,
      password: values.password
    }
    this.apiService
    .postUser('reset-password', data)
    .subscribe(
      data => {
        if (data.status) {
          this.router.navigate(['/login'])
          this.toastMessageService.showSuccess(data.message)
        } else {
          this.toastMessageService.showError(data.message);
        }
      },
      err => {;
        this.mixpanelService.error('verify-otp-error', {
          'message': err
        });
      },
      () => this.isLoading = false
    );
  }

  verifyOTP(values) {
    this.isLoading = true;
    let data = {
      email: values.email,
      otp: values.otp.trim()
    }
    this.apiService
      .postUser('verify-otp', data)
      .subscribe(
        data => {
          if (data.status) {
            this.showOtpSection = false;
            this.showPasswordSection = true;
          } else {
            this.toastMessageService.showError(data.message);
          }
        },
        err => {
          this.mixpanelService.error('verify-otp-error', {
            'message': err
          });
        },
        () => this.isLoading = false
      );
  }

  sendOTP(values) {
    this.apiService
      .postUser('forget-password', { email: values.email })
      .subscribe(
        data => {
          if (data.status) {
            this.showOtpSection = true;
            this.toastMessageService.showSuccess(data.message, 10000);
            this.forgotPasswordForm.controls['email'].disable();
          } else {
            this.toastMessageService.showError(data.message);
          }
        },
        err => {
          this.mixpanelService.error('forget-password-error', {
            'message': err
          });
        },
        () => this.isLoading = false
      );
  }


  createForgotPasswordForm() {
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(CustomValidators.email)]],
      otp: ['', [Validators.required, Validators.pattern(CustomValidators.onlyNumber)]],
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
    },
    {
      validators: this.passwordConfirming
    });
  }

  passwordMismatch() {
    let password = this.forgotPasswordForm.get('password');
    let confirmPassword = this.forgotPasswordForm.get('confirmPasssword');
    if(password?.value != confirmPassword?.value && confirmPassword?.value != null) {
      return true;
    }
    return false;
  }

  passwordConfirming(c: AbstractControl): { invalid: boolean } {
    if (c.get('password').value !== c.get('confirmPassword').value) {
      return { invalid: true };
    } else {
      return { invalid: false }
    }
}


}
