import { Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  @Input() toggleSidebar: boolean = false;

  constructor(
    private el: ElementRef
  ) { }

  ngOnInit(): void {
    let sidebar = document.getElementById('admin-sidebar');

    this.el.nativeElement.addEventListener('click', (event) => {
      if (event.target == sidebar) {
        this.toggleSidebar = false;
      }
    });
  }

  

}
