import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomValidators } from 'src/app/class/custom-validators';
import { AuthService } from 'src/app/services/auth.service';
import { ToastMessageService } from 'src/app/services/toast-message.service';
import { ApiService } from '../../api.service';
import { MixpanelService } from '../../mixpanel.service';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
})
export class SignupComponent implements OnInit {

  public userSignUpForm: FormGroup;
  public isLoading: boolean = false;

  public displayNextForm: boolean = false;

  get f() { return this.userSignUpForm.controls }

  constructor(
    private apiService: ApiService,
    private router: Router,
    private mixpanelService: MixpanelService,
    private fb: FormBuilder,
    private toastMessageService: ToastMessageService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.mixpanelService.pageTrack('Loaded signup page');
    this.createUserSignUpForm();
  }

  submitForm() {
    const values = this.userSignUpForm.getRawValue();

    if(values.password !== values.confirmPassword) {
      return;
    }

    if(this.userSignUpForm.valid) {
      let data = {
        full_name: values.fullName,
        email: values.email,
        password: values.password,
        school_name: values.school,
        class_name: values.class,
        city: values.city,
        mobile: values.mobile,
        is_student: true
      }
      this.submitSignUpData(data)
    } else {
      this.toastMessageService.showError("Form is not valid.")
    }
  }

  submitSignUpData(data) {
    this.isLoading = true;
    this.apiService.postUser('student-signup', data).subscribe(
      res => {
        if (res.status) {
          this.mixpanelService.event('signup-completed');
          this.toastMessageService.showWarning("Please check your email for verification.");
          // this.router.navigate(['courses']);
          let sampleData = {
            "email":data.email,
            "password":data.password,
          }
          this.apiService.postUser('login/email', sampleData).subscribe(
            data => {
              if (data.status) {
               this.authService.setCurrentUser(data, 'user').then(
                 res => {
                 this.router.navigate(['courses']);
               });
              } else {
                this.toastMessageService.showError(data.message)
              }
            },
            err => {
              this.toastMessageService.showError(err.error.detail);
            },
            () => this.isLoading = false
          )
        }
      },
      err => {
        this.toastMessageService.showError(err.error.detail);
        this.mixpanelService.error('signup-error', {
          'message': err.error
        })
      },
      () => this.isLoading = false
    );

  }

  checkValidation() {
    const { fullName, email, password, confirmPassword } = this.userSignUpForm.getRawValue();
    if(fullName == '' || email == '' || password == '' || confirmPassword == '') return true;
    if(confirmPassword != password) return true;
    return false;
  }

  showNextForm() {
    this.isLoading = true;
    this.authService.clearData();
    let email = this.userSignUpForm.getRawValue().email
    this.apiService.post('email/already-exist', { email } ).subscribe(
      data => {
        if(data.status) this.displayNextForm = true;
        if(!data.status) this.toastMessageService.showError(data.message)
      },
      err => {
        this.toastMessageService.showError(err.error.detail);
      },
      () => this.isLoading = false
    )
  }

  createUserSignUpForm() {
    this.userSignUpForm = this.fb.group({
      fullName: ['', [Validators.required, Validators.pattern(CustomValidators.onlyChars)]],
      email: ['', [Validators.required, Validators.pattern(CustomValidators.email)]],
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
      class: ['', Validators.required],
      school: ['', [Validators.required, Validators.pattern(CustomValidators.onlyChars)]],
      city:  ['', [Validators.required, Validators.pattern(CustomValidators.onlyChars)]],
      mobile: ['', [Validators.required, Validators.pattern(CustomValidators.onlyNumber)]]
    });
  }




}
