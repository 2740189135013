import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../api.service';
import { MixpanelService } from 'src/app/mixpanel.service';


@Component({
  selector: 'app-impact-report',
  templateUrl: './impact-report.component.html',
  styleUrls: ['./impact-report.component.css'],
})
export class ImpactReportComponent implements OnInit {
  public impact_report_data = new Array<impactReport>();
  public impactReportPointsData = new Array<impactReportPoints>();
  public filledStarArray = new Array()
  public halfStarArray = new Array()
  public emptyStarArray = new Array()

  constructor(private apiService: ApiService, private location: Location, private router: Router, private mixpanelService: MixpanelService) {
    this.impact_report_data = [
      {
        title: 'Self exploration',
        description: 'You always uphold the integrity of your organization.',
        score: 0,
        filled_star: 0,
        half_star: 0,
        empty_star: 0,
        image_url:
          'https://career-data.s3.ap-south-1.amazonaws.com/student-impact/undraw_career_progress_ivdb+(1).png',
      },
      {
        title: 'Career exploration',
        description: 'You always uphold the integrity of your organization.',
        score: 0,
        filled_star: 0,
        half_star: 0,
        empty_star: 0,
        image_url:
          'https://career-data.s3.ap-south-1.amazonaws.com/student-impact/undraw_Select_option_re_u4qn.png',
      },
      {
        title: 'Career planning',
        description: 'You always uphold the integrity of your organization.',
        score: 0,
        filled_star: 0,
        half_star: 0,
        empty_star: 0,
        image_url:
          'https://career-data.s3.ap-south-1.amazonaws.com/student-impact/undraw_Next_option_re_r9uf.png',
      },
      {
        title: 'Skill enhancement',
        description: 'You always uphold the integrity of your organization.',
        score: 0,
        filled_star: 0,
        half_star: 0,
        empty_star: 0,
        image_url:
          'https://career-data.s3.ap-south-1.amazonaws.com/student-impact/undraw_Updated_resume_re_q1or.png',
      },
    ];
    this.apiService.get('assessment/result').subscribe(
      (data) => {
        if(data.status == true) {
          for(let i = 0; i < this.impact_report_data.length; i++) {
            if(i == 0) {
                this.impact_report_data[i].score = data['result']['self_exploration']
                let x = Math.floor(data['result']['self_exploration'] / 2)
                this.impact_report_data[i].filled_star = x
                if(x < 5 && data['result']['self_exploration'] % 2 != 0) {
                  this.impact_report_data[i].half_star = 1
                } else {
                  this.impact_report_data[i].empty_star = 5 - x
                }
            } else if(i == 1) {
                this.impact_report_data[i].score = data['result']['career_exploration']
                let x = data['result']['career_exploration'] / 2
                this.impact_report_data[i].filled_star = x
                if(x < 5 && data['result']['career_exploration'] % 2 != 0) {
                  this.impact_report_data[i].half_star = 1
                } else {
                  this.impact_report_data[i].empty_star = 5 - x
                }
            } else if(i == 2) {
                this.impact_report_data[i].score = data['result']['career_planning']
                let x = data['result']['career_planning'] / 2
                this.impact_report_data[i].filled_star = x
                if(x < 5 && data['result']['career_planning'] % 2 != 0) {
                  this.impact_report_data[i].half_star = 1
                } else {
                  this.impact_report_data[i].empty_star = 5 - x
                }
            } else if(i == 3) {
                this.impact_report_data[i].score = data['result']['career_planning']
                let x = data['result']['career_planning'] / 2
                this.impact_report_data[i].filled_star = x
                if(x < 5 && data['result']['career_plannin'] % 2 != 0) {
                  this.impact_report_data[i].half_star = 1
                } else {
                  this.impact_report_data[i].empty_star = 5 - x
                }
            }
          }

        } else {
          this.location.back()
        }
      },
      (err) => {
        this.mixpanelService.error('assessment-error', {
          'message': err
        })
      }
    );
  }

  public openSidebar () {
    document.getElementById("sidenav").style.width = "50%";
  }

  goBack() {
    this.location.back()
  }

  createRange(number){
    var items: number[] = [];
    for(var i = 1; i <= number; i++){
       items.push(i);
    }
    return items;
  }

  ngOnInit() {
    this.mixpanelService.pageTrack('Loaded impact report page')
  }
}

interface impactReport {
  title: string;
  description: string;
  score: number;
  image_url: string;
  empty_star: number;
  filled_star: number;
  half_star: number
}

interface impactReportPoints {
  self_exploration: string;
  career_exploration: string;
  career_planning: string;
  skill_enhancement: string;
}
