import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminEventsComponent } from './admin-events/admin-events.component';
import { ApiService } from '../api.service';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AdminMentorsComponent } from './admin-mentors/admin-mentors.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { AdminHeaderComponent } from './shared/admin-header/admin-header.component';
import { AuthService } from '../services/auth.service';
import { AdminClientsComponent } from './admin-clients/admin-clients.component';
import { AdminUsersComponent } from './admin-users/admin-users.component';
import { AdminCoursesComponent } from './admin-courses/admin-courses.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { AdminUserManagementComponent } from './admin-user-management/admin-user-management.component';

@NgModule({
  declarations: [
    AdminLoginComponent,
    AdminEventsComponent,
    AdminDashboardComponent,
    AdminMentorsComponent,
    SidebarComponent,
    AdminHeaderComponent,
    AdminClientsComponent,
    AdminUsersComponent,
    AdminCoursesComponent,
    AdminUserManagementComponent
  ],
  imports: [
    AdminRoutingModule,
    CommonModule,
    ReactiveFormsModule,
    AngularMultiSelectModule,
    FormsModule
  ],
  providers: [ApiService, AuthService]
})
export class AdminModule { }
