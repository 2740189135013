import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../api.service';
import { Location } from '@angular/common'
import { MixpanelService } from '../../mixpanel.service';
import { ToastMessageService } from 'src/app/services/toast-message.service';

@Component({
  selector: 'app-personality-test',
  templateUrl: './personality-test.component.html',
  styleUrls: ['./personality-test.component.css']
})
export class PersonalityTestComponent implements OnInit {

  currentOrder = [];
  public questionNumber: number = 0;

  public all_data;
  public all_questions;
  public currentQuestion;
  public nextButtonDisplay: boolean = true;
  public previousButtonDisplay: boolean = false;
  public displayError: boolean = false;
  public response_body = [];
  public hash_id: string = '';
  public nextButtonText = 'Next';
  public solvedQuestions = [];
  public isLoading: boolean = false;

  careerArrayItem;

  constructor(
    private apiService: ApiService, 
    private router: Router, 
    private location: Location, 
    private mixpanelService: MixpanelService,
    private toastMessageService: ToastMessageService
  ) {
  }

  goBack() {
    this.location.back()
  }

  createTest() {
    this.isLoading = true;
    this.apiService.post('create_test', '').subscribe(
      data => {
        localStorage.setItem('hash_id', data['hash_key']);
        if (data.hash_key) {
          this.mixpanelService.event('personality-test-started');
          this.all_questions = data.question;
          this.currentQuestion = this.all_questions[this.questionNumber];
          this.hash_id = localStorage.getItem('hash_id');
        }
      },
      err => {
        this.mixpanelService.error('create-test-error', {
          message: err.error,
        });
      },
      () => this.isLoading = false
    );
  }

  ngOnInit() {
    this.createTest();
    this.mixpanelService.pageTrack('Loaded personality test page');
  }

  toggleClick(career) {
    career.selected = !career.selected;
    if (career.selected) {
      if (this.currentOrder.length < 4) {
        this.currentOrder.push(career);
        career.number = this.currentOrder.length
        this.response_body.push(career.career_id)
      }
    } else {
      this.currentOrder = this.currentOrder.filter((item) => item != career);
      this.response_body = this.response_body.filter((item) => item != career.career_id)
      for(let i = 0; i < this.currentOrder.length; i++) {
        for(let j = 0; j < this.currentQuestion.length; j++) {
          if(this.currentOrder[i]['career_id'] == this.currentQuestion[j]['career_id']) {
            this.currentQuestion[j]['number'] = i + 1
            break;
          }
        }
      }
      career.number = null
    }
  }

  fetchNextQuestion() {

    if (this.response_body.length !== 4) {
      this.toastMessageService.showInfo("Please select 4 choices");
    } else {
      this.displayNextQuestion();
    }
  }


  public displayNextQuestion() {
    this.previousButtonDisplay = true;

    if (this.questionNumber == 34) {
      this.response_body[4] = true;
      this.apiService
        .post(
          `test/${this.hash_id}/response/${this.questionNumber}`,
          this.response_body
        )
        .subscribe(
          (data) => {
            this.mixpanelService.event('personality-test-completed')
            let user_name = localStorage.getItem('user_name');
            user_name = user_name ? user_name : '';
            // this.validateUser(user_name);
            localStorage.setItem('personality_test_status', 'true')
            this.router.navigate(['/personality-report'])
          },
          (err) => {
            this.mixpanelService.error('test-response-error', {
              'message': err
            })
          }
        );
      return;
    }


    this.apiService
      .post(
        `test/${this.hash_id}/response/${this.questionNumber}`,
        this.response_body
      )
      .subscribe(
        (data) => {},
        (err) => {
          this.mixpanelService.error('test-response-error', {
            'message': err
          })
        }
      );
    let counter = 0;
    if (this.currentOrder.length == 4) {
      this.solvedQuestions[this.questionNumber] = this.currentOrder;
    }
    this.questionNumber += 1;
    if (this.questionNumber == 34) {
      this.nextButtonText = 'Submit';
    }
    this.checkSolvedQuestions(counter);
    this.response_body = [];
  }

  private validateUser(user_name) {
    if (typeof user_name !== undefined && user_name != '') {
      if (user_name.includes('dummyuser')) {
        localStorage.setItem('is_logged_in', 'false');

      } else if (
        localStorage.getItem('auth_token') &&
        !user_name.includes('dummyuser')
      ) {
        localStorage.setItem('is_logged_in', 'true');

      }
    } else {
      localStorage.setItem('is_logged_in', 'false');
      localStorage.setItem('auth_token', '');
    }
  }

  private checkSolvedQuestions(counter) {
    if (this.solvedQuestions[this.questionNumber]) {
      this.currentQuestion = this.all_questions[this.questionNumber]
      this.currentOrder = []
      for(let i = 0; i < this.currentQuestion.length; i++) {
        this.currentQuestion[i]['selected'] = false
      }
    } else {
      this.currentQuestion = this.all_questions[this.questionNumber];
      this.currentOrder = []
      return;
    }
  }


  public hideError() {
    this.displayError = false;
  }

  fetchPreviousQuestion() {
    this.response_body = []
    for(let i = 0; i < this.currentQuestion.length; i++) {
      this.currentQuestion[i]['selected'] = false
      this.currentQuestion[i]['number'] = ""
    }
    this.questionNumber -= 1;
    this.currentQuestion = this.all_questions[this.questionNumber];
    this.currentOrder = this.solvedQuestions[this.questionNumber];

    for(let i = 0; i < this.currentOrder.length; i++) {
      this.response_body.push(this.currentOrder[i]['career_id'])
    }
  }


}
