<app-verify-email></app-verify-email>
<div class="main">
  <div class="sidebar-container">
    <app-sidebar></app-sidebar>
  </div>
  <app-mobile-sidebar></app-mobile-sidebar>

  <div class="main-content">
    <!-- Profile section -->
    <app-header class="header" title="Personality Report"></app-header>

    <div class="event-container-mobile">
      <h2 class="current-page-name">Explore Courses</h2>
      <i class="fas fa-bars" (click)="openSidebar()"></i>
    </div>
    <!-- Course section -->
    <div class="course-main">
      <div class="course-section">
        <div class="course-container">

          <h4 class="rubik-bold text-center">Your top four Professions are</h4>

          <div class="container pt-2">
            <div class="my-3" *ngFor="let career of career_recommendation; let i = index">
              <div class="row my-3 d-flex align-items-center" *ngIf="i % 2 == 0">
                <div class="col-md-8 col-8">
                  <h5 class="rubik-medium pb-primary-color">{{ career?.category }}</h5>
                  <p class="roboto pb-grey-light-color">
                    {{ career?.category_description }}
                  </p>
                </div>
                <div class="col-md-4 col-4">
                  <img class="img-fluid" [src]="career?.image_url" [alt]="career?.category">
                </div>
              </div>

              <div class="row my-3 d-flex align-items-center" *ngIf="i % 2 != 0">
                <div class="col-md-4 col-4">
                  <img class="img-fluid" [src]="career?.image_url" [alt]="career?.category">
                </div>
                <div class="col-md-8 col-8 text-end d-flex flex-column">
                  <h5 class="rubik-medium pb-primary-color">{{ career?.category }}</h5>
                  <p class="roboto pb-grey-light-color">
                    {{ career?.category_description }}
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="other-prompts">
        <div class="programs-container">
          <div class="trending-programs">
            <h4 class="text-center rubik-bold">
              Your Personality Traits are
            </h4>

            <div class="container pt-2">
              <div class="my-3" *ngFor="let trait of personality_traits; let i = index">
                <div class="row my-3 d-flex align-items-center" *ngIf="i % 2 == 0">
                  <div class="col-md-8 col-8">
                    <h5 class="rubik-medium pb-primary-color">{{ trait?.trait_name }}</h5>
                    <p class="roboto pb-grey-light-color">
                      {{ trait?.trait_description }}
                    </p>
                  </div>
                  <div class="col-md-4 col-4">
                    <img class="img-fluid" [src]="trait?.trait_image" [alt]="trait?.trait_name">
                  </div>
                </div>
  
                <div class="row my-3 d-flex align-items-center" *ngIf="i % 2 != 0">
                  <div class="col-md-4 col-4">
                    <img class="img-fluid" [src]="trait?.trait_image" [alt]="trait?.trait_name">
                  </div>
                  <div class="col-md-8 col-8 text-end d-flex flex-column">
                    <h5 class="rubik-medium pb-primary-color">{{ trait?.trait_name }}</h5>
                    <p class="roboto pb-grey-light-color">
                      {{ trait?.trait_description }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="loading-div" *ngIf="isLoading">
  <img class="loading-mode-width loading-spinner" src="/assets/images/loader.gif" alt="">
</div>