import { Component, OnInit } from '@angular/core';
import { Constants } from '../../class/constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../../class/custom-validators'; 
import { ToastMessageService } from 'src/app/services/toast-message.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService  } from "../../services/api.service";


@Component({
  selector: 'app-admin-courses',
  templateUrl: './admin-courses.component.html',
  styleUrls: ['./admin-courses.component.css']
})
export class AdminCoursesComponent implements OnInit {

  public isEdit: boolean = false;
  public isView: boolean = false;

  public courseName: string = '';
  public courseImage: string = '';
  public courseDescription: string = '';

  public modalRef: any;
  public modalConfigLg = Constants.lightGreyBackdropModalLg;

  public courseForm: FormGroup;

  public courses: any = [];

  public isLoading: boolean = false;
  public dropdownList = [];
  public dropdownSettings = Constants.singleSelectDropdownSettings;
  public currentCourseId: any;


  get f() { return this.courseForm.controls }


  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private toastMessageService: ToastMessageService,
    private apiService: ApiService
  ) {
    this.dropdownList = [
      { id: 1, name: 'Free' },
      { id: 2, name: 'Paid' }
    ]
  }

  ngOnInit(): void {
    this.createCourseForm()
    this.getAllCourses()
  }

  createCourseForm() {
    this.courseForm = this.fb.group({
      courseName: ['', [Validators.required]],
      courseDescription: ['', Validators.required],
      courseImage: ['', [Validators.required, Validators.pattern(CustomValidators.websiteUrl)]],
      numOfLessons: ['', [Validators.required, Validators.pattern(CustomValidators.onlyNumber)]],
      totalHours: ['', [Validators.required, Validators.pattern(CustomValidators.onlyFloatingNumbers)]],
      amount: ['', [Validators.required, Validators.pattern(CustomValidators.onlyNumber)]],
      freeOrPaid: ['', Validators.required],
      courseOrder: ['', [Validators.required, Validators.pattern(CustomValidators.onlyNumber)]],
    });
  }

  getAllCourses() {
    this.isLoading = true;
    this.apiService.get('get-all-courses').subscribe(
      data => {
        this.courses = data['course_data'];
      },
      err => {
        this.toastMessageService.showError(err.error.detail);
      },
      () => this.isLoading = false
    )
  }

  openModal(modalRef: any, data?: any, isView?: any, isEdit?: any) {
    this.resetFormData();
    if (isView) {
      this.isView = true;
      this.patchValue(data);
      this.courseImage = data.course_image
    }
    if (isEdit) {
      this.isEdit = true;
      this.patchValue(data);
    }
    this.modalRef = this.modalService.open(modalRef, this.modalConfigLg);
  }

  patchValue(data) {
    if(data.course_id) {
      this.currentCourseId = data.course_id
    }
    this.courseForm.patchValue({
      courseName: data.course_name,
      courseDescription: data.course_description,
      courseImage: data.course_image,
      numOfLessons: data.num_of_lessons,
      totalHours: data.total_hours,
      amount: data.amount || '-',
      // freeOrPaid: data.free,
      // courseOrder: data.
    })

  }

  openDeleteModal(modalRef: any, data?: any) {
    this.courseName = data.course_name;
    this.courseImage = data.course_image;
    this.courseDescription = data.course_description
    this.modalRef = this.modalService.open(modalRef, this.modalConfigLg);
  }


  resetFormData() {
    this.courseForm.reset()
    this.isEdit = false;
    this.isView = false;
  }

  closeModal() {
    if (this.modalRef) {
      this.isEdit = false;
      this.isView = false;
      this.modalRef.dismiss();
    }
  }


}
