<app-verify-email></app-verify-email>
<div class="main">
  <div class="sidebar-container">
    <app-sidebar></app-sidebar>
  </div>

  <div class="main-content">

    <!-- Profile section -->
    <app-header class="header" title="Personality Test"></app-header>

    <div class="event-container-mobile">
      <h2 class="current-page-name">Personality Test</h2>
    </div>

    <!-- Profile section -->
    <div class="profile-main">
      <div class="profile-info-container">
        <img class="arrow-image" src="../../assets/images/left-arrow.svg" alt="arrow back" (click)="goBack()">

       <div class="mt-5 pt-3 container">
        <div class="pb-grey-light-bg pt-4 pb-2 border-radius-large text-center position-relative mb-3 px-2">
            <span class="pb-primary-color-bg text-white py-2 px-3 border-radius-medium hcl-pros-cons-text roboto">Ques
                {{ questionNumber + 1 }}</span>
            <h6 class="rubik pb-grey-light-color">Rank these career options from 1 to 4 based on your preference.</h6>
        </div>
       </div>



       <div class="row my-4 mx-3">
        <div class="col-12 my-2 col-md-6" *ngFor="let item of currentQuestion" (click)="toggleClick(item)">
            <div class="row d-flex align-items-center cursor-pointer">
                <div class="col-4">
                    <img class="img-fluid" [src]="item?.image_url" [alt]="item?.career_name">
                </div>
                <div class="col-8 border-radius-medium text-justify p-2 position-relative"
                    [ngClass]="item?.selected ? 'selected' : ''">
                    <h6 class="rubik-bold mb-0">{{ item?.career_name }}</h6>
                    <p class="roboto pb-grey-light-color mb-0"><small>{{ item?.career_description }}</small></p>
                    <span *ngIf="item?.selected"
                        class="question-order-badge badge rounded-pill pb-primary-color bg-white shadow-lg">
                        {{ item?.number }}
                    </span>
                </div>
            </div>
        </div>
      </div>

        <div class="arrow-container">
          <i class="fas fa-arrow-left" *ngIf="questionNumber != 0" (click)="fetchPreviousQuestion()"></i>
          <i class="fas fa-arrow-right" *ngIf="questionNumber != 34" (click)="fetchNextQuestion()"></i>
          <button *ngIf="questionNumber == 34" class="submit-button" (click)="fetchNextQuestion()">Submit</button>
        </div>

      </div>
    </div>
  </div>

</div>

<div class="loading-div" *ngIf="isLoading">
  <img class="loading-mode-width loading-spinner" src="/assets/images/loader.gif" alt="">
</div>



