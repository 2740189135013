import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from '../../class/constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../../class/custom-validators';
import { ToastMessageService } from 'src/app/services/toast-message.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ApiService } from "../../services/api.service";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-admin-clients',
  templateUrl: './admin-clients.component.html',
  styleUrls: ['./admin-clients.component.css']
})
export class AdminClientsComponent implements OnInit {

  public isEdit: boolean = false;
  public isView: boolean = false;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};

  public modalRef: any;
  public modalConfigLg = Constants.lightGreyBackdropModalLg;

  public clientForm: FormGroup;

  public clients: any = [];
  public clientName: string = '';
  public currentClientId: number;

  public isLoading: boolean = false;

  public addNewClientSubscription: Subscription;


  get f() { return this.clientForm.controls }

  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private toastMessageService: ToastMessageService,
    private loaderService: LoaderService,
    private apiService: ApiService
  ) { }

  openModal(modalRef: any, data?: any, isView?: any, isEdit?: any) {
    this.resetFormData();
    if (isView) {
      this.isView = true;
      this.patchValue(data)
    }
    if (isEdit) {
      this.isEdit = true;
      this.patchValue(data)
    }
   
    this.modalRef = this.modalService.open(modalRef, this.modalConfigLg);
  }

  patchValue(data) {
    if(data.id) {
      this.currentClientId = data.id
    }
    this.clientForm.patchValue({
      name: data.client_name,
      clientCode: data.client_code,
      primaryImageUrl: data.primary_client_logo,
      primaryWebUrl: data.primary_web_url,
      secondaryImageUrl: data.secondary_client_logo,
      secondaryWebUrl: data.secondary_web_url || '-',
      clientContactEmail: data.client_contact_email,
      clientWebUrl: data.client_web_url
    })
  }

  createClientForm() {
    this.clientForm = this.fb.group({
      name: ['', [Validators.required]],
      clientWebUrl: ['', [Validators.required, Validators.pattern(CustomValidators.onlyChars)]],
      primaryImageUrl: ['', [Validators.required, Validators.pattern(CustomValidators.websiteUrl)]],
      primaryWebUrl: ['', [Validators.required, Validators.pattern(CustomValidators.websiteUrl)]],
      secondaryImageUrl: ['', Validators.pattern(CustomValidators.websiteUrl)],
      secondaryWebUrl: ['', Validators.pattern(CustomValidators.websiteUrl)],
      clientCode: '',
      clientContactEmail: ['', [Validators.required, Validators.pattern(CustomValidators.email)]]
    });
  }

  onSubmitForm() {
    this.isLoading = true;
    if (this.clientForm.valid) {
      const values = this.clientForm.getRawValue();
  
      let clientData = {
        client_name: values.name,
        primary_client_logo: values.primaryImageUrl,
        secondary_client_logo: values.secondaryImageUrl,
        primary_web_url: values.primaryWebUrl,
        secondary_web_url: values.secondaryWebUrl,
        client_web_url: values.clientWebUrl,
        client_contact_email: values.clientContactEmail
      }
      this.addNewClient(clientData);
    } else {
      this.isLoading = false;
      this.toastMessageService.showError('Form is not valid.');
    }
  }

  addNewClient(clientData) {
    this.addNewClientSubscription = this.apiService.post('admin/client', clientData).subscribe(
      data => {
        if(data.status) {
          this.toastMessageService.showSuccess(data.message);
          this.modalRef.dismiss();
        } else {
          this.toastMessageService.showError(data.message);
        }
        this.getClients();
      },
      err => {
        this.toastMessageService.showError(err.error.message);
      },
      () => this.isLoading = false
    )
  }

  resetFormData() {
    this.clientForm.reset()
    this.isEdit = false;
    this.isView = false;
    this.currentClientId = null;
  }

  closeModal() {
    if (this.modalRef) {
      this.modalRef.dismiss();
    }
  }

  getClients() {
    this.isLoading = true;
    this.loaderService.show();
    this.apiService.get('admin/client').subscribe(
      data => {
        this.clients = data.data
      }, 
      err => {
        this.toastMessageService.showError(err)
      },
      () => this.isLoading = false
    )
  }

  openDeleteModal(modalRef: any, data?:any) {
    this.clientName = data.client_name;
    this.currentClientId = data.id
    this.modalRef = this.modalService.open(modalRef, this.modalConfigLg);
  }

  onDelete() {
    this.isLoading = true;
    this.apiService.delete(`admin/client/${this.currentClientId}`).subscribe(
      data => {
        this.toastMessageService.showSuccess("Client deleted successfully");
        this.getClients();
        this.modalRef.dismiss();
      },
      err => {
        this.toastMessageService.showError(err)
      },
      () => this.isLoading = false
    )
  }

  onUpdateClient() {
    this.isLoading = true;
    const values = this.clientForm.getRawValue();
  
    let clientData = {
      client_name: values.name,
      primary_client_logo: values.primaryImageUrl,
      secondary_client_logo: values.secondaryImageUrl,
      primary_web_url: values.primaryWebUrl,
      secondary_web_url: values.secondaryWebUrl,
      client_web_url: values.clientWebUrl,
      client_contact_email: values.clientContactEmail
    }
    this.updateClient(clientData);
  }

  updateClient(clientData) {
    this.apiService.put(`admin/client/${this.currentClientId}`, clientData).subscribe(
      data => {
        this.toastMessageService.showSuccess("Client updated successfully");
        this.modalRef.dismiss();
        this.getClients();
      },
      err => {
        this.toastMessageService.showError(err)
      },
      () => this.isLoading = false
    )
  }

  ngOnInit(): void {
    this.createClientForm();
    this.getClients();
  }

}
