import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from '../../class/constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../../class/custom-validators'; 
import { ToastMessageService } from 'src/app/services/toast-message.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ApiService  } from "../../services/api.service";

@Component({
  selector: 'app-admin-events',
  templateUrl: './admin-events.component.html',
  styleUrls: ['./admin-events.component.css']
})
export class AdminEventsComponent implements OnInit {

  public isEdit: boolean = false;
  public isView: boolean = false;

  public eventName: string = '';
  public eventImage: string = '';

  public modalRef: any;
  public modalConfigLg = Constants.lightGreyBackdropModalLg;

  public eventForm: FormGroup;

  public events: any = [];

  public isLoading: boolean = false;


  get f() { return this.eventForm.controls }



  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private toastMessageService: ToastMessageService,
    private loaderService: LoaderService,
    private apiService: ApiService
  ) { }

  openModal(modalRef: any, data?: any, isView?:any, isEdit?: any) {
    this.resetFormData();
    if(isView) {
      this.isView = true;
      this.eventImage = data.event_image
      this.eventForm.patchValue({
        eventName: data.event_name,
        eventUrl: data.event_url,
        eventImageUrl: data.event_image,
        eventDescription: data.description
      })
    }
    if(isEdit) {
      this.isEdit = true;
      this.eventForm.patchValue({
        eventName: data.event_name,
        eventUrl: data.event_url,
        eventImageUrl: data.event_image,
        eventDescription: data.description
      })
    }
    this.modalRef = this.modalService.open(modalRef, this.modalConfigLg);
  }

  openDeleteModal(modalRef: any, data?:any) {
    this.eventName = data.event_name
    this.modalRef = this.modalService.open(modalRef, this.modalConfigLg);
  }


  resetFormData() {
    this.eventForm.reset()
    this.isEdit = false;
    this.isView = false;
  }

  closeModal() {
    if (this.modalRef) {
      this.isEdit = false;
      this.isView = false;
      this.modalRef.dismiss();
    }
  }

  createEventForm() {
    this.eventForm = this.fb.group({
      eventName: ['', [Validators.required]],
      eventUrl: ['', [Validators.required, Validators.pattern(CustomValidators.websiteUrl)]],
      eventImageUrl: ['', [Validators.required, Validators.pattern(CustomValidators.websiteUrl)]],
      eventDescription: ['', Validators.required]
    });
  }

  onSubmitForm() {
    if (this.eventForm.valid) {
      const values = this.eventForm.getRawValue();
      let loginData: any = {
        email: values.email || '',
        password: values.password || '',
      };
      this.toastMessageService.showSuccess('Form is valid')
      this.modalRef.dismiss();
    } else {
      const values = this.eventForm.getRawValue();
      this.toastMessageService.showError('Form is not valid.');
    }
  }

  onDelete() {
    this.toastMessageService.showSuccess("Event deleted successfully");
    this.modalRef.dismiss();
  }

  getEvents() {
    this.isLoading = true;
    this.apiService.get('get-event').subscribe(
      data => {
        this.events = data['event-response'];
      }, err => {
        this.toastMessageService.showError(err.error.detail);
      },
      () => this.isLoading = false
    )
  }



  ngOnInit() {
    this.createEventForm()
    this.getEvents()
  }
}