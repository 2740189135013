import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/api.service';
import { Constants } from 'src/app/class/constants';
import { MixpanelService } from 'src/app/mixpanel.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {

  public events = []
  public modalRef: any;
  public modalConfigMd = Constants.lightGreyBackdropModalMd;

  public isLoading: boolean = false;
  public currentEventData: any;

  constructor(
    private apiService: ApiService,
    private el: ElementRef,
    private router: Router,
    private mixpanelService: MixpanelService,
    private modalService: NgbModal
  ) {

  }


  public getEventTime(time) {
    let hour = time.split(":")[0]
    if (hour > 12) {
      return hour - 12 + " PM"
    }
    if (hour == 12) {
      return hour + " PM"
    }
    if (hour < 12) {
      return hour + " AM"
    }
  }

  public openEvent() {
    this.isLoading = true;
    const url = this.currentEventData?.event_url;
    window.open(url, "_blank");
    this.isLoading = false;
  }


  public openSidebar () {
    document.getElementById("sidenav").style.width = "50%";
  }

  openModal(modalRef: any, data: any) {
    this.isLoading = true;
    this.resetData();
    this.currentEventData = data;
    this.modalRef = this.modalService.open(modalRef, this.modalConfigMd);
    this.isLoading = false;
  }

  resetData() {
    this.currentEventData = [];
  }

  closeModal() {
    if (this.modalRef) {
      this.modalRef.dismiss();
    }
  }




  ngOnInit() {
    this.mixpanelService.pageTrack('Loaded events page')

    this.getAllEvents();

  }

  getAllEvents() {
    this.isLoading = true;
    this.apiService.get("get-event").subscribe(
      data => {
        if (data.status) {
          this.events = data['event-response']
          for (let i = 0; i < this.events.length; i++) {
            if (this.events[i]['description'].length > 160) {
              this.events[i]['short_description'] = this.events[i]['description'].substring(0, 160) + "...";
            } else {
              this.events[i]['short_description'] = this.events[i]['description']
            }
            if (this.events[i]['event_name'].length > 43) {
              this.events[i]['short_event_name'] = this.events[i]['event_name'].substring(0, 43) + "...";
            } else {
              this.events[i]['short_event_name'] = this.events[i]['event_name']
            }
          }
        }
      }, 
      err => {
        this.mixpanelService.error('get-event-error', {
          'message': err
        })
      },
      () => this.isLoading = false
    );
  }

}
