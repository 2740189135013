import { environment } from './../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../api.service';
import { SocialAuthService } from 'angularx-social-login';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
} from 'angularx-social-login';
import { SocialUser } from 'angularx-social-login';
import * as mixpanel from 'mixpanel-browser';
import { MixpanelService } from '../../mixpanel.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/class/custom-validators';
import { ToastMessageService } from 'src/app/services/toast-message.service';
import { DataService } from 'src/app/data.service';
import { AuthService } from 'src/app/services/auth.service';

mixpanel.init(environment.mixpanelProjectToken, {
  debug: true,
  ignore_dnt: true,
});

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {


  public userLoginForm: FormGroup;
  public isLoading: boolean = false;

  get f() { return this.userLoginForm.controls }


  constructor(
    private apiService: ApiService,
    private router: Router,
    private socialAuthService: SocialAuthService,
    private mixpanelService: MixpanelService,
    private fb: FormBuilder,
    private toastMessageService: ToastMessageService,
    private authService: AuthService
  ) { }



  ngOnInit(): void {
    this.createUserLoginForm();
  }

  submitLoginForm() {
    if (this.userLoginForm.valid) {
      this.isLoading = true;
      const values = this.userLoginForm.getRawValue()
      const data = {
        email: values.email || '',
        password: values.password || ''
      };
      this.loginWithEmail(data);
    } else {
      this.toastMessageService.showError("Form is not valid")
    }
  }

  loginWithEmail(data) {
    this.apiService.postUser('login/email', data).subscribe(
      data => {
        if (data.status) {
         this.authService.setCurrentUser(data, 'user').then(
           res => {
           this.router.navigate(['courses']);
         });
        } else {
          this.toastMessageService.showError(data.message)
        }
      },
      err => {
        this.toastMessageService.showError(err.error.detail);
      },
      () => this.isLoading = false
    )
  }


  createUserLoginForm() {
    this.userLoginForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(CustomValidators.email)]],
      password: ['', Validators.required]
    });
  }

  signInWithGoogle(): void {
    this.mixpanelService.event('Google login started');
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {
    this.mixpanelService.event('Facebook login started');
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  signOut(): void {
    this.socialAuthService.signOut();
  }

}
