<app-verify-email></app-verify-email>
<div class="main">
  <div class="sidebar-container">
    <app-sidebar active="courses"></app-sidebar>
  </div>

  <app-mobile-sidebar></app-mobile-sidebar>

  <div class="main-content">
    <!-- Profile section -->
    <app-header class="header" title="Explore Courses"></app-header>

    <div class="event-container-mobile">
      <h2 class="current-page-name">Explore Courses</h2>
      <i class="fas fa-bars" (click)="openSidebar()"></i>
    </div>

    <!-- Mentor section -->
    <div class="mentor-main">
      <div class="mentor-section">
        <div class="rating-container">
          <i class="fas fa-arrow-left" (click)="goBack()"></i>
          <div class="stars">
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
          </div>
        </div>
        <div class="video-info-container">
          <div class="course-heading-container">
            <div class="video-heading-container">
              <h2 class="video-heading">{{ currentCourseData && currentCourseData[0]?.course_name }}</h2>
            </div>
            <p class="video-description">
              {{ currentCourseData && currentCourseData[0]?.course_description }}
            </p>
            <div class="video-other-info">
              <div>
                <i class="fas fa-hourglass-half"></i>
                <span class="course-time"
                  >{{ currentCourseData && currentCourseData[0]?.total_hours }} Hrs</span
                >
              </div>
              <div>
                <i class="fas fa-layer-group"></i>
                <span class="course-lessons"
                  >{{ currentCourseData && currentCourseData[0]?.num_of_lessons }} Lessons</span
                >
              </div>
            </div>
          </div>
          <div
            style="padding: 56.25% 0 0 0; position: relative; margin-top: 2%"
            class="video-box-container"
          >
            <iframe
              [src]="videoUrl()"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 20px;
              "
              title="Automobile Technology | Video #1 | Introduction"
            ></iframe>
          </div>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </div>
      </div>

      <div class="other-prompts">
        <div class="programs-container">
          <div class="trending-programs">
            <h2>Course Videos</h2>
            <p class="course-time-lessons">
              {{ currentCourseData && currentCourseData[0]?.num_of_lessons }} Lessons ({{
                currentCourseData && currentCourseData[0]?.total_hours
              }}
              Hrs)
            </p>

            <div *ngFor="let video of video_info_free">
              <div
                class="video-item"
                (click)="playVideo(video)"
                [ngClass]="{
                  blue:
                    video?.selected == true &&
                    video?.vimeo_id == vimeo_id &&
                    video?.video_watched != true,
                  black:
                    video?.selected != true &&
                    video?.vimeo_id != vimeo_id &&
                    video?.video_watched != true,
                  success: video?.video_watched == true
                }"
              >
                <div class="video-title-container">
                  <i
                    class="fas"
                    [ngClass]="{
                      'fa-pause':
                        video?.selected == true &&
                        video?.vimeo_id == vimeo_id &&
                        video?.video_watched != true,
                      'fa-play':
                        video?.selected != true &&
                        video?.vimeo_id != vimeo_id &&
                        video?.video_watched != true,
                      'fa-check': video?.video_watched == true
                    }"
                  ></i>
                  <span class="video-title">{{ video?.video_title }}</span>
                </div>
                <span class="video-time">{{ video?.video_time }}</span>
              </div>

              <div
                class="video-item"
                (click)="openModal(assessmentModal, video)"
                *ngIf="
                  video?.video_question != '' && video?.video_question != null
                "
                [ngClass]="{
                  blue: video?.assessment_active == true,
                  black: video?.assessment_done != true,
                  success: video?.assessment_done == true
                }"
              >
                <div class="video-title-container">
                  <i
                    *ngIf="video?.assessment_done != true"
                    class="fas fa-list-alt"
                    [ngClass]="{
                      blue:
                        video?.assessment_active == true &&
                        video?.assessment_done != true,
                      black: video?.assessment_done != true,
                      success: video?.assessment_done == true
                    }"
                  ></i>

                  <i
                    class="fas fa-check"
                    *ngIf="video?.assessment_done == true"
                  ></i>

                  <span class="video-title">{{ video?.video_question }}</span>
                </div>
              </div>
            </div>

            <div *ngFor="let video of video_info_paid">
              <div class="video-item lock">
                <div class="video-title-container">
                  <i class="fas fa-lock"></i>
                  <span class="video-title">{{ video?.video_title }}</span>
                </div>
                <span class="video-time">{{ video?.video_time }}</span>
              </div>

              <div
                class="video-item lock"
                *ngIf="
                  video?.video_question != '' && video?.video_question != null
                "
              >
                <div class="video-title-container">
                  <i class="fas fa-lock"></i>
                  <span class="video-title">{{ video?.video_question }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<ng-template #assessmentModal let-modal>

  <div class="modal-header">
      <h4 class="modal-title">Self Exploration - Know yourself little better!</h4>
      <span class="btn btn-close btn-outline-danger" data-dismiss="modal" aria-label="Close" (click)="closeModal()"></span>

  </div>
  <div class="modal-body modal-body-custom-scroll">

    <div>
      <h5 class="pb-grey-light-color">{{ current_question }}</h5>

      <div class="modal-body" *ngIf="assessment_type == 'select one'">
        <p
          class="assessment-option"
          *ngFor="let assessment of assessment_option"
          (click)="selectOption($event, assessment)"
        >
          {{ assessment }}
        </p>
      </div>

      <div class="modal-body" *ngIf="assessment_type == 'select many'">
        <div class="select-many-container">
          <span
            class="assessment-option"
            style="margin-bottom: 1.5em; margin-right: 0.4em"
            *ngFor="let assessment of assessment_option"
            (click)="selectOption($event, assessment)"
            >{{ assessment }}</span
          >
        </div>
      </div>
  
      <div class="modal-body" *ngIf="assessment_type == 'input'">
        <textarea
          name="assessment-input"
          class="assessment-input"
          [(ngModel)]="assessment_input"
          (ngModelChange)="onInputChange()"
        ></textarea>
      </div>
  
  
    </div>

  </div>
  <div class="modal-footer">
      <button type="button" class="btn pb-primary-color-bg text-white w-100 border-radius-large roboto" (click)="submitAssessment()">Submit</button>
      <button type="button" class="btn btn-white w-100 my-2 roboto" data-bs-dismiss="modal" (click)="closeModal()">Cancel</button>
  </div>

</ng-template>





<div class="loading-div" *ngIf="isLoading">
  <img class="loading-mode-width loading-spinner" src="/assets/images/loader.gif" alt="">
</div>


