import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { Constants } from 'src/app/class/constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-personality-test-prompt',
  templateUrl: './personality-test-prompt.component.html',
  styleUrls: ['./personality-test-prompt.component.css'],
})
export class PersonalityTestPromptComponent implements OnInit {
  public modalRef: any;
  public modalConfigMd = Constants.lightGreyBackdropModalMd;

  public isLoading: boolean = false;
  public currentUser: any;
  public personalityTestStatus: boolean = false;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.getPersonalityTestStatus();
  }

  public startTest() {
    this.isLoading = true;
    this.router.navigate(['/personality-test']);
    this.closeModal();
    this.isLoading = false;
  }


  openModal(modalRef: any) {
    this.isLoading = true;
    this.modalRef = this.modalService.open(modalRef, this.modalConfigMd);
    this.isLoading = false;
  }

  closeModal() {
    if (this.modalRef) {
      this.modalRef.dismiss();
    }
  }

  viewReport() {
    this.router.navigate(['/personality-report']);
  }

  getPersonalityTestStatus() {
    this.currentUser = this.authService.getCurrentUser('user');
    this.personalityTestStatus = this.currentUser?.personality_test_status;
  }


 
}
