<div class="full-page pt-10-vh bg-primary-theme pb-5 px-3">
  <div class="container auth-main-content">
    <div class="row">
      <div class="col-md-7 pt-4 d-flex flex-column align-items-center logo-container pb-5">

        <img class="probano-logo img-fluid my-2 mb-5" src="../../assets/images/probano_dark_logo.svg" alt="ProBano logo" />
        <img class="img-fluid mt-5" src="../../assets/images/auth_illustration.svg" alt="Probano image" />

      </div>
      <div class="col-md-5 pt-4 pb-5 px-5">

        <div class="w-75 mt-5">
          <h2 class="mt-4 rubik pb-tertiary-color">
            Whoops!
          </h2>
  
          <h5>Page Not Found</h5>
  
  
          <p class="pb-grey-light-color my-5">
            We looked all over, but that page seems to have gotten away from us. Try one of these links to get back on track.
          </p>

          <button class="btn pb-primary-color-bg text-white roboto w-100 my-3" routerLink="/">
            Go To Home Page
          </button>

        </div>

      </div>
    </div>
  </div>
</div>


<div class="loading-div" *ngIf="isLoading">
  <img class="loading-mode-width loading-spinner" src="/assets/images/loader.gif" alt="">
</div>
