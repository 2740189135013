import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../api.service';
import { MixpanelService } from '../../mixpanel.service';
@Component({
  selector: 'app-personality-report',
  templateUrl: './personality-report.component.html',
  styleUrls: ['./personality-report.component.css']
})
export class PersonalityReportComponent implements OnInit {

  public courses;
  public career_recommendation;
  public personality_traits;
  public isLoading: boolean = false;

  constructor(private apiService: ApiService, private router: Router, private location: Location, private mixpanelService: MixpanelService) {
  
  }

  public goBack() {
    this.location.back()
  }

  public openSidebar () {
    document.getElementById("sidenav").style.width = "50%";
  }

  public closeSidebar() {
    document.getElementById("sidenav").style.width = "0%";
  }

  ngOnInit() {
    this.mixpanelService.pageTrack('Loaded personality report page');
    this.getResult();
  }

  getResult() {
    this.isLoading = true;
    this.apiService.get(`test/result`).subscribe(
      data => {
        this.career_recommendation = data['career_recommendation'];
        this.personality_traits = data['personality_traits'];
      }, err => {
        this.mixpanelService.error('test-result-error', {
          'message': err
        })
        this.isLoading = false
      },
      () => this.isLoading = false
    )
    
  }

}
