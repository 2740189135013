import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from '../../class/constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../../class/custom-validators'; 
import { ToastMessageService } from 'src/app/services/toast-message.service';
import { ApiService } from 'src/app/services/api.service';
import { CSVRecord } from './CSVModel';
import { each, filter } from 'underscore';
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-admin-user-management',
  templateUrl: './admin-user-management.component.html',
  styleUrls: ['./admin-user-management.component.css']
})
export class AdminUserManagementComponent implements OnInit {

  public isLoading: boolean = false;
  public activeTab: string = 'client_users';
  public clientId: number;
  public clientUsers: any = [];
  public isEdit: boolean = false;
  public isView: boolean = false;
  public modalRef: any;
  public modalConfigLg = Constants.lightGreyBackdropModalLg;
  public clientUserForm: FormGroup;

  public alreadyExistingEmails: any = [];
  public allUsers: any = [];

  public clientList: any = [];
  public selectedClient: any = [];
  public dropdownSettings = { 
    ...Constants.singleSelectDropdownSettings, 
    text: 'Select Client',
    labelKey: 'client_name'
  };

  public multipleClientUsers: any = [];
  @ViewChild('csvReader') csvReader: any;

  get fc() { return this.clientUserForm.controls };

  public totalTestCompletedClientUsers: number;
  public totalClientUsers: number;
  public totalUsers: number;
  public totalTestCompleted: number;

  constructor(
    private apiService: ApiService,
    private toastMessageService: ToastMessageService,
    private modalService: NgbModal,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.createClientUserForm();
    this.getAllClients();
  }

  onClientSelect(event) {
    this.clientId = event.id
    this.getClientUsers(this.clientId)
  }

  onClientDeSelect(event) {
    this.clientId = null;
    this.clientUsers = [];
    this.totalClientUsers = 0;
    this.totalTestCompletedClientUsers = 0;
  }

  getAllClients() {
    this.isLoading = true;
    this.apiService.get('get-all-clients').subscribe(
      data => {
        this.clientList = data.data;
      },
      err => {
        this.toastMessageService.showError(err.error.detail);
      },
      () => this.isLoading = false
    )
  }

  getDate() {
    let dateObj = new Date();
    let month = dateObj.getUTCMonth() + 1;
    let day = dateObj.getUTCDate();
    let year = dateObj.getUTCFullYear();
    let newdate = day + "/" + month + "/" + year;
    return newdate;
  }

  exportExcel() {
    let data = []
    let fileName = 'users';
    if(this.activeTab === 'client_users') {
      data = this.clientUsers;
      fileName = this.selectedClient[0].client_name;
    } else if(this.activeTab = 'users') {
      this.selectedClient = [];
      data = this.allUsers;
    }
    const replacer = (key, value) => value === null ? '' : value; 
    const header = Object.keys(data[0]);
    let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');
    var blob = new Blob([csvArray], {type: 'text/xlsx' });
    saveAs(blob, fileName + "-" + this.getDate() + ".xlsx");
  }


  uploadListener($event: any): void {

    let text = [];
    let files = $event.srcElement.files;

    if (this.isValidCSVFile(files[0])) {

      let input = $event.target;
      let reader = new FileReader();
      reader.readAsText(input.files[0]);

      reader.onload = () => {
        let csvData = reader.result;
        let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);

        let headersRow = this.getHeaderArray(csvRecordsArray);

        this.multipleClientUsers = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
      };

      reader.onerror = () => {
        this.toastMessageService.showError('Error is occured while reading file!')
      };

    } else {
      alert("Please import valid .csv or .xlsx file.");
      this.fileReset();
    }
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    let csvArr = [];

    for (let i = 1; i < csvRecordsArray.length; i++) {
      let curruntRecord = (<string>csvRecordsArray[i]).split(',');
      if (curruntRecord.length == headerLength) {
        let csvRecord: CSVRecord = new CSVRecord();
        csvRecord.name = curruntRecord[0].trim();
        csvRecord.email = curruntRecord[1].trim();
        csvRecord.class = curruntRecord[2].trim();
        csvRecord.school = curruntRecord[3].trim();
        csvRecord.state = curruntRecord[4].trim();
        csvRecord.mobile = curruntRecord[5].trim();
        csvArr.push(csvRecord);
      }
    }
    return csvArr;
  }

  isValidCSVFile(file: any) {
    return file.name.endsWith(".csv") || file.name.endsWith(".xlsx");
  }

  getHeaderArray(csvRecordsArr: any) {
    let headers = (<string>csvRecordsArr[0]).split(',');
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  fileReset() {
    this.csvReader.nativeElement.value = "";
    this.multipleClientUsers = [];
  }

  createClientUserForm() {
    this.clientUserForm = this.fb.group({
      name: ['', [Validators.required, Validators.pattern(CustomValidators.onlyChars)]],
      email: ['', [Validators.required, Validators.pattern(CustomValidators.email)]],
      mobile: ['', Validators.required],
      school: ['', [Validators.required, Validators.pattern(CustomValidators.onlyChars)]],
      class: ['', Validators.required],
      city: ['', [Validators.required, Validators.pattern(CustomValidators.onlyChars)]]
    });
  }

  getAllUsers() {
    this.isLoading = true;
    this.apiService.get('admin/get-all-users').subscribe(
      data => {
        this.allUsers = data.data;
        this.totalUsers = data.data.length;
        this.totalTestCompleted = filter(data.data, item => item.is_personality).length
      },
      err => {
        this.toastMessageService.showError(err);
      },
      () => this.isLoading = false
    )
  }

  onSubmitForm() {
    this.isLoading = true;
    if (this.clientUserForm.valid) {
      const values = this.clientUserForm.getRawValue();
      let data: any = {
        data: [
          {
            full_name: values.name || '',
            email: values.email || '',
            password: values.mobile || '',
            class_name: values.class || '',
            city: values.city || '',
            school_name: values.school || '',
            mobile: values.mobile || '',
            is_student: true || ''
          }
        ],
        total_count: 1,
        client_id: this.selectedClient[0].id
      };
      this.submitUserData(data)
    } else {
      this.isLoading = false;
      this.toastMessageService.showError('Form is not valid.');
    }
  }

  submitUserData(data) {
    this.apiService.post('admin/create-client-users', data).subscribe(
      data => {
        if(data.status) {
          this.toastMessageService.showSuccess(data.message);
          this.getClientUsers(this.clientId);
          this.alreadyExistingEmails = data.user_exists
          this.closeModal();
        } else {
          this.toastMessageService.showError(data.message);
        }
      },
      err => {
        this.toastMessageService.showError(err.error.detail);
      },
      () => this.isLoading = false
    )
  }

  onMultipleClientSubmitForm() {
    this.isLoading = true;
    let postData = []
    each(this.multipleClientUsers, item => {
      postData.push({
        full_name: item.name || '',
        email: item.email || '',
        password: item.mobile || '',
        class_name: item.class || '',
        school_name: item.school || '',
        mobile: item.mobile || '',
        city: item.state || '',
        is_student: true
      })
    })
    let data: any = {
      data: postData,
      total_count: this.multipleClientUsers.length,
      client_id: this.selectedClient[0].id
    };
    this.submitUserData(data);
  }

  getClientUsers(clientId) {
    this.isLoading = true;
    this.apiService.get(`admin/get-client-users/${clientId}`).subscribe(
      data => {
        this.clientUsers = data.data;
        this.totalClientUsers = data.data.length;
        this.totalTestCompletedClientUsers = filter(this.clientUsers, item => item.test_done).length;
      }, 
      err => {
        this.toastMessageService.showError(err.error.detail)
      },
      () => this.isLoading = false
    )
  }

  onSwitchingTab(tab) {
    this.activeTab = tab;
    if(this.activeTab === 'users') {
      this.getAllUsers();
    } else if(this.activeTab === 'client_users') {
      this.getClientUsers(this.clientId);
    }
  }

  openMultiple(modalRef: any, data?: any, isView?: any, isEdit?: any) {
    this.multipleClientUsers = [];
    if (isView) {
      this.isView = true;
      this.patchMultipleClientUserFormValue(data);
    }
    if (isEdit) {
      this.isEdit = true;
      this.patchMultipleClientUserFormValue(data);
    }
    this.modalRef = this.modalService.open(modalRef, this.modalConfigLg);
  }

  openModal(modalRef: any, data?: any, isView?: any, isEdit?: any) {
    this.resetClientUserFormData();
    if (isView) {
      this.isView = true;
      this.patchClientUserFormValue(data);
    }
    if (isEdit) {
      this.isEdit = true;
      this.patchClientUserFormValue(data);
    }
    this.modalRef = this.modalService.open(modalRef, this.modalConfigLg);
  }

  patchMultipleClientUserFormValue(data) {
  }

  patchClientUserFormValue(data) {
    if(data.career_guide_id) {
    }
    this.clientUserForm.patchValue({
      mentorName: data.name,
      mentorEmail: data.email,
      mentorImageUrl: data.img_url,
      mentorDescription: data.description,
      linkedinUrl: data.linkedin_url || '-',
      mentorTitle: data.title,
      isAvailable: data.isAvailable || true 
    })
  }

  resetClientUserFormData() {
    this.alreadyExistingEmails = [];
    this.clientUserForm.reset();
    this.isEdit = false;
    this.isView = false;
  }
  

  closeModal() {
    if (this.modalRef) {
      this.isEdit = false;
      this.isView = false;
      this.modalRef.dismiss();
      this.multipleClientUsers = [];
    }
  }

}
