<nav class="navbar navbar-expand-lg navbar-light" style="background-color: #e3f2fd;">
  <div class="container-fluid">
    <div class="d-flex align-items-center justify-content-between w-100" id="navbarSupportedContent">
      
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <h3 (click)="toggleSidebarFn()"><i class="fas fa-bars cursor-pointer pb-secondary-color"></i></h3>
      </ul>
      <div class="d-flex mx-3">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item custom-dropdown">
            <a class="nav-link pe-auto" (click)="toggleDropdown()">
              <img class="rounded-circle z-depth-2 width-40 height-40 user-select-none" [src]="user?.image" alt="admin">
            </a>
            <div *ngIf="showDropdown" class="custom-dropdown-content" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" style="border-top-left-radius: 20px;">{{ user?.email }}</a>
              <!-- <a class="dropdown-item">My Profile</a> -->
              <a class="dropdown-item" style="border-bottom-right-radius: 20px;" (click)="logout()">Logout</a>
            </div>
      
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>

<app-admin-sidebar [toggleSidebar]="toggleSidebar"></app-admin-sidebar>