import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { CustomValidators } from 'src/app/class/custom-validators';
import { AuthService } from 'src/app/services/auth.service';
import { ToastMessageService } from 'src/app/services/toast-message.service';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.css']
})
export class AdminHeaderComponent implements OnInit {

  public adminEmail: string = ''

  public showDropdown: boolean = false;
  public toggleSidebar: boolean = false;
  public user: any;

  constructor(
    private authService: AuthService,
    private el: ElementRef,
    private apiService: ApiService,
    private toastMessageService: ToastMessageService,
    private router: Router
  ) {
  }

  toggleSidebarFn() {
    this.toggleSidebar = !this.toggleSidebar
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown
  }

  ngOnInit(): void {
    this.user = this.authService.getCurrentUser('admin');
    if(this.user.image === '' || !this.user.image || !CustomValidators.websiteUrl.test(this.user.image)) {
      this.user.image = "https://profile-image-user.s3.ap-south-1.amazonaws.com/profile.png"
    }
  }

  logout() {
    this.apiService.get('logout').subscribe(
      data => {
        if(data.status) {
          this.authService.clearData();
          this.toastMessageService.showSuccess(data.message);
          this.router.navigate(['/']);
        }
      },
      err => {
        this.toastMessageService.showError(err.error.detail);
      }
    )
  }

}
