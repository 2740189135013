<div class="programs-container mt-3">
    <div class="trending-programs">
        <h2>Trending Programs</h2>
        <div class="program" (click)="openModal(programModal, 'explore')">
            <img class="program-image" src="../../assets/images/course.png" alt="" />
            <span class="time"><i class="fas fa-play"></i>&nbsp; 4 Hrs</span>
            <div class="program-info">
                <h3>Explore Program</h3>
                <p>Build your career awareness with us.</p>
            </div>
        </div>

        <div class="program" (click)="openModal(programModal, 'discover')">
            <img class="program-image" src="../../assets/images/course.png" alt="" />
            <span class="time"><i class="fas fa-play"></i>&nbsp; 6 Hrs</span>
            <div class="program-info">
                <h3>Discover Program</h3>
                <p>Make the right career choice with us & plan your path.</p>
            </div>
        </div>
    </div>
</div>



<ng-template #programModal let-modal>

    <div class="modal-header">
        <h4 class="modal-title rubik-bold">Trending Programs</h4>
        <span class="btn btn-close btn-outline-danger" data-dismiss="modal" aria-label="Close"
            (click)="closeModal()"></span>
    </div>
    <div class="modal-body modal-body-custom-scroll">

        <img class="img-fluid" [src]="currentProgram?.image" [alt]="currentProgram?.name">

        <div class="px-3 my-2">
            <div class="d-flex align-items-center justify-content-between">
                <h4 class="roboto-bold">{{ currentProgram?.name }}</h4>
                <h4 class="rubik-bold pb-secondary-color">₹ {{ currentProgram?.price }}</h4>
            </div>

            <div *ngFor="let item of currentProgram?.features">
                <i class="fas fa-star"></i>
                <span class="roboto pb-grey-light-color">{{ item }}</span>
            </div>
        </div>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn pb-primary-color-bg text-white w-100 border-radius-large roboto-bold"
            (click)="purchaseNow()">Purchase Now</button>
        <button type="button" class="btn btn-white w-100 my-2 roboto" data-bs-dismiss="modal"
            (click)="closeModal()">Cancel</button>
    </div>

</ng-template>

<div class="loading-div" *ngIf="isLoading">
    <img class="loading-mode-width loading-spinner" src="/assets/images/loader.gif" alt="">
</div>