<div class="py-5 h-100">
    <div class="hcl-report-bg-color">
        <div class="container py-3">
            <h3 class="py-4 rubik-bold">Career Psychometric Report</h3>
        </div>

        <div class="student-info-container">
            <app-student-info [data]="data"></app-student-info>
        </div>
    </div>

    <div class="container my-4">
        <div class="hcl-main-content col-md-8 col-8">
            <h4 class="hcl-report-text-color roboto-bold">Congratulations</h4>
            <p class="roboto pb-grey-light-color">
                on completing the ProBano Career Psychometric Test. This report will give an insight into your career interests and strengths.
            </p>
            <p class="roboto pb-grey-light-color">
                We have designed this report in a crisp yet comprehensive manner so that you can remember these factors while you are in this journey of selecting the right career option for yourself.
            </p>
            <p class="roboto pb-grey-light-color">
                To be precise, we have divided this report in two parts. In this report you will get:
            </p>
        </div>

        <div>
            <h4 class="rubik-medium">A. Suggested Career Fields:</h4>

            <div class="custom-progress-bar"></div>

            <p class="roboto mt-3 pb-grey-light-color">
                Career fields are a broad division of career options on the basis of industries,
                each career field has a variety of career paths available to choose from.
                This feature is designed to give you a broader perspective of possible career options for you.
            </p>

            <h4 class="rubik-medium">B. Your Personality Traits:</h4>
            <div class="custom-progress-bar"></div>
            <p class="roboto mt-3 pb-grey-light-color">
                Knowing your own personality traits is crucial for any big decision for our lives.It plays a significant role in the success or failure of any career pursuit.
            </p>
            <p class="roboto pb-grey-light-color">
                This feature is designed to give you an insight into your strengths which will help you in your career journey
            </p>
        </div>
    </div>
</div>
