export const CustomValidators = {
  email:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  phoneNumber: /^[0]?[6789]\d{9}$/,
  pincode: /^[1-9][0-9]{5}$/,
  password:
    /^(?![\s])(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w])([^\s]){8,}$/,
  onlyChars: /^[a-zA-Z ]*$/,
  userName: /^([a-zA-Z0-9]){4,}$/,
  websiteUrl:
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
  onlyNumber: /^[0-9]*$/,
  onlyFloatingNumbers: /^[+-]?\d+(\.\d+)?$/
};
