<div id="sidenav" class="sidenav">
  <a href="javascript:void(0)" class="closebtn" (click)="closeSidebar()"
    >&times;</a
  >
  <a routerLink="/my-profile" style="margin-top: 1.5em">My Profile</a>
  <a routerLink="/my-courses">My Courses</a>
  <a routerLink="/courses">Explore Courses</a>
  <a routerLink="/events">Events</a>
  <a routerLink="/mentors">Mentors</a>
  <!-- <a (click)="openScholarshipModal()">Scholarship</a> -->
  <a (click)="logout()">Logout</a>
</div>

