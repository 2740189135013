<app-verify-email></app-verify-email>
<div class="main">
  <div class="sidebar-container">
    <app-sidebar></app-sidebar>
  </div>

  <app-mobile-sidebar></app-mobile-sidebar>

  <div class="main-content">
    <!-- Profile section -->
    <app-header class="header" title="Impact Report"></app-header>
    <div class="event-container-mobile">
      <h2 class="current-page-name">Impact Report</h2>
      <i class="fas fa-bars" (click)="openSidebar()"></i>
    </div>
    <!-- Course section -->
    <div class="course-main">
      <div class="course-section">
        <div class="course-container">


          <div class="heading-container">
            <img class="back-arrow" src="../../assets/images/left-arrow.svg" alt="" (click)="goBack()">
            <h2 class="heading">Your Score</h2>
            <div></div>
          </div>

          <div class="profession-container">
            <div class="profession-card" *ngFor="let data of impact_report_data">
              <img [src]="data.image_url" [alt]="data.title" class="profession-image">
              <div class="profession-info-container">
                <h2 class="profession-name">{{ data.title }}</h2>
                <p class="profession-description">{{ data.description }}</p>
                <div>

                      <i *ngFor="let i of createRange(data.filled_star)" class="fas fa-star"></i>
                      <i *ngFor="let i of createRange(data.half_star)" class="fas fa-star-half"></i>
                      <i *ngFor="let i of createRange(data.empty_star)" class="fas fa-star empty"></i>

                </div>
              </div>
            </div>


          </div>


        </div>
      </div>


    </div>
  </div>
</div>






