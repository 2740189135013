
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminClientsComponent } from './admin-clients/admin-clients.component';
import { AdminCoursesComponent } from './admin-courses/admin-courses.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AdminEventsComponent } from './admin-events/admin-events.component';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { AdminMentorsComponent } from './admin-mentors/admin-mentors.component';
import { AdminUserManagementComponent } from './admin-user-management/admin-user-management.component';
import { AdminAuthGuard } from './auth.guard';


const routes: Routes = [
  {
    path: 'login',
    component: AdminLoginComponent,
  },
  {
    path: 'dashboard',
    component: AdminDashboardComponent,
    canActivate: [AdminAuthGuard]
  },
  {
    path: 'events',
    component: AdminEventsComponent,
    canActivate: [AdminAuthGuard]
  },
  {
    path: 'mentors',
    component: AdminMentorsComponent,
    canActivate: [AdminAuthGuard]
  },
  {
    path: 'clients',
    component: AdminClientsComponent,
    canActivate: [AdminAuthGuard]
  },
  {
    path: 'courses',
    component: AdminCoursesComponent,
    canActivate: [AdminAuthGuard]
  },
  {
    path: 'user-management',
    component: AdminUserManagementComponent,
    canActivate: [AdminAuthGuard]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }