import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MixpanelService } from '../../mixpanel.service';
import { ToastMessageService } from 'src/app/services/toast-message.service';
import { pluck, indexOf, filter } from 'underscore';
import { Constants } from 'src/app/class/constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-course-videos',
  templateUrl: './course-videos.component.html',
  styleUrls: ['./course-videos.component.css'],
})
export class CourseVideosComponent implements OnInit {
  
  public current_question;
  public assessment_type;
  public assessment_option;
  public selected_assessment;
  public assessment_done: boolean = false
  public assessment_input;
  public video_id;

  public user_answers = [];
  public video_info_free;
  public video_info_paid;
  public vimeo_id;
  public video_source;

  public isLoading: boolean = false;
  public currentCourseId;
  public currentCourseData: any;
  public enrolledCourseIds: any;
  public coursesEnrolled: any;
  public allCourses: any;

  public modalRef: any;
  public modalConfigMd = Constants.lightGreyBackdropModalMd;


  constructor(
    private apiService: ApiService,
    private _location: Location,
    private sanitizer: DomSanitizer,
    private mixpanelService: MixpanelService,
    private route: ActivatedRoute,
    private router: Router,
    private toastMessageService: ToastMessageService,
    private modalService: NgbModal
  ) {
  }

  openModal(modalRef: any, data: any) {
    this.resetData();
    for (let i = 0; i < this.video_info_free.length; i++) {
      if (this.video_id == this.video_info_free[i]['video_id']) {
        this.video_info_free[i]['assessment_active'] = true;
      }
    }
    this.video_id = data?.video_id;
    this.assessment_type = data?.video_question_type;
    this.assessment_option = data?.video_option.split('/ ');
    this.current_question = data?.video_question;
    this.assessment_done = data?.assessment_done

    this.modalRef = this.modalService.open(modalRef, this.modalConfigMd);
  }

  resetData() {
    this.assessment_input = ""
    this.user_answers = []
  }

  closeModal() {
    if (this.modalRef) {
      this.modalRef.dismiss();
    }
  }

  public selectOption(event, assessment) {
    const hasClass = event.target.classList.contains('active');
    if (hasClass) {
      event.target.classList.remove('active');
      this.user_answers = this.user_answers.filter(
        (item) => item != assessment
      );
    } else {
      this.user_answers.push(assessment);
      event.target.classList.add('active');
    }
    this.selected_assessment = assessment;
  }

  public submitAssessment() {
    if (this.user_answers.length > 0) {
      let bodyData = {
        response_body: this.user_answers,
      };
      let data = {
        course_id: this.currentCourseId,
        assessment_list: this.video_id,
      };
      this.updateCourseTrack(data);
      this.updateCourseResponse(bodyData);
    } else {
      this.toastMessageService.showInfo("Please select atleast one option.")
    }
  }

  updateCourseResponse(bodyData) {
    this.apiService
    .post(`course/response/${this.currentCourseId}/${this.video_id}`, bodyData)
    .subscribe(
      data => {
        this.mixpanelService.event('assessment-completed');
        this.resetData();
        this.closeModal();
      },
      err => {
        this.mixpanelService.error('course-response-error', {
          'message': err,
        })
      }
    );
  }

  updateCourseTrack(data) {
    this.apiService.post('course-track', data).subscribe(
      data => {
        for (let i = 0; i < this.video_info_free.length; i++) {
          if (this.video_id == this.video_info_free[i]['video_id']) {
            this.video_info_free[i]['assessment_done'] = true;
          }
        }
      },
      err => {
        this.mixpanelService.error('course-track-error', {
          'message': err
        })
      },
      () => this.isLoading = false
    );
  }

  public onInputChange() {
    if (this.assessment_input.includes(',')) {
      this.user_answers = this.assessment_input.split(', ');
    } else {
      this.user_answers = this.assessment_input.split(' ');
    }
  }

  public playVideo(video) {
    this.mixpanelService.event('playing-video', {
      'video_name': video.video_title,
      'video_id': video.video_id
    })
    for (let i = 0; i < this.video_info_free.length; i++) {
      if (this.video_id == this.video_info_free[i]['video_id']) {
        this.video_info_free[i]['video_watched'] = true;
      }
    }
    this.video_id = video.video_id;
    this.sendWatchedData();
    this.vimeo_id = video.vimeo_id;
    video['selected'] = true;
  }

  public sendWatchedData() {
    this.isLoading = true;
    this.apiService
      .post('course-track', {
        course_id: this.currentCourseId,
        video_list: this.video_id,
      })
      .subscribe(
        data => {},
        err => {
          this.mixpanelService.error('course-track-error', {
            'message': err
          })
        },
        () => this.isLoading = false
      );
  }

  public videoUrl() {
    let video_source =
      'https://player.vimeo.com/video/' +
      this.vimeo_id +
      '?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479';
    return this.sanitizer.bypassSecurityTrustResourceUrl(video_source);
  }

  ngOnInit() {
    this.mixpanelService.pageTrack('Loaded course videos page');
    this.route.queryParams.subscribe(
      data => {
        if(data.courseId) {
          this.currentCourseId = data.courseId;
          this.validateCourse();
        } else {
          this.router.navigate(['my-courses'])
        }
      }
    )
  }

  validateCourse() {
    this.isLoading = true;
    this.apiService.get('my-course').subscribe(
      data => {
        this.coursesEnrolled = data['course enroll'];
        this.enrolledCourseIds = pluck(this.coursesEnrolled, 'course_id');
        if(indexOf(this.enrolledCourseIds, +this.currentCourseId) === -1) {
          this.getFreeVideos();
        } else {
          this.currentCourseData = filter(this.coursesEnrolled, course => course?.course_id === +this.currentCourseId);
          this.getCurrentCourseData();
          this.getCourseTrack();
        }
      }, 
      err => {
        this.toastMessageService.showError(err.error.detail);
        this.mixpanelService.error('my-course-error', {
          'message': err
        })
      },
      () => this.isLoading = false
    )
  }

  getAllCourses() {
    this.isLoading = true;
    this.apiService.get('get-all-courses').subscribe(
      data => {
        this.allCourses = data['course_data'];
        this.currentCourseData = filter(this.allCourses, course => course?.course_id === +this.currentCourseId);
      },
      err => {
        this.mixpanelService.error('get-all-courses-error', {
          message: err.error,
        });
      },
      () => this.isLoading = false
    );
  }

  getFreeVideos() {
    this.isLoading = true;
    this.getAllCourses();
    this.apiService.get(`video-info/${this.currentCourseId}`).subscribe(
      (data) => {
        this.video_info_free = data['video_info']['free_video'];
        this.video_info_paid = data['video_info']['paid_video'];
        this.vimeo_id = data['video_info']['free_video'][0]['vimeo_id'];
        this.video_id = data['video_info']['free_video'][0]['video_id'];
        this.video_info_free[0]['selected'] = true;
        this.video_source =
          'https://player.vimeo.com/video/' +
          this.vimeo_id +
          '?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479';
      },
      err => {
        this.mixpanelService.error('video-info-error', {
          'message': err
        })
      },
      () => this.isLoading = false
    );

  }

  getCurrentCourseData() {
    this.apiService.get(`get-videos-list/${this.currentCourseId}`).subscribe(
      (data) => {
        this.video_info_free = data['video_list'];
        this.vimeo_id = data['video_list'][0]['vimeo_id'];
        this.video_id = data['video_list'][0]['video_id'];
        this.video_info_free[0]['selected'] = true;
        this.video_source =
          'https://player.vimeo.com/video/' +
          this.vimeo_id +
          '?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479';
      },
      (err) => {
        this.mixpanelService.error('get-video-list-error', {
          'message': err
        })
      }
    );

  }

  getCourseTrack() {
    this.isLoading = true;
    this.apiService.get(`get-course-track/${this.currentCourseId}`).subscribe(
      data => {
        let video_list = data['message']['video_list'].split(', ')
        let assessment_list = data['message']['assessment_list'].split(', ')
        for(let i = 0; i < this.video_info_free.length; i++) {
            for(let j = 0; j < video_list.length; j++) {
                if(this.video_info_free[i]['video_id'] == video_list[j]) {
                    this.video_info_free[i]['video_watched'] = true
                }
            }
            for(let j = 0; j < assessment_list.length; j++) {
              if(this.video_info_free[i]['video_id'] == assessment_list[j]) {
                  this.video_info_free[i]['assessment_done'] = true
              }
          }
        }
      }, 
      err => {
        this.mixpanelService.error('get-course-track-error', {
          'message': err
        })
      },
      () => this.isLoading = false
    )
  }

  public openSidebar() {
    document.getElementById('sidenav').style.width = '50%';
  }

  goBack() {
    this._location.back();
  }

  numSequence(n: number): Array<number> {
    return Array(n);
  }
}
