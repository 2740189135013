<app-admin-header></app-admin-header>

<div class="container py-4">
    <div>
        <h2>Mentors</h2>
    </div>

    <button type="button" class="btn btn-primary float-end m-3" (click)="openModal(mentorModal)">
        Add Mentor
    </button>

    <div class="">
        <table class="table table-striped table-secondary">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Mentor Name</th>
                    <th scope="col">Mentor Email</th>
                    <th scope="col">Mentor Title</th>
                    <th scope="col">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of mentors; let i = index">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>{{ item?.name }}</td>
                    <td>{{ item?.email }}</td>
                    <td>{{ item?.title }}</td>
                    <td>
                        <i class="fa fa-eye" data-bs-toggle="tooltip" data-bs-placement="top" title="View"
                            (click)="openModal(mentorModal, item, true)"></i>
                        <i class="fa fa-edit" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"
                            (click)="openModal(mentorModal, item, false, true)"></i>
                        <i class="fa fa-trash-o" aria-hidden="true" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Delete" (click)="openDeleteModal(deleteMentorModal, item)"></i>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<ng-template #mentorModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title" *ngIf="!isView">
            {{ isEdit ? "Edit" : "Add" }} Mentor
        </h5>
        <h5 class="modal-title" *ngIf="isView">View Mentor</h5>
        <span class="btn btn-close btn-outline-danger" data-dismiss="modal" aria-label="Close"
            (click)="closeModal()"></span>
    </div>
    <div class="modal-body">
        <img *ngIf="isView" [src]="mentorImage" class="img-fluid rounded mx-auto d-block" [alt]="mentorName" />

        <form [formGroup]="mentorForm">
            <div class="row clearfix px-3">
                <div class="col-md-12 col-lg-6">
                    <div class="form-group mt-3">
                        <label class="secondary-text-font-size primary-theme-color mb-1">Mentor Name</label>
                        <input type="text" name="mentorName" formControlName="mentorName" [readonly]="isView"
                            class="form-input form-control theme-form-control pt-2 placeholder-fix"
                            autocomplete="off" />
                        <div class="text-danger mt-1" *ngIf="f.mentorName.touched && f.mentorName?.errors?.required">
                            Mentor Name is Required
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-lg-6">
                    <div class="form-group mt-3">
                        <label class="secondary-text-font-size primary-theme-color mb-1">Mentor Email</label>
                        <input type="text" name="mentorEmail" formControlName="mentorEmail" [readonly]="isView"
                            class="form-input form-control theme-form-control pt-2 placeholder-fix"
                            autocomplete="off" />
                        <div class="text-danger mt-1" *ngIf="f.mentorEmail.touched && f.mentorEmail?.errors?.required">
                            Mentor Email is Required
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-lg-6">
                    <div class="form-group mt-3">
                        <label class="secondary-text-font-size primary-theme-color mb-1">Mentor Image Url</label>
                        <input type="text" name="mentorImageUrl" formControlName="mentorImageUrl" [readonly]="isView"
                            class="form-input form-control theme-form-control pt-2 placeholder-fix"
                            autocomplete="off" />
                        <div class="text-danger mt-1" *ngIf="f.mentorImageUrl.touched && f.mentorImageUrl?.errors?.required">
                            Mentor Image Url is Required
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-lg-6">
                    <div class="form-group mt-3">
                        <label class="secondary-text-font-size primary-theme-color mb-1">Mentor Linked In Url</label>
                        <input type="text" name="linkedinUrl" formControlName="linkedinUrl" [readonly]="isView"
                            class="form-input form-control theme-form-control pt-2 placeholder-fix"
                            autocomplete="off" />
                    </div>
                </div>

                <div class="col-md-12 col-lg-6">
                    <div class="form-group mt-3">
                        <label class="secondary-text-font-size primary-theme-color mb-1">Mentor Title</label>
                        <input type="text" name="mentorTitle" formControlName="mentorTitle" [readonly]="isView"
                            class="form-input form-control theme-form-control pt-2 placeholder-fix"
                            autocomplete="off" />
                        <div class="text-danger mt-1" *ngIf="f.mentorTitle.touched && f.mentorTitle?.errors?.required">
                            Mentor Title is Required
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-lg-6">
                    <div class="form-group mt-3">
                        <label class="secondary-text-font-size primary-theme-color mb-1">Mentor Available</label>
                        <div class="row container">
                            <label class="col-md-6 form-check">
                                <input class="form-check-input" type="radio" value="true" formControlName="isAvailable" [readonly]="isView">
                                <span class="roboto form-check-label">Yes</span>
                            </label>
                            <label class="col-md-6 form-check">
                                <input class="form-check-input" type="radio" value="false" formControlName="isAvailable" [readonly]="isView">
                                <span class="roboto form-check-label">No</span>
                            </label>
                        </div>
                    </div>
                </div>


                <div class="col-md-12 col-lg-12">
                    <div class="form-group mt-3">
                        <label class="secondary-text-font-size primary-theme-color mb-1">Mentor Description</label>
                        <textarea type="text" name="mentorDescription" formControlName="mentorDescription"
                            [readonly]="isView" class="form-input form-control theme-form-control pt-2 placeholder-fix"
                            autocomplete="off" rows="5"></textarea>
                        <div class="text-danger mt-1" *ngIf="f.mentorDescription.touched && f.mentorDescription?.errors?.required">
                            Mentor Description is Required
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="closeModal()">
            Close
        </button>
        <button type="button" class="btn btn-primary" (click)="onSubmitForm()" *ngIf="!isView">
            Save changes
        </button>
    </div>
</ng-template>

<ng-template #deleteMentorModal let-modal>
    <div class="modal-header">
        <h3 class="modal-title roboto-bold">Delete Mentor</h3>
        <span class="btn btn-close btn-outline-danger" data-dismiss="modal" aria-label="Close"
            (click)="closeModal()"></span>
    </div>

    <div class="modal-body">
        <h4 class="roboto-bold">Are you sure you want to delete this mentor?</h4>
        <div class="row my-3 d-flex align-items-center">
            <div class="col-md-4">
                <img class="img-fluid rounded-circle" [src]="mentorImage" [alt]="mentorName">
            </div>
            <div class="col-md-8">
                <h5 class="pb-primary-color rubik">{{ mentorName }}</h5>
                <p class="roboto">{{ mentorShortDescription }}</p>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="closeModal()">
            Close
        </button>
        <button type="button" class="btn btn-danger" (click)="onDelete()" *ngIf="!isView && !isEdit">
            Delete
        </button>
    </div>
</ng-template>

<div class="loading-div" *ngIf="isLoading">
    <img class="loading-mode-width loading-spinner" src="/assets/images/loader.gif" alt="" />
</div>