import { PersonalityReportComponent } from './personality-test-pages/personality-report/personality-report.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignupComponent } from './auth-pages/signup/signup.component';
import { LoginComponent } from './auth-pages/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PersonalityTestComponent } from './personality-test-pages/personality-test/personality-test.component';
import { ForgetPasswordComponent } from './auth-pages/forget-password/forget-password.component';
import { EventsComponent } from './event-pages/events/events.component';
import { MentorsComponent } from './mentors/mentors.component';
import { UserProfileComponent } from './profile-pages/user-profile/user-profile.component';
import { CourseVideosComponent } from './course-pages/course-videos/course-videos.component';
import { ExploreCoursesComponent } from './course-pages/explore-courses/explore-courses.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { HeaderComponent } from './components/header/header.component';
import { ImpactReportComponent } from './components/impact-report/impact-report.component';
import { UserCoursesComponent } from './course-pages/user-courses/user-courses.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AdminModule } from './admin/admin.module';
import { ClientsModule } from './clients/clients.module';
import { ToastMessageService } from './services/toast-message.service';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';
import { environment } from 'src/environments/environment';
import { PersonalityTestPromptComponent } from './components/personality-test-prompt/personality-test-prompt.component';
import { ImpactReportPromptComponent } from './components/impact-report-prompt/impact-report-prompt.component';
import { MobileSidebarComponent } from './components/mobile-sidebar/mobile-sidebar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { ProgramsComponent } from './components/programs/programs.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';


@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    LoginComponent,
    PersonalityTestComponent,
    ForgetPasswordComponent,
    EventsComponent,
    MentorsComponent,
    UserProfileComponent,
    CourseVideosComponent,
    ExploreCoursesComponent,
    SidebarComponent,
    HeaderComponent,
    PersonalityReportComponent,
    ImpactReportComponent,
    UserCoursesComponent,
    NotFoundComponent,
    PersonalityTestPromptComponent,
    ImpactReportPromptComponent,
    MobileSidebarComponent,
    ProgramsComponent,
    VerifyEmailComponent
   ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    SocialLoginModule,
    AdminModule,
    ClientsModule,
    ToastrModule.forRoot(),
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AngularMultiSelectModule,
    NgbModule
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              environment.googleClientId
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebookClientId)
          }
        ]
      } as SocialAuthServiceConfig,
    }, 
    ToastMessageService],
  bootstrap: [AppComponent],
})
export class AppModule {}
