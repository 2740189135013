<div class="profile-header">
  <h2 class="current-page-name">{{ title }}</h2>
  <div class="profile-content">
    <div class="dropdown">
      <img
        class="user-image"
        src="../../assets/images/profile.png"
        alt="user image"
      />
      <div class="dropdown-content">
        <a style="border-top-left-radius: 20px;" (click)="goToProfile()">My Profile</a>
        <a (click)="goToCourses()">My Courses</a>
        <a style="border-bottom-right-radius: 20px;" (click)="logout()">Logout</a>
      </div>
    </div>
    <div class="user-info">
      <span class="user-name">{{ currentUser?.name }}</span>
      <br />
      <span class="user-email">{{ currentUser?.email }}</span>
    </div>
  </div>
</div>
