import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ImpactReportComponent } from './components/impact-report/impact-report.component';
import { CourseVideosComponent } from './course-pages/course-videos/course-videos.component';

import { EventsComponent } from './event-pages/events/events.component';
import { ExploreCoursesComponent } from './course-pages/explore-courses/explore-courses.component';
import { ForgetPasswordComponent } from './auth-pages/forget-password/forget-password.component';
import { LoginComponent } from './auth-pages/login/login.component';
import { MentorsComponent } from './mentors/mentors.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PersonalityReportComponent } from './personality-test-pages/personality-report/personality-report.component';
import { PersonalityTestComponent } from './personality-test-pages/personality-test/personality-test.component';
import { UserProfileComponent } from './profile-pages/user-profile/user-profile.component';
import { SignupComponent } from './auth-pages/signup/signup.component';
import { UserCoursesComponent } from './course-pages/user-courses/user-courses.component';

import { UserAuthGuard } from './user-auth.guard';

const routes: Routes = [
  {
    path: '',
    component: ExploreCoursesComponent,
    canActivate: [UserAuthGuard]
    // component: MobileScholarshipComponent
  },
  {
    path: 'signup',
    component: SignupComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'personality-test',
    component: PersonalityTestComponent,
    // canActivate: [UserAuthGuard]
  },
  {
    path: 'forget-password',
    component: ForgetPasswordComponent,
    // canActivate: [UserAuthGuard]
  },
  {
    path: 'events',
    component: EventsComponent,
    canActivate: [UserAuthGuard]
  },
  {
    path: 'my-profile',
    component: UserProfileComponent,
    canActivate: [UserAuthGuard]
  },
  {
    path: 'mentors',
    component: MentorsComponent,
    canActivate: [UserAuthGuard]
  },
  {
    path: 'courses',
    component: ExploreCoursesComponent,
    canActivate: [UserAuthGuard]
  },
  {
    path: 'course-videos',
    component: CourseVideosComponent,
    canActivate: [UserAuthGuard]
  },
  {
    path: 'personality-report',
    component: PersonalityReportComponent,
    // canActivate: [UserAuthGuard]
  },
  {
    path: 'impact-report',
    component: ImpactReportComponent,
    canActivate: [UserAuthGuard]
  },
  {
    path: 'my-courses',
    component: UserCoursesComponent,
    canActivate: [UserAuthGuard]
  },
  {
    path: 'admin',
    loadChildren: './admin/admin.module#AdminModule',
  },
  {
    path: 'client',
    loadChildren: './clients/clients.module#ClientsModule'
  },
  {path: '404', component: NotFoundComponent},
  {path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
