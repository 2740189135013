<app-verify-email></app-verify-email>
<div class="main">
  <div class="sidebar-container">
    <app-sidebar></app-sidebar>
  </div>

  <app-mobile-sidebar></app-mobile-sidebar>

  <div class="main-content">
    <!-- Profile section -->
    <app-header class="header" title="My Profile"></app-header>

    <div class="event-container-mobile">
      <h2 class="current-page-name">My Profile</h2>
      <i class="fas fa-bars" (click)="openSidebar()"></i>
    </div>

    <!-- Profile section -->
    <div class="profile-main">
      <div class="profile-info-container">
        <div class="banner-container">
          <div class="user-image-container">
            <img
              class="user-profile-image"
              src="../../assets/images/profile.png"
              alt="User image"
            />
          </div>
        </div>

        <div class="user-profile-info">
          <h2 class="user-profile-name">{{ currentUser?.name }}</h2>
          <p class="user-profile-email">{{ currentUser?.email }}</p>

          <div class="container" *ngIf="!displayEditForm">
            <div class="row w-75 mx-auto">
              <div class="col-md-5 d-flex my-3">
                <i class="fas fa-phone-alt"></i>
                <div class="d-flex flex-column align-items-start ms-3">
                  <span class="small-text">Phone Number</span>
                  <span class="roboto-bold">+91 {{ currentUser?.mobile }}</span>
                </div>
              </div>
  
              <div class="col-md-7 d-flex my-3">
                <i class="fas fa-envelope"></i>
                <div class="d-flex flex-column align-items-start ms-3">
                  <span class="small-text">Email Address</span>
                  <span class="roboto-bold">{{ currentUser?.email }}</span>
                </div>
              </div>
  
              <div class="col-md-5 d-flex my-3">
                <i class="fas fa-graduation-cap"></i>
                <div class="d-flex flex-column align-items-start ms-3">
                  <span class="small-text">Class</span>
                  <span class="roboto-bold">{{ currentUser?.class_name }} <sup>th</sup></span>
                </div>
              </div>
  
              <div class="col-md-7 d-flex my-3">
                <i class="fas fa-school"></i>
                <div class="d-flex flex-column align-items-start ms-3">
                  <span class="small-text">School Name</span>
                  <span class="roboto-bold">{{ currentUser?.school_name }} </span>
                </div>
              </div>
            </div>
          </div>

          <div class="container" *ngIf="displayEditForm">
            <div class="row w-75 mx-auto">
              <form [formGroup]="profileForm">
                <div class="row clearfix px-3">
                    <div class="col-md-12 col-lg-6">
                        <div class="form-group mt-3 text-start text-start">
                            <label class="primary-theme-color mb-1 pb-grey-light-color">Full Name</label>
                            <input type="text" name="name" formControlName="name"
                                [readonly]="isView"
                                class="form-input form-control theme-form-control pt-2 placeholder-fix" autocomplete="off" />
                            <div class="text-danger mt-1" *ngIf="f.name.touched && f.name?.errors?.required">
                                Full Name is Required
                            </div>
                        </div>
                    </div>
    
                    <div class="col-md-12 col-lg-6">
                        <div class="form-group mt-3 text-start">
                            <label class="primary-theme-color mb-1 pb-grey-light-color">Class</label>
                            <input type="text" name="class" formControlName="class"
                                [readonly]="isView"
                                class="form-input form-control theme-form-control pt-2 placeholder-fix" autocomplete="off" />
                            <div class="text-danger mt-1" *ngIf="f.class.touched && f.class?.errors?.required">
                                Class is Required
                            </div>
                        </div>
                    </div>
    
    
                    <div class="col-md-12 col-lg-6">
                        <div class="form-group mt-3 text-start">
                            <label class="primary-theme-color mb-1 pb-grey-light-color">School Name</label>
                            <input type="text" name="school" formControlName="school"
                                [readonly]="isView"
                                class="form-input form-control theme-form-control pt-2 placeholder-fix" autocomplete="off" />
                            <div class="text-danger mt-1" *ngIf="f.school.touched && f.school?.errors?.required">
                                School Name is Required
                            </div>
                        </div>
                    </div>
    
                </div>
            </form>
            </div>
          </div>


          <button *ngIf="!displayEditForm" class="edit-button my-4" (click)="showUpdateProfile()">
            Edit Profile
          </button>
          <button *ngIf="displayEditForm" class="edit-button my-4" (click)="updateProfile()" [disabled]="profileForm.invalid">
            Update Profile
          </button>
        </div>
      </div>

      <div class="other-prompts">
        <app-personality-test-prompt></app-personality-test-prompt>

        <app-impact-report-prompt></app-impact-report-prompt>
      </div>
    </div>
  </div>

</div>

<div class="loading-div" *ngIf="isLoading">
  <img class="loading-mode-width loading-spinner" src="/assets/images/loader.gif" alt="">
</div>
