<div class="student-info hcl-report-bg-dark-color text-white py-4 px-4 col-4 col-md-3">
  <h3 class="rubik">Student Info</h3>
  <div class="row">
    <div class="col-md-7">
      <span class="roboto">Name</span>
      <br />
      <span class="h5">{{ data.name  }}</span>
    </div>
    <div class="col-md-5">
      <span class="roboto">Class</span>
      <br />
      <span class="h5">{{ data.class  }}</span>
    </div>
    <div class="col-md-7 mt-2">
      <span class="roboto">School</span>
      <br />
      <span class="h5">{{ data.school }}</span>
    </div>
    <div class="col-md-5 mt-2">
      <span class="roboto">State</span>
      <br />
      <span class="h5">{{ data.state }}</span>
    </div>
  </div>

</div>
