<app-admin-header></app-admin-header>

<div class="container py-4">
    <div>
        <h2>Courses</h2>
    </div>

    <button type="button" class="btn btn-primary float-end m-3" (click)="openModal(courseModal)">
        Add Course
    </button>

    <div class="">
        <table class="table table-striped table-secondary">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Course Name</th>
                    <th scope="col">No. of lessons</th>
                    <th scope="col">Total Hours</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of courses; let i = index">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>{{ item?.course_name }}</td>
                    <td>{{ item?.num_of_lessons }}</td>
                    <td>{{ item?.total_hours }}</td>
                    <td>&#x20B9; {{ item?.amount == 0 ? "Free" : item?.amount }}</td>
                    <td>
                        <i class="fa fa-eye" data-bs-toggle="tooltip" data-bs-placement="top" title="View"
                            (click)="openModal(courseModal, item, true)"></i>
                        <i class="fa fa-edit" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"
                            (click)="openModal(courseModal, item, false, true)"></i>
                        <i class="fa fa-trash-o" aria-hidden="true" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Delete" (click)="openDeleteModal(deleteCourseModal, item)"></i>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<ng-template #courseModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title" *ngIf="!isView">
            {{ isEdit ? "Edit" : "Add" }} Course
        </h5>
        <h5 class="modal-title" *ngIf="isView">View Course</h5>
        <span class="btn btn-close btn-outline-danger" data-dismiss="modal" aria-label="Close"
            (click)="closeModal()"></span>
    </div>
    <div class="modal-body">
        <img *ngIf="isView" [src]="courseImage" class="img-fluid" [alt]="courseName" />

        <form [formGroup]="courseForm">
            <div class="row clearfix px-3">
                <div class="col-md-12 col-lg-6">
                    <div class="form-group mt-3">
                        <label class="secondary-text-font-size primary-theme-color mb-1">Course Name</label>
                        <input type="text" name="courseName" formControlName="courseName" [readonly]="isView"
                            class="form-input form-control theme-form-control pt-2 placeholder-fix"
                            autocomplete="off" />
                        <div class="text-danger mt-1" *ngIf="f.courseName.touched && f.courseName?.errors?.required">
                            Course Name is Required
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-lg-6">
                    <div class="form-group mt-3">
                        <label class="secondary-text-font-size primary-theme-color mb-1">Course Image Url</label>
                        <input type="text" name="courseImage" formControlName="courseImage" [readonly]="isView"
                            class="form-input form-control theme-form-control pt-2 placeholder-fix"
                            autocomplete="off" />
                        <div class="text-danger mt-1" *ngIf="f.courseImage.touched && f.courseImage?.errors?.required">
                            Course Image Url is Required
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-lg-6">
                    <div class="form-group mt-3">
                        <label class="secondary-text-font-size primary-theme-color mb-1">Course Order</label>
                        <input type="text" name="courseOrder" formControlName="courseOrder" [readonly]="isView"
                            class="form-input form-control theme-form-control pt-2 placeholder-fix"
                            autocomplete="off" />
                        <div class="text-danger mt-1" *ngIf="f.courseOrder.touched && f.courseOrder?.errors?.required">
                            Course Order is Required
                        </div>
                        <div class="text-danger mt-1" *ngIf="f.courseOrder?.errors?.pattern">
                            Only Numbers are allowed
                        </div>
                    </div>
                </div>

                <div class="col-md-6 col-lg-6">
                    <div class="form-group mt-3">
                        <label class="secondary-text-font-size primary-theme-color mb-1">Course Price</label>
                        <angular2-multiselect 
                            [data]="dropdownList" 
                            [settings]="dropdownSettings" 
                            name="freeOrPaid" 
                            formControlName="freeOrPaid">
                        </angular2-multiselect>
                        <div class="text-danger mt-1" *ngIf="f.freeOrPaid.touched && f.freeOrPaid?.errors?.required">
                            Course Price is Required
                        </div>
                        <div class="text-danger mt-1" *ngIf="f.freeOrPaid?.errors?.pattern">
                            Only Numbers are allowed
                        </div>
                    </div>
                </div>

                <div class="col-md-6 col-lg-4">
                    <div class="form-group mt-3">
                        <label class="secondary-text-font-size primary-theme-color mb-1">Amount</label>
                        <input type="text" name="amount" formControlName="amount" [readonly]="isView"
                            class="form-input form-control theme-form-control pt-2 placeholder-fix"
                            autocomplete="off" />
                        <div class="text-danger mt-1" *ngIf="f.amount.touched && f.amount?.errors?.required">
                            Amount is Required
                        </div>
                        <div class="text-danger mt-1" *ngIf="f.amount?.errors?.pattern">
                            Only Numbers are allowed
                        </div>
                    </div>
                </div>

                <div class="col-md-6 col-lg-4">
                    <div class="form-group mt-3">
                        <label class="secondary-text-font-size primary-theme-color mb-1">Number of Lessons</label>
                        <input type="text" name="numOfLessons" formControlName="numOfLessons" [readonly]="isView"
                            class="form-input form-control theme-form-control pt-2 placeholder-fix"
                            autocomplete="off" />
                        <div class="text-danger mt-1"
                            *ngIf="f.numOfLessons.touched && f.numOfLessons?.errors?.required">
                            Num of Lessons is Required
                        </div>
                        <div class="text-danger mt-1" *ngIf="f.numOfLessons?.errors?.pattern">
                            Only Numbers are allowed
                        </div>
                    </div>
                </div>

                <div class="col-md-6 col-lg-4">
                    <div class="form-group mt-3">
                        <label class="secondary-text-font-size primary-theme-color mb-1">Total Hours</label>
                        <input type="text" name="totalHours" formControlName="totalHours" [readonly]="isView"
                            class="form-input form-control theme-form-control pt-2 placeholder-fix"
                            autocomplete="off" />
                        <div class="text-danger mt-1" *ngIf="f.totalHours.touched && f.totalHours?.errors?.required">
                            Total Hours is Required
                        </div>
                        <div class="text-danger mt-1" *ngIf="f.totalHours?.errors?.pattern">
                            Only Floating Numbers are allowed
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-lg-12">
                    <div class="form-group mt-3">
                        <label class="secondary-text-font-size primary-theme-color mb-1">Course Description</label>
                        <textarea type="text" name="courseDescription" formControlName="courseDescription"
                            [readonly]="isView" class="form-input form-control theme-form-control pt-2 placeholder-fix"
                            autocomplete="off" rows="5"></textarea>
                        <div class="text-danger mt-1" *ngIf="f.courseDescription.touched && f.courseDescription?.errors?.required">
                            Course Description is Required
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="closeModal()">
            Close
        </button>
        <button type="button" class="btn btn-primary" (click)="onSubmitForm()" *ngIf="!isView">
            Save changes
        </button>
    </div>
</ng-template>

<ng-template #deleteCourseModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Delete Course</h5>
        <span class="btn btn-close btn-outline-danger" data-dismiss="modal" aria-label="Close"
            (click)="closeModal()"></span>
    </div>

    <div class="modal-body">
        <h4>Are you sure you want to delete this course?</h4>
        <div class="row my-3 d-flex align-items-center">
            <div class="col-md-4">
                <img class="img-fluid" [src]="courseImage" [alt]="courseName">
            </div>
            <div class="col-md-8">
                <h3 class="text-primary">{{ courseName }}</h3>
                <p>{{ courseDescription }}</p>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="closeModal()">
            Close
        </button>
        <button type="button" class="btn btn-danger" (click)="onDelete()" *ngIf="!isView && !isEdit">
            Delete
        </button>
    </div>
</ng-template>

<div class="loading-div" *ngIf="isLoading">
    <img class="loading-mode-width loading-spinner" src="/assets/images/loader.gif" alt="" />
</div>