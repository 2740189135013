import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { SvgService } from 'src/app/svg.service';
import { ToastMessageService } from 'src/app/services/toast-message.service';
import { ActivatedRoute, Router } from '@angular/router';
import mixpanel from 'mixpanel-browser';
import { saveAs } from 'file-saver'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import jsPDF from 'jspdf';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';

@Component({
  selector: 'app-hcl-report',
  templateUrl: './hcl-report.component.html',
  styleUrls: ['./hcl-report.component.css']
})
export class HclReportComponent implements OnInit {

  @ViewChild('sampleDiv') sampleDiv: ElementRef;

  public personalityTestData: any;
  public isLoading: boolean = false;
  public personalityResultData: any;
  public fetchFromlocalStorage: boolean = false;

  public probano_logo: SafeHtml;
  
  constructor(
    private apiService: ApiService,
    private toastMessageService: ToastMessageService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private svg: SvgService
  ) { }



  ngOnInit(): void {
    this.route.queryParams.subscribe(data => {
      if(data && 
        (!Object.keys(data).length) || 
        (!data.clientCode || !data.studentId || !data.redeemCode) 
      ) {
        this.router.navigate(['/']);
      } else {
        this.personalityTestData = data;
        this.validatePersonalityTestResult();
      }
    })
    this.probano_logo = this.sanitizer.bypassSecurityTrustHtml(this.svg.probano_logo);
  }

  async createAndDownload(){
    const doc = new jsPDF();
   
    const sampleDiv = this.sampleDiv.nativeElement;
   
    var html = htmlToPdfmake(sampleDiv.innerHTML);
     
    const documentDefinition = { content: html};
    const fileName = `${this.personalityResultData?.student_info.name}-Psychometric Test Report.pdf`;
    console.log('File Name: ', fileName);
    pdfMake.createPdf(documentDefinition).download(fileName);
  }

  getCategoryImage(category: string) {
    return this.sanitizer.bypassSecurityTrustHtml(this.svg.categoryImage(category));
  }

  getTraitImage(trait: string){
    return this.sanitizer.bypassSecurityTrustHtml(this.svg.traitImage(trait));
  }

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      };
      img.onerror = error => {
        reject(error);
      };
      img.src = url;
    });
  }

  downloadPDF() {
    let url;
    if(this.personalityTestData && this.personalityTestData.clientCode) {
      const { clientCode, studentId, redeemCode } = this.personalityTestData;
      url = `pdf/${clientCode}/${studentId}/${redeemCode}`;
    } else {
      const { client_code, student_id, redeem_code } = this.personalityTestData;
      url = `pdf/${client_code}/${student_id}/${redeem_code}`;
    }
    this.downloadPDFApi(url, this.personalityResultData.student_info.name); 
  }

  downloadPDFApi(url, pdfName) {
    this.isLoading = true;
    this.apiService.downloadPDF(url).subscribe(
      data => {
        this.isLoading = false;
        saveAs(data, pdfName + ".pdf");
      },
      err => {
        this.isLoading = false;
        this.toastMessageService.showError(err.error.detail)
      }
    )
  }

  validatePersonalityTestResult() {
    this.isLoading = true;
    if(!Object.keys(this.personalityTestData).length) {
      this.fetchFromlocalStorage = true;
      this.personalityTestData = JSON.parse(localStorage.getItem('personality_test_client'))
    }
    if(!this.personalityTestData || !Object.keys(this.personalityTestData).length) {
      this.isLoading = false;
      this.router.navigate(['/']);
    } else {
      this.fetchPersonalityTestResult()
    }
    
  }

  fetchPersonalityTestResult() {
    this.isLoading = true;
    let url;
    if(this.fetchFromlocalStorage) {
      url =  `personality-test/result/${this.personalityTestData.client_code}/${this.personalityTestData.student_id}/${this.personalityTestData.redeem_code}`
    } else {
      url =  `personality-test/result/${this.personalityTestData.clientCode}/${this.personalityTestData.studentId}/${this.personalityTestData.redeemCode}`
    }
    this.apiService.get(url).subscribe(
      data => {
        if(data.status) {
          this.personalityResultData = data;
        } else {
          this.toastMessageService.showError(data.message);
        }  
      },
      err => {
        this.router.navigate(['/']);
        mixpanel.track('test-result-error', {
          error: err
        });
        this.toastMessageService.showError(err.error.detail);
      },
      () => this.isLoading = false
    )
  }

  sendEmailToStudent() {
    this.isLoading = true;
    let data;
    if(!this.fetchFromlocalStorage) {
      data = {
        client_code: this.personalityTestData.clientCode,
        redeem_code: this.personalityTestData.redeemCode,
        student_id: this.personalityTestData.studentId
      }
    } else {
      data = this.personalityTestData
    }
    this.apiService.postUser('send-email-client-user', data).subscribe(
      data => {
        if(data.status) {
          mixpanel.track('report-sent', {
            data: this.personalityTestData
          });
          this.toastMessageService.showSuccess(data.message)
        } else {
          this.toastMessageService.showError(data.message)
        }
      },
      err => {
        mixpanel.track('report-error', {
          error: err
        });
        this.toastMessageService.showError(err.error.detail);
      },
      () => this.isLoading = false
    )
  }
}
