import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient
  ) { }

  private setHeaders() {
    let headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json'});
    const token = localStorage.getItem('auth_token'); // For Development Only
    // const token = "86f10819f2d66fb49739b53e6474e082f04153a2"
    // const token = this.cookieService.get('auth_token');
    if (token !== null) {
      headers = headers.set('Authorization', 'Token ' + token);
    }
    return headers;
  }

  get(path: string): Observable<any>{
    const url = environment.api_url + '/' + path;

    return this.http.get(url, {headers: this.setHeaders()}).pipe(map((res: Response) => res), catchError((error: Response) => throwError(error)));
  }

  post(path: string, body): Observable<any>{
    const url = environment.api_url + '/' + path;
    return this.http.post(url, body, {headers: this.setHeaders()}).pipe(map((res: Response) => res), catchError((error: Response) => throwError(error)));
  }

  postUser(path: string, body: any): Observable<any> {
    const url = environment.api_url + '/' + path;
    return this.http.post(url, body)
    .pipe(map((res: Response) => res), catchError((error: Response) => throwError(error)));
  }

  postScholarship(mobile_no, student_class): Observable<any> {
    const url = `https://auroscholar.com/api/partnerapilogin.php?partner_id=ProBano&partner_source=PROBp0lmzl&mobile_no=${mobile_no}&student_class=${student_class}`
    return this.http.post(url, '').pipe(
        map((res: Response) => res),
        catchError((error: Response) => throwError(error))
    );
}


  put(path: string, body): Observable<any>{
    const url = environment.api_url + '/' + path;
    return this.http.put(url, body, {headers: this.setHeaders()}).pipe(map((res: Response) => res), catchError((error: Response) => throwError(error)));
  }

  delete(path: string): Observable<any>{
    const url = environment.api_url + '/' + path;
    return this.http.delete(url, {headers: this.setHeaders()}).pipe(map((res: Response) => res), catchError((error: Response) => throwError(error)));
  }

  upload(path, file, name_scheme_code, subject_id?): Observable<any> {
    const url = environment.api_url + '/' + path
    const formData = new FormData();
    formData.append("name_scheme_code", name_scheme_code);
    if (subject_id) {
      formData.append("subject_id", subject_id);
    }
    formData.append("file", file, file.name);
    const token = localStorage.getItem('auth_token'); // For Development Only
    // const token = this.cookieService.get('auth_token');
    let headers = new HttpHeaders({'Authorization': token});
    return this.http.post(url, formData, {headers: headers}).pipe(map((res: Response) => res), catchError((error: Response) => throwError(error)));
  }

  downloadPDF(path) {
    const url = environment.api_url + '/' + path;
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get(url, { headers: headers, responseType: 'blob' });
  }

}
