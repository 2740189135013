import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { ToastMessageService } from 'src/app/services/toast-message.service';
import mixpanel from 'mixpanel-browser';

@Component({
  selector: 'app-hcl-personality-test',
  templateUrl: './hcl-personality-test.component.html',
  styleUrls: ['./hcl-personality-test.component.css']
})
export class HclPersonalityTestComponent implements OnInit {

  public isLoading: boolean = false;

  public questionNumber: number = 0;
  public currentQuestionOrder = [];
  public currentQuestion: any = [];
  public currentQuestionIds: any = [];
  public postData: any = [];
  public questionsData: any = [];
  public submittedAnswerIds: any = [];
  public solvedQuestions = [];
  public progressValue = 0;

  constructor(
    private apiService: ApiService,
    private toastMessageService: ToastMessageService,
    private router: Router,
  ) { }


  ngOnInit(): void {
    this.checkAlreadyExist()
  }

  submitQuestions() {

    if (this.currentQuestionIds.length !== 4) {
      this.toastMessageService.showInfo("Please select 4 choices");
    } else {
      this.submittedAnswerIds.push(this.currentQuestionIds);
      this.isLoading = true;
      let postData = {
        student_id: this.postData.student_id,
        redeem_code: this.postData.redeem_code,
        client_id: this.postData.client_id,
        answers: this.submittedAnswerIds
      }
      this.apiService.postUser('get-personality-test-response', postData).subscribe(
        data => {
          if (!data.status) {
            this.toastMessageService.showError(data.message)
          } else {
            mixpanel.track('test-completed', {
              data: postData
            });
            this.router.navigate(['client/report'], {
              queryParams: {
                clientCode: this.postData.client_code,
                studentId: this.postData.student_id,
                redeemCode: this.postData.redeem_code
              }
            })
          }
        },
        err => {
          mixpanel.track('test-result-err', {
            error: err
          });
          this.toastMessageService.showError(err.error.detail);
        },
        () => this.isLoading = false
      )
    }


  }

  fetchPreviousQuestion() {
    this.currentQuestionIds = []
    for (let i = 0; i < this.currentQuestion.length; i++) {
      this.currentQuestion[i]['selected'] = false
      this.currentQuestion[i]['number'] = ""
    }
    this.questionNumber -= 1;
    this.currentQuestion = this.questionsData.questions[this.questionNumber];
    this.currentQuestionOrder = this.solvedQuestions[this.questionNumber];
    this.submittedAnswerIds.pop()

    for (let i = 0; i < this.currentQuestionOrder.length; i++) {
      this.currentQuestionIds.push(this.currentQuestionOrder[i]['career_id'])
    }
    this.progressValue -= 2.85;

  }

  fetchNextQuestion() {

    if (this.currentQuestionIds.length !== 4) {
      this.toastMessageService.showInfo("Please select 4 choices")
    } else {
      this.progressValue += 2.85;
      this.displayNextQuestion();
    }
  }

  public displayNextQuestion() {
    this.submittedAnswerIds.push(this.currentQuestionIds)
    if (this.currentQuestionOrder.length == 4) {
      this.solvedQuestions[this.questionNumber] = this.currentQuestionOrder;
    }
    this.questionNumber += 1;
    this.checkSolvedQuestions();
    this.currentQuestionIds = [];
  }

  private checkSolvedQuestions() {
    if (this.solvedQuestions[this.questionNumber]) {
      this.currentQuestion = this.questionsData.questions[this.questionNumber]
      this.currentQuestionOrder = []
      for (let i = 0; i < this.currentQuestion.length; i++) {
        this.currentQuestion[i]['selected'] = false
      }
    } else {
      this.currentQuestion = this.questionsData.questions[this.questionNumber];
      this.currentQuestionOrder = []
      return;
    }
  }


  toggleClick(career) {
    career.selected = !career.selected;

    if (career.selected) {
      if (this.currentQuestionOrder.length < 4) {
        this.currentQuestionOrder.push(career);
        career.number = this.currentQuestionOrder.length
        this.currentQuestionIds.push(career.career_id)
      }
    } else {
      this.currentQuestionOrder = this.currentQuestionOrder.filter((item) => item != career);
      this.currentQuestionIds = this.currentQuestionIds.filter((item) => item != career.career_id)
      for (let i = 0; i < this.currentQuestionOrder.length; i++) {
        for (let j = 0; j < this.currentQuestion.length; j++) {
          if (this.currentQuestionOrder[i]['career_id'] == this.currentQuestion[j]['career_id']) {
            this.currentQuestion[j]['number'] = i + 1
            break;
          }
        }
      }
      career.number = null
    }

  }

  checkAlreadyExist() {
    this.isLoading = true;
    this.postData = JSON.parse(localStorage.getItem('personality_test_client'));
    let data = {
      email: this.postData.student_email || '',
      redeem_code: this.postData.redeem_code || '',
      client_id: this.postData.client_id
    };
    this.apiService.postUser('personality-test-client', data).subscribe(
      data => {
        if (data.status && data.personality_test_status) {
          this.router.navigate(['client/report'], {
            queryParams: {
              clientCode: data.data.client_code,
              studentId: data.data.student_id,
              redeemCode: data.data.redeem_code
            }
          })
        } else {
          this.getAllQuestions();
        }
      },
      err => {
        this.toastMessageService.showError(err.error.detail);
      },
      () => this.isLoading = false
    )
  }


  getAllQuestions() {
    this.isLoading = true;
    if (!this.postData) {
      this.toastMessageService.showError("Something went wrong")
      this.isLoading = false;
      this.router.navigate(['/'])
      return;
    }
    let data = {
      ...this.postData,
      email: this.postData.student_email
    }
    this.apiService.postUser('get-personality-test-questions', data).subscribe(
      data => {
        if (!data.status) {
          this.toastMessageService.showError(data.message)
        } else {
          this.questionsData = data;
          this.currentQuestion = this.questionsData.questions[this.questionNumber]
        }
      },
      err => {
        this.toastMessageService.showError(err.error.detail);
      },
      () => this.isLoading = false
    )
  }

}
