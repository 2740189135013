import { Router } from '@angular/router';
import { ApiService } from '../../api.service';
import { Component, OnInit } from '@angular/core';
import { MixpanelService } from '../../mixpanel.service';
import { ToastMessageService } from 'src/app/services/toast-message.service';

@Component({
  selector: 'app-impact-report-prompt',
  templateUrl: './impact-report-prompt.component.html',
  styleUrls: ['./impact-report-prompt.component.css'],
})
export class ImpactReportPromptComponent implements OnInit {
  public impactReportError: string = '';

  constructor(
    private apiService: ApiService,
    private mixpanelService: MixpanelService,
    private router: Router,
    private toastMessageService: ToastMessageService
  ) {}

  public viewImpactReport() {
    this.mixpanelService.event("Clicked on impact report button")
    this.apiService.get('assessment/result').subscribe(
      (data) => {
        if (data.status) {
          this.mixpanelService.event('impact-report-watched');
          this.router.navigate(['/impact-report']);
        } else {
          this.toastMessageService.showError('Please Complete all the assessments.')
        }
      },
      (err) => {
        this.mixpanelService.error('assessment-result-error', {
          message: err.error,
        });
      }
    );
  }

  ngOnInit(): void {}
}
