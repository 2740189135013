<footer class="pb-primary-color-bg font-small blue-grey lighten-5 fixed-footer-bottom">
  <div class="hcl-footer">
    <div class="container">
      <div class="row py-4 d-flex align-items-center">
        <div class="col-md-6 col-6 col-lg-5 text-md-left mb-4 mb-md-0">
          <a href="/">
            <img class="mb-0" src="../../../../assets/images/probano_logo.svg" alt="">
          </a>
        </div>

        <div class="col-md-6 col-6 col-lg-7 text-end text-md-right">
          <h6 class="mb-0 text-white roboto-medium">Powered by ProBano</h6>
        </div>
      </div>
    </div>
  </div>
</footer>
