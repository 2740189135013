<div class="full-page pt-10-vh bg-primary-theme pb-5 px-3">
  <div class="container auth-main-content">
    <div class="row">
      <div class="col-md-7 pt-4 d-flex flex-column align-items-center logo-container pb-5">

        <img class="probano-logo img-fluid my-2 mb-5" src="../../assets/images/probano_dark_logo.svg"
          alt="ProBano logo" />
        <img class="img-fluid mt-5" src="../../assets/images/auth_illustration.svg" alt="Probano image" />

      </div>
      <div class="col-md-5 pt-4 pb-5 px-5">

        <h4 class="mt-4 rubik-bold">
          Create New Account
        </h4>


        <div class="px-3">
          <form [formGroup]="userSignUpForm">
            <div class="row" *ngIf="!displayNextForm">

              <div class="col-12 px-0">
                <div class="form-group mt-3">
                  <label class="secondary-text-font-size primary-theme-color mb-1 roboto pb-grey-light-color">Full
                    Name</label>
                  <input type="text" name="fullName" formControlName="fullName"
                    class="form-input form-control theme-form-control py-2 placeholder-fix pb-fade-grey-light-color-bg"
                    autocomplete="off" />
                  <div class="text-danger mt-1" *ngIf="f?.fullName?.touched && f?.fullName?.errors?.required">
                    Full Name is Required
                  </div>
                  <div class="text-danger mt-1" *ngIf="f?.fullName?.touched && f?.fullName?.errors?.pattern">
                    Please enter a valid name
                  </div>
                </div>
              </div>


              <div class="col-12 px-0">
                <div class="form-group mt-3">
                  <label
                    class="secondary-text-font-size primary-theme-color mb-1 roboto pb-grey-light-color">Email</label>
                  <input type="text" name="email" formControlName="email"
                    class="form-input form-control theme-form-control py-2 placeholder-fix pb-fade-grey-light-color-bg"
                    autocomplete="off" />
                  <div class="text-danger mt-1" *ngIf="f?.email?.touched && f?.email?.errors?.required">
                    Email is Required
                  </div>
                  <div class="text-danger mt-1" *ngIf="f?.email?.touched && f?.email?.errors?.pattern">
                    Please enter a valid email
                  </div>
                </div>
              </div>

              <div class="col-12 px-0">
                <div class="form-group mt-3">

                  <label class="secondary-text-font-size primary-theme-color mb-1 roboto pb-grey-light-color">New
                    Password
                  </label>
                  <input type="password" name="password" formControlName="password"
                    class="form-input form-control theme-form-control py-2 placeholder-fix pb-fade-grey-light-color-bg"
                    autocomplete="off" />
                  <div class="text-danger mt-1" *ngIf="f?.password.touched && f?.password?.errors?.required">
                    Password is Required
                  </div>
                </div>
              </div>

              <div class="col-12 px-0">
                <div class="form-group mt-3">
                  <label class="secondary-text-font-size primary-theme-color mb-1 roboto pb-grey-light-color">Confirm
                    New Password
                  </label>
                  <input type="password" name="confirmPassword" formControlName="confirmPassword"
                    class="form-input form-control theme-form-control py-2 placeholder-fix pb-fade-grey-light-color-bg"
                    autocomplete="off" />
                  <div class="text-danger mt-1"
                    *ngIf="f?.confirmPassword.touched && f?.confirmPassword?.errors?.required">
                    Confirm Password is Required
                  </div>
                  <div class="mt-1 pb-primary-color">
                    Password length should be greater than 6 characters
                  </div>

                  <div class="text-danger mt-1"
                    *ngIf="userSignUpForm?.get('password')?.value != userSignUpForm.get('confirmPassword')?.value && userSignUpForm?.get('confirmPassword')?.value != null">
                    Password does not match</div>
                </div>
              </div>

              <button type="button" class="btn pb-primary-color-bg text-white roboto w-100 my-3"
                (click)="showNextForm()" [disabled]="checkValidation()">
                Next
              </button>

            </div>


            <div class="row" *ngIf="displayNextForm">

              <div class="col-12 px-0">
                <div class="form-group mt-3">
                  <label
                    class="secondary-text-font-size primary-theme-color mb-1 roboto pb-grey-light-color">Class</label>
                  <input type="text" name="class" formControlName="class"
                    class="form-input form-control theme-form-control py-2 placeholder-fix pb-fade-grey-light-color-bg"
                    autocomplete="off" />
                  <div class="text-danger mt-1" *ngIf="f?.class?.touched && f?.class?.errors?.required">
                    Class is Required
                  </div>
                </div>
              </div>


              <div class="col-12 px-0">
                <div class="form-group mt-3">
                  <label class="secondary-text-font-size primary-theme-color mb-1 roboto pb-grey-light-color">School
                    Name</label>
                  <input type="text" name="school" formControlName="school"
                    class="form-input form-control theme-form-control py-2 placeholder-fix pb-fade-grey-light-color-bg"
                    autocomplete="off" />
                  <div class="text-danger mt-1" *ngIf="f?.school?.touched && f?.school?.errors?.required">
                    School Name is Required
                  </div>
                  <div class="text-danger mt-1" *ngIf="f?.school?.touched && f?.school?.errors?.pattern">
                    Please enter a valid School Name
                  </div>
                </div>
              </div>

              <div class="col-12 px-0">
                <div class="form-group mt-3">
                  <label class="secondary-text-font-size primary-theme-color mb-1 roboto pb-grey-light-color">City
                    Name</label>
                  <input type="text" name="school" formControlName="city"
                    class="form-input form-control theme-form-control py-2 placeholder-fix pb-fade-grey-light-color-bg"
                    autocomplete="off" />
                  <div class="text-danger mt-1" *ngIf="f?.city?.touched && f?.city?.errors?.required">
                    City Name is Required
                  </div>
                  <div class="text-danger mt-1" *ngIf="f?.city?.touched && f?.city?.errors?.pattern">
                    Please enter a valid City Name
                  </div>
                </div>
              </div>

              <div class="col-12 px-0">
                <div class="form-group mt-3">
                  <label class="secondary-text-font-size primary-theme-color mb-1 roboto pb-grey-light-color">Mobile Number</label>
                  <input type="text" name="mobile" formControlName="mobile"
                    class="form-input form-control theme-form-control py-2 placeholder-fix pb-fade-grey-light-color-bg"
                    autocomplete="off" />
                  <div class="text-danger mt-1" *ngIf="f?.mobile?.touched && f?.mobile?.errors?.required">
                    Mobile Number is Required
                  </div>
                  <div class="text-danger mt-1" *ngIf="f?.mobile?.touched && f?.mobile?.errors?.pattern">
                    Please enter a valid Mobile Number
                  </div>
                </div>
              </div>



              <button type="button" class="btn pb-primary-color-bg text-white roboto w-100 my-3" (click)="submitForm()"
                [disabled]="userSignUpForm.invalid">
                Signup
              </button>

            </div>
          </form>


          <p class="have-text mt-3">Have an account? <span class="login-text" [routerLink]="['/login']">Login</span></p>


        </div>

      </div>
    </div>
  </div>
</div>


<div class="loading-div" *ngIf="isLoading">
  <img class="loading-mode-width loading-spinner" src="/assets/images/loader.gif" alt="">
</div>