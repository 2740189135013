<app-admin-header></app-admin-header>

<div class="container my-5">
    <ul class="nav nav-tabs">
        <li class="nav-item cursor-pointer" (click)="onSwitchingTab('client_users')">
            <a class="nav-link h5" [ngClass]="activeTab === 'client_users' ? 'active': ''">Client Users</a>
        </li>
        <li class="nav-item cursor-pointer" (click)="onSwitchingTab('users')">
            <a class="nav-link h5" [ngClass]="activeTab === 'users' ? 'active': ''" aria-current="page">All Users</a>
        </li>
    </ul>

    <button type="button" class="btn btn-secondary float-end" (click)="exportExcel()" [disabled]="!selectedClient.length && activeTab === 'client_users'">Export Excel</button>

    <div class="mt-5" *ngIf="alreadyExistingEmails && alreadyExistingEmails.length">
        <h4>Here is a list of already existing emails</h4>
        <button type="button" class="btn btn-danger mx-2" *ngFor="let item of alreadyExistingEmails">
            {{ item }}
        </button>
    </div>

    <div *ngIf="activeTab === 'client_users'" class="my-5">

        <div class="row">
            <div class="col-lg-3 col-md-6 my-2 col-6">
                <angular2-multiselect 
                    [data]="clientList" 
                    [(ngModel)]="selectedClient" 
                    [settings]="dropdownSettings"
                    (onSelect)="onClientSelect($event)" 
                    (onDeSelect)="onClientDeSelect($event)"
                >
                </angular2-multiselect>
            </div>

            <div class="col-lg-4 col-md-6 my-2 col-6">
                <button type="button" class="btn btn-primary mx-3 mb-1" [disabled]="!selectedClient.length" (click)="openModal(clientUserModal)">Add
                    User</button>
                <button type="button" class="btn btn-secondary mb-1" [disabled]="!selectedClient.length" (click)="openMultiple(multipleClientUserModal)">Add
                    Multiple Users</button>

            </div>
        </div>
           
        <div class="mt-2 mb-4 row" *ngIf="totalClientUsers">
            <div class="col-md-6">
                <h3>Total {{ selectedClient[0]?.client_name }} Users: {{ totalClientUsers }}</h3>
            </div>
            <div class="col-md-6">
                <h3>Total Test Completed: {{ totalTestCompletedClientUsers }}</h3>
            </div>  
        </div>

        <table class="table table-striped table-secondary" *ngIf="clientId">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Redeem Code</th>
                    <th scope="col">Is Test Completed</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of clientUsers; let i = index">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>{{ item?.name }}</td>
                    <td>{{ item?.email }}</td>
                    <td>{{ item?.redeem_code }}</td>
                    <td>{{ item?.test_done ? "Yes" : "No" }}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div *ngIf="activeTab === 'users'" class="my-5">

        <!-- <button type="button" class="btn btn-secondary mb-3 float-end" (click)="openMultiple(multipleClientUserModal)">Add Multiple Users</button>

        <button type="button" class="btn btn-primary float-end mb-3 me-4" (click)="openModal(clientUserModal)">Add User</button> -->


        <div class="my-3 row" *ngIf="totalUsers">
            <div class="col-md-6">
                <h3>Total Users: {{ totalUsers }}</h3>
            </div>
            <div class="col-md-6">
                <h3>Total Test Completed: {{ totalTestCompleted }}</h3>
            </div>  
        </div>

       <div class="table-responsive fixed-height-table">
        <table class="table table-striped table-secondary">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Mobile</th>
                    <th scope="col">Class</th>
                    <th scope="col">School</th>
                    <th scope="col">State / City</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of allUsers; let i = index">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>{{ item?.name }}</td>
                    <td>{{ item?.email }}</td>
                    <td>{{ item?.mobile }}</td>
                    <td>{{ item?.class }}</td>
                    <td>{{ item?.school_name }}</td>
                    <td>{{ item?.city }}</td>
                </tr>
            </tbody>
        </table>
       </div>
    </div>
</div>


<ng-template #multipleClientUserModal let-modal>

    <div class="modal-header">
        <h5 class="modal-title" *ngIf="!isView">{{ isEdit ? 'Edit' : 'Add' }} Multiple Client Users</h5>
        <h5 class="modal-title" *ngIf="isView">View Client User</h5>
        <span class="btn btn-close btn-outline-danger" data-dismiss="modal" aria-label="Close"
            (click)="closeModal()"></span>

    </div>
    <div class="modal-body">
        <form>
            <div class="row clearfix px-3">
                <div>
                    <h5>Upload Excel File</h5>
                    <input class="form-control form-control-lg mt-3" type="file" #csvReader name="Upload CSV"
                        id="txtFileUpload" (change)="uploadListener($event)" accept=".csv">
                </div>

                <table class="table table-striped table-secondary my-5"
                    *ngIf="multipleClientUsers && multipleClientUsers.length > 0">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Class</th>
                            <th scope="col">School</th>
                            <th scope="col">State</th>
                            <th scope="col">Mobile</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of multipleClientUsers; let i = index">
                            <th scope="row">{{ i + 1 }}</th>
                            <td>{{ item?.name }}</td>
                            <td>{{ item?.email }}</td>
                            <td>{{ item?.class }}</td>
                            <td>{{ item?.school }}</td>
                            <td>{{ item?.state }}</td>
                            <td>{{ item?.mobile }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </form>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="closeModal()">Close</button>
        <button type="button" class="btn btn-primary" (click)="onMultipleClientSubmitForm()" *ngIf="!isView">Save
            changes</button>
    </div>

</ng-template>


<ng-template #clientUserModal let-modal>

    <div class="modal-header">
        <h5 class="modal-title" *ngIf="!isView">{{ isEdit ? 'Edit' : 'Add' }} Client User</h5>
        <h5 class="modal-title" *ngIf="isView">View Client User</h5>
        <span class="btn btn-close btn-outline-danger" data-dismiss="modal" aria-label="Close"
            (click)="closeModal()"></span>

    </div>
    <div class="modal-body">

        <form [formGroup]="clientUserForm">
            <div class="row clearfix px-3">
                <div class="col-md-12 col-lg-6">
                    <div class="form-group mt-3">
                        <label class="secondary-text-font-size primary-theme-color mb-1">Name</label>
                        <input type="text" name="name" formControlName="name" [readonly]="isView"
                            class="form-input form-control theme-form-control pt-2 placeholder-fix"
                            autocomplete="off" />
                        <div class="text-danger mt-1" *ngIf="fc.name.touched && fc.name?.errors?.required">
                            Name is Required
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-lg-6">
                    <div class="form-group mt-3">
                        <label class="secondary-text-font-size primary-theme-color mb-1">Email</label>
                        <input type="text" name="email" formControlName="email" [readonly]="isView"
                            class="form-input form-control theme-form-control pt-2 placeholder-fix"
                            autocomplete="off" />
                        <div class="text-danger mt-1" *ngIf="fc.email.touched && fc.email?.errors?.required">
                            Email is Required
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-lg-6">
                    <div class="form-group mt-3">
                        <label class="secondary-text-font-size primary-theme-color mb-1">Mobile</label>
                        <input type="text" name="mobile" formControlName="mobile" [readonly]="isView"
                            class="form-input form-control theme-form-control pt-2 placeholder-fix"
                            autocomplete="off" />
                        <div class="text-danger mt-1" *ngIf="fc.mobile.touched && fc.mobile?.errors?.required">
                            Mobile is Required
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-lg-6">
                    <div class="form-group mt-3">
                        <label class="secondary-text-font-size primary-theme-color mb-1">School</label>
                        <input type="text" name="school" formControlName="school" [readonly]="isView"
                            class="form-input form-control theme-form-control pt-2 placeholder-fix"
                            autocomplete="off" />
                        <div class="text-danger mt-1" *ngIf="fc.school.touched && fc.school?.errors?.required">
                            School is Required
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-lg-6">
                    <div class="form-group mt-3">
                        <label class="secondary-text-font-size primary-theme-color mb-1">Class</label>
                        <input type="text" name="class" formControlName="class" [readonly]="isView"
                            class="form-input form-control theme-form-control pt-2 placeholder-fix"
                            autocomplete="off" />
                        <div class="text-danger mt-1" *ngIf="fc.class.touched && fc.class?.errors?.required">
                            Class is Required
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-lg-6">
                    <div class="form-group mt-3">
                        <label class="secondary-text-font-size primary-theme-color mb-1">State / City</label>
                        <input type="text" name="city" formControlName="city" [readonly]="isView"
                            class="form-input form-control theme-form-control pt-2 placeholder-fix"
                            autocomplete="off" />
                        <div class="text-danger mt-1" *ngIf="fc.city.touched && fc.city?.errors?.required">
                            State / City is Required
                        </div>
                    </div>
                </div>
            </div>
        </form>



    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="closeModal()">Close</button>
        <button type="button" class="btn btn-primary" [disabled]="clientUserForm.invalid" (click)="onSubmitForm()"
            *ngIf="!isView">Save changes</button>
    </div>

</ng-template>





<div class="loading-div" *ngIf="isLoading">
    <img class="loading-mode-width loading-spinner" src="/assets/images/loader.gif" alt="" />
</div>