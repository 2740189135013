<!-- PDF Export Div -->
<div style="display: none;" id="sampleDiv" #sampleDiv>
  <p style="text-align: center; font-size: 1.25em; color: #2d3a37">Career Psychometric Report</p>
  <div>
    <h3 style="font-size: 1.25em; color: #25BEC2; font-weight: bold;">{{personalityResultData?.client_info.client_name}}</h3>
    <p style="color: #707070; font-size: 0.8em;">in association with ProBano</p>
  </div>
  <p style="color: #000;">
    <span style="font-weight: bold; font-size: 1.2em; color: #000;">Name: </span>
    {{personalityResultData?.student_info.name}}
  </p>
  <p style="color: #000;">
    <span style="font-weight: bold; font-size: 1.2em; color: #000;">Class: </span>
    {{personalityResultData?.student_info.class}}
  </p>
  <p style="color: #000;">
    <span style="font-weight: bold; font-size: 1.2em; color: #000;">School: </span>
    {{personalityResultData?.student_info.school}}
  </p>
  <p style="color: #000;">
    <span style="font-weight: bold; font-size: 1.2em; color: #000;">State: </span>
    {{personalityResultData?.student_info.state}}
  </p>
  <p>
    <span style="font-size: 1.25em; color: #25BEC2; font-weight: bold;">Congratulations</span> on completing the ProBano Career Psychometric Test. This report will give an insight into your career interests and strengths.
  </p>
  <p>
    We have designed this report in a crisp yet comprehensive manner so that you can remember these factors while you are in this journey of selecting the right career option for yourself. To be precise, we have divided this report in two parts. In this report you will get: 
  </p>
  <p style="font-size: 1.25em; color: #25BEC2; font-weight: bold;">A. Suggested Career Fields:</p>
  <p>
    Career fields are a broad division of career options on the basis of industries,
    each career field has a variety of career paths available to choose from.
    This feature is designed to give you a broader perspective of possible career options for you.
  </p>
  <p style="font-size: 1.25em; color: #25BEC2; font-weight: bold;">B. Your Personality Traits:</p>
  <p>
    Knowing your own personality traits is crucial for any big decision for our lives.It plays a significant role in the success or failure of any career pursuit. This feature is designed to give you an insight into your strengths which will help you in your career journey
  </p>
  <hr>
  <div>
    <p style="font-size: 1.5em; color: #25BEC2; font-weight: bold; margin-bottom: 0;">Suggested Career Fields: </p>
    <p style="font-size: 0.75em; color: #707070;">
      These are the suggested career fields according to your interests. Each career field has been given a 'Career Inclination Score' which is a measure of your interest in that career field on a 10 point scale.
    </p>
  </div>
  <div *ngFor="let item of personalityResultData?.career_recommendation; let i = index;" style="margin-bottom: 2em;">
    <div style="margin-top: 1.5em">
      <div [innerHTML]="getCategoryImage(item.category)"></div>
      <p style="font-size: 1em; color: #25BEC2; font-weight: bold; margin-bottom: 0;">{{item.category}}</p>
    </div>
    <p style="font-size: 0.75em; color: #707070; margin-bottom: 0.5em;">{{item.category_description}}</p>
    <p style="font-size: 0.75em; color: #000; margin-bottom: 0.5em;">Career Inclination Score: <b>{{item.points/4}}</b></p>
  </div>
  <hr>
  <div>
    <p style="font-size: 1.5em; color: #25BEC2; font-weight: bold; margin-bottom: 0;">Your Personality Traits: </p>
    <p style="font-size: 0.75em; color: #707070;">
      These are the key strengths aligned with your career interests. Each trait has been given a 'Personality Match Score' which measures your personality match to the given trait ranging from 'Good Match' to 'Strong Match' on a 10 point scale.
    </p>
  </div>
  <div *ngFor="let item of personalityResultData?.personality_traits; let i = index;" style="margin-bottom: 2em;">
    <div [innerHTML]="getTraitImage(item.trait_name)"></div>
    <p style="font-size: 1em; color: #25BEC2; font-weight: bold; margin-bottom: 0;">{{item.trait_name}}</p>
    <p style="font-size: 0.75em; color: #707070; margin-bottom: 0.5em;">{{item.trait_description}}</p>
    <p style="font-size: 0.75em; color: #000; margin-bottom: 0.5em;">Personality Match Score: <b>{{(item.points/4)*10}}</b></p>
  </div>
  <p style="text-align: center; font-size: 1.5em; color: #000000; font-weight: bold; margin-top: 2em;">Career Recommendations Deep Dive</p>
  <div *ngFor="let item of personalityResultData?.career_recommendation; let i = index;">
    <div [innerHTML]="getCategoryImage(item.category)"></div>
    <p style="font-size: 1.5em; color: #25BEC2; font-weight: bold;">{{item.category}}</p>
    <p style="font-size: 1em; color: #000;">{{item.category_description}}</p>
    <p style="color: #000; font-weight: bold; text-decoration: underline;">Careers</p>
    <div *ngFor="let career of item.careers; let i=index;">
      <p style="font-size: 1em; color: #25BEC2; font-weight: bold; margin-bottom: 0;"><span style="color: #000;">{{i+1}}.</span> {{career.career_name}}</p>
      <p style="font-size: 0.75em; color: #707070; margin-bottom: 1em;">{{career.career_description}}</p>
    </div>
    <p><span style="height: 1em; width: 1em; border-radius: 50%; background-color: #000; margin-right: 0.25em;"></span>Workplaces: {{item.workplaces}}</p>
    <hr>
  </div>
  <p style="text-align: center; font-size: 1.5em; color: #000000; font-weight: bold; margin-top: 2em;">Personality Traits Deep Dive</p>
  <div *ngFor="let item of personalityResultData?.personality_traits; let i = index;">
    <div [innerHTML]="getTraitImage(item.trait_name)"></div>
    <p style="font-size: 1.5em; color: #25BEC2; font-weight: bold;">{{item.trait_name}}</p>
    <p style="font-size: 1em; color: #000;">{{item.trait_description}}</p>
    <p style="color: #000; font-weight: bold; text-decoration: underline;">Pros</p>
    <div *ngFor="let pro of item.pros; let i=index;">
      <p style="font-size: 1em; color: #000; margin-bottom: 0;"><span style="color: #000;">{{i+1}}.</span> {{pro}}</p>
    </div>
    <p style="color: #000; font-weight: bold; text-decoration: underline; margin-top: 0.5em;">Cons</p>
    <div *ngFor="let con of item.cons; let i=index;">
      <p style="font-size: 1em; color: #000; margin-bottom: 0;"><span style="color: #000;">{{i+1}}.</span> {{con}}</p>
    </div>
    <hr>
  </div>
  <div>
    <p style="text-align: center; font-size: 1.5em; font-weight: bold; color: #000;">Got any questions?</p>
    <p style="text-align: center; font-size: 1em; color: #707070;">Contact us at: {{personalityResultData?.client_info?.client_contact_email}}</p>
  </div>
  <!-- <div style="text-align: left; margin-top: 4in;">
    <div [innerHTML]="probano_logo"></div>
  </div> -->
</div>
<div id="reportPDF" #reportPDF *ngIf="personalityResultData">
  <app-hcl-header [data]="personalityResultData.client_info"></app-hcl-header>

  <app-hcl-main-page [data]="personalityResultData.student_info"></app-hcl-main-page>

  <app-career-and-traits [data]="personalityResultData"></app-career-and-traits>

  <div class="position-fixed-bottom mx-3 pdf-section">
    <!-- <button class="btn pb-primary-color-bg mx-2" (click)="downloadPDF()">Download PDF</button> -->
    <button class="btn btn-secondary" (click)="createAndDownload()">Download PDF</button>
  </div>

  <app-hcl-contact-us [data]="personalityResultData?.client_info?.client_contact_email"></app-hcl-contact-us>

  <app-hcl-footer></app-hcl-footer>
</div>

<div class="loading-div" *ngIf="isLoading">
  <img class="loading-mode-width loading-spinner" src="/assets/images/loader.gif" alt="">
</div>
