import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-hcl-main-page',
  templateUrl: './hcl-main-page.component.html',
  styleUrls: ['./hcl-main-page.component.css']
})
export class HclMainPageComponent implements OnInit {

  @Input() data: any;
  public progressValue: number = 10;

  constructor() { }

  ngOnInit(): void {
  }

  getWidthInPercentage(value) {
    return value + "%"
  }

}
