<div class="full-page pt-10-vh">
    <div class="container auth-main-content">
        <div class="row">
            <div class="col-md-7 pt-4 pb-5 d-flex flex-column align-items-center logo-container">      

               <div class="margin-right-auto">
                    <img class="img-fluid mx-4 max-height-80-px" [src]="currentClient?.secondary_client_logo">
               </div>

                <img class="img-fluid my-5" src="../../../../assets/images/auth_illustration.svg" alt="Probano image" />
                <div class="text-center">
                    <h3>Welcome To <span class="pb-primary-color">PathFinder Test</span></h3>
                </div>
            </div>
            <div class="col-md-5 pt-4 pb-5 px-5">

                <div class="text-end">
                    <img class="img-fluid max-height-80-px"
                        [src]="currentClient?.primary_client_logo" [alt]="currentClient?.client_name" />
                </div>

                <form [formGroup]="hclForm" class="my-5 hcl-form">
                    <div class="row clearfix">
                        <div class="col-12 px-0">
                            <div class="form-group mt-3">
                                <label
                                    class="secondary-text-font-size primary-theme-color mb-1 roboto pb-grey-light-color">Email</label>
                                <input type="text" name="email" formControlName="email"
                                    class="form-input form-control theme-form-control py-2 placeholder-fix pb-fade-grey-light-color-bg"
                                    autocomplete="off" />
                                <div class="text-danger mt-1" *ngIf="f.email.touched && f.email?.errors?.required">
                                    Email is Required
                                </div>
                            </div>
                        </div>

                        <div class="col-12 px-0">
                            <div class="form-group mt-3">
                                <label
                                    class="secondary-text-font-size primary-theme-color mb-1 roboto pb-grey-light-color">Redeem
                                    Code</label>
                                <input type="text" name="redeemCode" formControlName="redeemCode"
                                    class="form-input form-control theme-form-control py-2 placeholder-fix pb-fade-grey-light-color-bg"
                                    autocomplete="off" />
                                <div class="text-danger mt-1"
                                    *ngIf="f.redeemCode.touched && f.redeemCode?.errors?.required">
                                    Redeem Code is Required
                                </div>
                            </div>
                        </div>


                        <button type="button" class="btn pb-primary-color-bg text-white mt-5 roboto"
                            data-bs-dismiss="modal" (click)="openModal(rulesModal)" [disabled]="hclForm.invalid">Take
                            The Test</button>



                    </div>
                </form>

                <div class="mt-5 position-relative">
                    <h5 class="mb-0 roboto-medium text-end">Powered by 
                        <a routerLink="/" class="text-decoration-none"><span class="pb-primary-color">ProBano</span></a>
                    </h5>
                </div>

            </div>
        </div>
    </div>
</div>


<ng-template #rulesModal let-modal>
    <div class="modal-header">
        <h3 class="modal-title rubik">Rules</h3>
        <span class="btn btn-close btn-outline-danger" data-dismiss="modal" aria-label="Close"
            (click)="closeModal()"></span>
    </div>
    <div class="modal-body">
        <h5 class="roboto">There will be four options for each 35 question.</h5>

        <div class="row">
            <div class="col-md-12">
                <ul class="list-unstyled">
                    <li class="my-1 roboto">
                        <i class="fas fa-star pb-secondary-color"></i>
                        <span class="mx-2">Choose your most liked option first.</span>
                    </li>
                    <li class="my-1 roboto">
                        <i class="fas fa-star pb-secondary-color"></i>
                        <span class="mx-2">Then choose your second most liked option.</span>
                    </li>
                    <li class="my-1 roboto">
                        <i class="fas fa-star pb-secondary-color"></i>
                        <spa class="mx-2" n>Then choose your third most liked option.</spa>
                    </li>
                    <li class="my-1 roboto">
                        <i class="fas fa-star pb-secondary-color"></i>
                        <span class="mx-2">Then choose your least liked option.</span>
                    </li>
                    <li class="my-1 roboto">
                        <i class="fas fa-star pb-secondary-color"></i>
                        <span class="mx-2">Submit each question.</span>
                    </li>
                </ul>
            </div>

            <h5 class="roboto-medium mt-2">
                प्रत्येक प्रश्न के लिए चार विकल्प होंगे
            </h5>

            <div class="col-md-12">
                <ul class="list-unstyled">
                    <li class="my-1 roboto">
                        <i class="fas fa-star pb-secondary-color"></i>
                        <span class="mx-2">सबसे पहले अपना सबसे पसंदीदा विकल्प चुनें।</span>
                    </li>
                    <li class="my-1 roboto">
                        <i class="fas fa-star pb-secondary-color"></i>
                        <span class="mx-2">इसके बाद अपना दूसरा सबसे ज्यादा पसंद किया जाने वाला विकल्प
                            चुनें।</span>
                    </li>
                    <li class="my-1 roboto">
                        <i class="fas fa-star pb-secondary-color"></i>
                        <span class="mx-2">इसके बाद अपना तीसरा सबसे ज्यादा पसंद किया जाने वाला विकल्प
                            चुनें।</span>
                    </li>
                    <li class="my-1 roboto">
                        <i class="fas fa-star pb-secondary-color"></i>
                        <span class="mx-2">फिर अपना सबसे कम पसंद किया गया विकल्प चुनें।</span>
                    </li>
                    <li class="my-1 roboto">
                        <i class="fas fa-star pb-secondary-color"></i>
                        <span class="mx-2">प्रत्येक प्रश्न सबमिट करें।</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary w-100 pb-grey-light-color-bg" data-bs-dismiss="modal"
            (click)="closeModal()">
            Close
        </button>
        <button type="button" class="btn btn-primary w-100 pb-primary-color-bg my-3" (click)="navigateToTest()">
            Start Test
        </button>
    </div>
</ng-template>

<div class="loading-div" *ngIf="isLoading">
    <img class="loading-mode-width loading-spinner" src="/assets/images/loader.gif" alt="">
</div>