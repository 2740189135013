import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  constructor() {}

  pageTrack(page) {
    mixpanel.track(page);
  }

  identify (distinct_id) {
    mixpanel.identify(distinct_id)
  }

  event(action, properties?) {
    try {
      if (properties) {
        const event_properties = { ...this.userInfo(), ...properties };
        mixpanel.track(action, event_properties)
      } else {
        mixpanel.track(action, this.userInfo())
      }
    } catch {}
  }

  error(message, error){
    try{
      const alert = {
        flag: 'error'
      };
      const properties = {...alert, ...this.userInfo(), ...error};
      mixpanel.track(message, properties);
    } catch {}
  }

  userInfo() {
      const properties = {
      name: localStorage.getItem('user_name'),
      email: localStorage.getItem('user_email'),
    };
    return properties;
  }
}
