import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../environments/environment';
import { ApiService } from '../api.service';
import { Constants } from '../class/constants';
import { MixpanelService } from '../mixpanel.service';
import { AuthService } from '../services/auth.service';
import { ToastMessageService } from '../services/toast-message.service';

declare var Razorpay: any;

@Component({
  selector: 'app-mentors',
  templateUrl: './mentors.component.html',
  styleUrls: ['./mentors.component.css'],
})
export class MentorsComponent implements OnInit {
  public mentors = new Array<mentorType>();
  public personality_test_status: string;

  public modalRef: any;
  public modalConfigMd = Constants.lightGreyBackdropModalLg;

  public currentMentor: any;
  public currentUser: any;

  public isLoading: boolean = false;


  public razorPayOptions = {
    key: environment.razorpay_key,
    amount: '',
    currency: 'INR',
    name: 'ProBano',
    image: 'https://career-data.s3.ap-south-1.amazonaws.com/probano_logo.png',
    description: '',
    order_id: '',
    modal: {
      escape: false,
    },
    theme: {
      color: '#25BEC2',
    },
    prefill: {
      name: '',
      contact: '',
      email: '',
    },
    handler: (res) => {},
  };

  constructor(
    private apiService: ApiService,
    private mixpanelService: MixpanelService,
    private modalService: NgbModal,
    private authService: AuthService,
    private toastMessageService: ToastMessageService
  ) {
  }

  getAllGuides() {
    this.isLoading = true;
    this.apiService.get('get-all-guides').subscribe(
      data => {
        this.mentors = data;
        for (let i = 0; i < this.mentors.length; i++) {
          if (this.mentors[i]['description'].length > 400) {
            this.mentors[i]['short_description'] =
              this.mentors[i]['description'].substring(0, 400) + '...';
          } else {
            this.mentors[i]['short_description'] =
              this.mentors[i]['description'];
          }
        }
      },
      err => {
        this.mixpanelService.error('get-all-guides-error', {
          message: err.error,
        });
      },
      () => this.isLoading = false
    );
  }

  public bookMentor() {
    this.currentUser = this.authService.userValue;
    let data = {
      amount: "999",
      name: this.currentUser?.name
    }
    if (this.currentUser.is_verified)
   {
    this.apiService.postUser('pay', data).subscribe(
      data => {
        this.razorPayOptions.description = this.currentMentor?.name;
        this.razorPayOptions.prefill = {
          name: this.currentUser?.name,
          contact: this.currentUser?.mobile,
          email: this.currentUser?.email,
        };
        this.razorPayOptions.order_id = data.order.order_payment_id;
        this.razorPayOptions.amount = "99900";

        this.razorPayOptions.handler = (response) => {
          if (response.razorpay_payment_id) {
            let postData = {
              mentor_name: this.currentMentor?.name,
              full_name: this.currentUser?.name,
              mentor_email: this.currentMentor?.email,
              user_email: this.currentUser?.email,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
            };
            this.toastMessageService.showSuccessWithTitle("Mission Successful", "Thanks for booking a mentor session with us. Our team's rep will contact you via mail/phone.", 10000)
            this.checkPaymentSuccess(postData);
          } else {
            this.toastMessageService.showError("Mentor booking not completed")
            this.mixpanelService.event("Mentor booking not completed", {
              "mentor_name": this.currentMentor?.name
            })
          }
        }
        const rzp1 = new Razorpay(this.razorPayOptions);
        rzp1.open();
      }, 
      err => {
        this.mixpanelService.error('payment-error', {
          message: err.error,
        });
      }
    )
   }
   else{
    this.toastMessageService.showError("Unable to book session, please verify your email.")

   }
  }

  checkPaymentSuccess(data) {
    this.apiService.post('payment/success',data).subscribe(
      (data) => {
        this.mixpanelService.event('mentor-booking-completed', {
          "mentor_name": this.currentMentor?.name
        });
      },
      (err) => {
        this.mixpanelService.error('payment-success-error', {
          message: err.error,
        });
      }
    );
  }
 
  openModal(modalRef: any, data) {
    this.currentMentor = data;
    this.modalRef = this.modalService.open(modalRef, this.modalConfigMd);
  }

  closeModal() {
    if (this.modalRef) {
      this.modalRef.dismiss();
    }
  }

  public openSidebar() {
    document.getElementById('sidenav').style.width = '50%';
  }

  ngOnInit() {
    this.getAllGuides()
    this.mixpanelService.pageTrack('Loaded mentors page')
  }
}

interface mentorType {
  career_guide_id: number;
  description: string;
  email: string;
  img_url: string;
  linkedin_url: string;
  name: string;
  short_description: string;
  title: string;
}
